import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService {

  private lastError?: string;

  constructor(private logger: NGXLogger, private router: Router) {
    this.router.events.subscribe({
      next: (val) => {
        if (val instanceof NavigationEnd) {
          this.reset();
        }
      }
    });
  }

  reset() {
    this.lastError = undefined;
  }

  get errorMessage(): string | undefined {
    return this.lastError;
  }

  get hasError(): boolean {
    return this.lastError !== undefined;
  }

  error(message: any, ...additional: any[]) {
    if (message instanceof String) {
      this.lastError = String(message);
    } else {
      this.lastError = JSON.stringify(message, undefined, 4);
    }

    this.lastError += additional.map(v => JSON.stringify(v, undefined, 4)).join('\n');
    this.logger.error(message, ...additional);
  }
}
