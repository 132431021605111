import { EventEmitter, Injectable } from '@angular/core';
import { GridOperator } from './grid-operator';
export { GridOperator };

@Injectable({
  providedIn: 'root',
})
export class GridOperatorsService {
  private static operators: GridOperator[] = [];
  private static operatorsMap: { [id: string]: GridOperator } = {};

  public static knownOperatorsChanged = new EventEmitter<GridOperator[]>();

  public set knownOperators(operators: GridOperator[]) {
    GridOperatorsService.operators = operators;

    GridOperatorsService.operatorsMap = GridOperatorsService.operators.reduce(
      (
        currentOperatorMap: { [id: string]: GridOperator },
        newOperator: GridOperator
      ) => {
        currentOperatorMap[newOperator.id] = newOperator;
        return currentOperatorMap;
      },
      {}
    );

    GridOperatorsService.knownOperatorsChanged?.emit(operators);
  }

  public get knownOperators(): GridOperator[] {
    return GridOperatorsService.operators;
  }

  public get knownOperatorsMap(): { [id: string]: GridOperator } {
    return GridOperatorsService.operatorsMap;
  }

  public isKnownGridOperator(id: string | undefined | null): boolean {
    if (!id) {
      return false;
    }
    return this.knownOperatorsMap[id.toUpperCase()] !== undefined;
  }

  public isSupportedGridOperator(id: string | undefined | null): boolean {
    if (!id) {
      return false;
    }

    const gridOperator = this.knownOperatorsMap[id.toUpperCase()];
    if (gridOperator) {
      return (
        (gridOperator.isSupported && gridOperator?.section === 'Electricity') ??
        true
      );
    }
    return false;
  }

  public extractGridOperatorId(meterPointId: string | undefined | null) {
    if (!meterPointId) {
      return undefined;
    }

    if (meterPointId.length < 8) {
      return undefined;
    }

    return meterPointId.substring(0, 8);
  }
}
