import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INGXLoggerConfig, NGXLoggerColorScheme, NgxLoggerLevel } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerModuleConfig implements INGXLoggerConfig {

  constructor(private settings: SettingsService) {

    if (environment.production) {
      this.level = NgxLoggerLevel.INFO;
    } else {
      this.level = NgxLoggerLevel.DEBUG;
    }

    this.serverLogLevel = NgxLoggerLevel.OFF;
    this.colorScheme = ['purple', 'teal', 'gray', 'gray', 'red', 'red', 'red'];

    this.enableSourceMaps = true;

    if (this.settings.active) {
      this.serverLoggingUrl = settings.active.loggingApiUrl;
    }

    SettingsService.settingsChanged.subscribe(newSettings => {
      if (!newSettings) {
        return;
      }
      this.serverLoggingUrl = newSettings.loggingApiUrl;
    });
  }
  level: NgxLoggerLevel;
  timestampFormat?: string | undefined;
  disableConsoleLogging?: boolean | undefined;
  enableSourceMaps?: boolean | undefined;
  proxiedSteps?: number | undefined;
  colorScheme?: NGXLoggerColorScheme | undefined;
  disableFileDetails?: boolean | undefined;
  serverLogLevel?: NgxLoggerLevel | undefined;
  serverLoggingUrl?: string | undefined;
  withCredentials?: boolean | undefined;
  customHttpParams?: HttpParams | undefined;
  customHttpHeaders?: HttpHeaders | undefined;
  httpResponseType?: 'arraybuffer' | 'blob' | 'text' | 'json' | undefined;
}
