<div *ngIf="workspace" class="workspace-status-widget-root">
  <a
    app-nista-button
    color="primary"
    size="small"
    [routerLink]="['/workspace', workspace.workspaceId]"
    >Open '{{ workspace.name }}'</a
  >
  <span>WorkspaceId: {{ workspace.workspaceId }}</span>
  <div class="facilities-container">
    <app-nista-expander
      *ngFor="let facility of facilityList; index as i"
      [title]="facility.facilityName + ' - ' + facility.facilityId"
      [loading]="facilityLoading[i]?.[1] ?? true"
      [error]="!!errorMessages[i]"
      [matTooltip]="errorMessages[i] ? errorMessages[i][1] : ''"
    >
      <app-facility-status-widget
        [facility]="facility"
        [workspaceId]="workspace.workspaceId"
        (loadingEmitter)="addFacilityLoading($event)"
        (errorEmitter)="addErrorMessage($event)"
      ></app-facility-status-widget>
    </app-nista-expander>
  </div>
</div>
