import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Icon } from '../gnista-icon/gnista-icon.component';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit, OnChanges {
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.headline) {
      this.headline = changes.headline.currentValue;
    }

    if (changes.text) {
      this.text = changes.text.currentValue;
    }

    if (changes.action1) {
      this.action1 = changes.action1.currentValue;
    }

    if (changes.action2) {
      this.action2 = changes.action2.currentValue;
    }

    if (changes.closeable) {
      this.closeable = changes.closeable.currentValue;
    }

    if (changes.icon) {
      this.icon = changes.icon.currentValue;
    }

    if (this.action1 && this.action2) {
      this.type = 'twoaction';
    } else if (this.action1) {
      this.type = 'oneaction';
    } else {
      this.type = 'noaction';
    }
  }

  @Input()
  headline?: string;

  @Input()
  text?: string;

  @Input()
  action1?: string;

  @Input()
  action2?: string;

  type: SnackbarType = 'noaction';

  @Input()
  closeable = false;

  @Input()
  icon?: Icon;

  @Output()
  closeClicked = new EventEmitter();

  @Output()
  action1Clicked = new EventEmitter();

  @Output()
  action2Clicked = new EventEmitter();

  ngOnInit(): void {}
}

export type SnackbarType = keyof typeof SNACKBAR_TYPE;

export const SNACKBAR_TYPE = {
  noaction: 'noaction',
  oneaction: 'oneaction',
  twoaction: 'twoaction',
};
