import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridOperatorsService } from './grid-operators.service';
import { GridOperator } from './grid-operator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GridOperatorsHttpService {
  constructor(
    private http: HttpClient,
    private gridOperatorsService: GridOperatorsService
  ) {}

  loadGridOperators(): Observable<any> {
    return new Observable<void>((resolve) => {
      this.http.get('assets/grid-operators.json').subscribe({
        next: (response) => {
          this.gridOperatorsService.knownOperators = response as GridOperator[];
          resolve.next();
          resolve.complete();
        },
        error: (err) => resolve.error(err),
      });
    });
  }
}
