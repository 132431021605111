<div class="status-root">
  <div class="banner">
    <a href="https://nista.io" rel="noopener">
      <img src="assets/wordmark.svg" />
    </a>
    <h1>status page</h1>
  </div>
  <ng-container *ngIf="isNista">
    <div class="scroll-container" *ngIf="doneLoading">
      <ng-scrollbar thumbClass="scroll-thumb" barClass="scroll-bar">
        <div class="scroll-container">
          <app-tab-navigation-group tabGroupQuery="tab">
            <app-tab-navigation-item label="Service Status">
              <ng-container *ngTemplateOutlet="publicStatusPage">
              </ng-container>
            </app-tab-navigation-item>
            <app-tab-navigation-item label="Workspace Status">
              <ng-container *ngTemplateOutlet="workspaceStatusPage">
              </ng-container>
            </app-tab-navigation-item>
          </app-tab-navigation-group>
        </div>
      </ng-scrollbar>
    </div>
  </ng-container>
  <ng-container *ngIf="!isNista">
    <div class="scroll-container" *ngIf="doneLoading">
      <ng-scrollbar thumbClass="scroll-thumb" barClass="scroll-bar">
        <div class="scroll-container">
          <ng-container *ngTemplateOutlet="publicStatusPage"> </ng-container>
        </div>
      </ng-scrollbar>
    </div>
  </ng-container>

  <app-loading *ngIf="!doneLoading"></app-loading>
</div>

<ng-template #publicStatusPage>
  <div class="header-row" *ngIf="!status">
    <h1>NISTA.IO CAN'T FETCH STATUS INFORMATION</h1>
    <app-gnista-icon [colorOverride]="'red'" [icon]="'error'"></app-gnista-icon>
  </div>
  <div class="header-row" *ngIf="status">
    <h1>
      NISTA.IO
      {{
        status.healthy
          ? "IS FULLY FUNCTIONAL "
          : "has " + status.replicasStatus + " services running"
      }}
    </h1>
    <app-gnista-icon
      *ngIf="status.healthy"
      [colorOverride]="'green'"
      [icon]="'done'"
    ></app-gnista-icon>
    <app-gnista-icon
      *ngIf="!status.healthy"
      [colorOverride]="'yellow'"
      [icon]="'warning'"
    ></app-gnista-icon>
  </div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          State{{ ": " + status?.replicasStatus + " services running" }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table *ngIf="status?.deployments" class="status">
        <thead>
          <th>Service Name</th>
          <th>Ready</th>
          <th>Restarts</th>
          <th>Creation</th>
          <th>Last Restart</th>
          <th>Current State</th>
        </thead>
        <tbody>
          <tr
            *ngFor="let deployment of status?.deployments"
            [ngClass]="getStatusIcon(deployment).color"
          >
            <td>{{ deployment.deploymentName }}</td>
            <td>
              {{ deployment.readyReplicas ?? 0 }}/{{
                deployment.desiredReplicas ?? "?"
              }}
            </td>
            <td>{{ deployment.restartCount }}</td>
            <td title="{{ deployment.creationTimestamp }}">
              {{ deployment.creationTimestamp | dateAgo }}
            </td>
            <td title="{{ deployment.lastRestartTimestamp }}">
              {{ deployment.lastRestartTimestamp | dateAgo }}
            </td>
            <td class="statusColumn">
              <app-gnista-icon
                [colorOverride]="getStatusIcon(deployment).color"
                [icon]="getStatusIcon(deployment).icon"
              ></app-gnista-icon>
              <span>{{ deployment.status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-expansion-panel>
  </mat-accordion>

  <br />
  <h2>Lines of Code per Service ({{ allLinesOfCode }} LOC in total)</h2>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> LOC Details </mat-panel-title>
      </mat-expansion-panel-header>
      <table class="loc-table">
        <thead>
          <th>Service Name</th>
          <th>Language</th>
          <th>Lines of Code</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let deployment of status?.deployments">
            <tr
              *ngFor="
                let loc of deployment.linesOfCode ?? []
                  | orderBy : 'code' : true;
                let i = index
              "
            >
              <td
                *ngIf="i === 0"
                [attr.rowspan]="deployment.linesOfCode?.length ?? 1"
              >
                {{ deployment.deploymentName }}
              </td>
              <td>{{ loc.language }}</td>
              <td>{{ loc.code }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </mat-expansion-panel>
  </mat-accordion>

  <h2>Commits per Service ({{ status?.totalCommits ?? 0 }} in total)</h2>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> LOC Details </mat-panel-title>
      </mat-expansion-panel-header>
      <table class="loc-table">
        <thead>
          <th>Service Name</th>
          <th>Committer</th>
          <th>Number of Commits</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let deployment of status?.deployments">
            <tr
              *ngFor="
                let commit of deployment.commits ?? []
                  | orderBy : 'commits' : true;
                let i = index
              "
            >
              <td
                *ngIf="i === 0"
                [attr.rowspan]="deployment.commits?.length ?? 1"
              >
                {{ deployment.deploymentName }}
              </td>
              <td>{{ commit.name }}</td>
              <td>{{ commit.commits }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #workspaceStatusPage>
  <div class="workspace-list">
    <div class="workspace-card" *ngFor="let workspace of workspaceList">
      <app-workspace-status-widget
        [workspace]="workspace"
      ></app-workspace-status-widget>
    </div>
  </div>
</ng-template>
