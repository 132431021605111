import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { WebsocketConnectionStateService } from '../websocket-connection-state.service';

@Component({
  selector: 'app-connection-lost-overlay',
  templateUrl: './connection-lost-overlay.component.html',
  styleUrls: ['./connection-lost-overlay.component.scss'],
})
export class ConnectionLostOverlayComponent implements OnInit, OnDestroy {
  reconnecting = false;

  destroyed$ = new Subject<void>();

  constructor(private connectionState: WebsocketConnectionStateService) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.connectionState
      .onReconnectChanged()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newState) => {
        this.reconnecting = newState;
      });
  }

  reloadCurrentPage() {
    window.location.reload();
  }
}
