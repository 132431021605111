import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ErrorhandlerService } from './errorhandler.service';

export const isRequired = (): PropertyDecorator => (
  target: any,
  propertyKey: string | symbol
) => {

  const NG_ON_ONIT_NAME = 'ngOnInit';

  /** ngOnInit might not be implemented by this component */
  // eslint-disable-next-line @typescript-eslint/ban-types
  const ngOnInitClone: Function | null = target[NG_ON_ONIT_NAME];

  const logger: NGXLogger | null = IsRequiredLoggingService.getLogger();

  const errorHandler: ErrorhandlerService | null = IsRequiredLoggingService.getErrorHandler();

  Object.defineProperty(target, NG_ON_ONIT_NAME, {
    value() {
      if (this[propertyKey] == null) {

        if (errorHandler) {
          errorHandler.error('is required, but was not provided', target.constructor.name, propertyKey);
        } else if (logger) {
          logger.error('is required, but was not provided', target.constructor.name, propertyKey);
        } else {
          console.error(
            target.constructor.name +
            `: ${String(propertyKey)} is required, but was not provided`
          );
        }
      }
      // Calling the original ngOnInit with its original context
      //
      if (ngOnInitClone) {
        ngOnInitClone.call(this);
      }
    }
  });
};

@Injectable()
export class IsRequiredLoggingService {
  private static logger: NGXLogger | undefined = undefined;
  private static errorHandler: ErrorhandlerService | undefined = undefined;

  public constructor(logger: NGXLogger, errorHandler: ErrorhandlerService) {
    IsRequiredLoggingService.logger = logger;
    IsRequiredLoggingService.errorHandler = errorHandler;
  }
  public static getLogger(): NGXLogger | null {
    if (!IsRequiredLoggingService.logger) {
      return null;
    }
    return IsRequiredLoggingService.logger;
  }

  public static getErrorHandler(): ErrorhandlerService | null {
    if (!IsRequiredLoggingService.errorHandler) {
      return null;
    }
    return IsRequiredLoggingService.errorHandler;
  }
}
