<ng-container *ngIf="!state">
  <div class="container-fluid intro">
    <div class="row">
      <div class="col-md-10 mx-auto logo">
        <img src="./assets/nista_logo.svg" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 mx-auto">
        <h1>Something's wrong here...</h1>
        <h2>We can't find the page you were looking for.</h2>
        <p>You can navigate back to your dashboard or play a game!</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 mx-auto buttons">
        <button app-nista-button color="primary" (click)="startGameLoop()">
          Play Flappy Bird
        </button>
        <button
          app-nista-button
          color="secondary"
          (click)="navigateToDashboard()"
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="state">
  <p>Score: {{ state.score }}</p>

  <div
    class="bird"
    #bird
    [style.top]="100 - state.birdPositionY / 10 + '%'"
    [style.left]="100 - state.birdPositionX / 10 + '%'"
  >
    <img src="./assets/nista_logo.svg" />
  </div>

  <div
    class="wall wall-upper"
    [style.right]="100 - wall.x / 10 + '%'"
    [style.height]="wall.y / 10 + '%'"
    *ngFor="let wall of state.upperWalls"
  ></div>
  <div
    class="wall wall-lower"
    [style.right]="100 - wall.x / 10 + '%'"
    [style.height]="wall.y / 10 + '%'"
    *ngFor="let wall of state.lowerWalls"
  ></div>

  <div *ngIf="showScoreBoard" class="score-board">
    <div class="score-text">You did great!</div>
    <div class="score-text">{{ state.score }} points</div>
    <div class="row buttons">
      <button
        app-nista-button
        size="medium"
        color="primary"
        (click)="startGameLoop()"
      >
        Restart
      </button>
      <button
        app-nista-button
        size="medium"
        color="secondary"
        (click)="navigateToDashboard()"
      >
        Go to Dashboard
      </button>
    </div>
  </div>
</ng-container>
