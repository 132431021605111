<div class="dialog-container">
  <div class="top">
    <h2 mat-dialog-title>Gefällt Ihnen nista.io?</h2>
    <star-rating
      size="large"
      [hoverEnabled]="true"
      [starType]="'svg'"
      [rating]="rating"
      (ratingChange)="onRatingChange($event)"
    >
    </star-rating>
  </div>
  <ng-scrollbar
    thumbClass="scroll-thumb"
    barClass="scroll-bar"
    class="scroll-container"
    [class.image-attached]="image"
  >
    <div class="content">
      <div class="feedbackText">
        <label>Gibt es etwas das Sie uns mitteilen wollen?</label>
        <mat-form-field appearance="outline" class="formfield">
          <textarea
            autosize
            [minRows]="5"
            [maxRows]="10"
            matInput
            [(ngModel)]="feedbackText"
            placeholder="Schreiben Sie Ihre Meinung ..."
          ></textarea></mat-form-field
        ><br />
      </div>
      <br />
      <div class="screenshot">
        <div class="imagecontainer" *ngIf="image">
          <img [src]="image" />
          <div class="close" (click)="deleteCapture()">
            <app-gnista-icon class="icon" icon="delete"></app-gnista-icon>
          </div>
        </div>
        <a (click)="capture()" *ngIf="!image">
          <app-gnista-icon icon="screenshot">screenshot</app-gnista-icon> 
          Der aktuelle Bildschirm wird als Screenshot angehängt
        </a>
      </div>
    </div>
  </ng-scrollbar>

  <div class="dialogactions" mat-dialog-actions>
    <button
      app-nista-button
      size="medium"
      color="secondary"
      (click)="onNoClick()"
    >
      Abbrechen
    </button>
    <button
      app-nista-button
      size="medium"
      color="primary"
      [mat-dialog-close]="result"
      cdkFocusInitial
    >
      Senden
    </button>
  </div>
</div>
