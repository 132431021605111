import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  DataTransfer,
  DataTransferService,
  HttpDirection,
} from 'src/app/api.servicesbase';

@Component({
  selector: 'app-workspace-root',
  templateUrl: './workspace-root.component.html',
  styleUrls: ['./workspace-root.component.scss'],
})
export class WorkspaceRootComponent implements OnInit {
  data?: Observable<DataTransfer>;

  direction: HttpDirection = HttpDirection.down;

  constructor(private transfereService: DataTransferService) {}

  ngOnInit(): void {
    this.data = this.transfereService.data;
  }
}
