import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PendingChangesService {

  private pendingChanges = false;

  public clearPendingChanges() {
    this.pendingChanges = false;
  }

  public setPendingChanges() {
    this.pendingChanges = true;
  }

  constructor() { }

  public get hasPendingChanges(): boolean {
    return this.pendingChanges;
  }
}
