import { debounce } from '@agentepsilon/decko';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterSizeConfig } from 'lottie-web';
import { filter, Observable } from 'rxjs';
import { DataTransfer, HttpDirection } from 'src/app/api.servicesbase';

@Component({
  selector: 'app-http-loading-bar',
  templateUrl: './http-loading-bar.component.html',
  styleUrls: ['./http-loading-bar.component.scss'],
})
export class HttpLoadingBarComponent implements OnInit {
  @Input()
  data?: Observable<DataTransfer>;

  displayValue?: number;

  @Input()
  direction?: HttpDirection;

  show = false;

  @Input()
  alwaysShow = false;

  @Input()
  filter: (item: DataTransfer) => boolean = (item: DataTransfer) => true;

  @Output()
  transferFinished: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
    this.show = this.alwaysShow;
    this.data?.pipe(filter((item) => this.filter(item))).subscribe({
      next: (data) => {
        if (!this.direction || this.direction === data.direction) {
          this.setShow(true);
          this.displayValue = this.getPercentDone(data);
          if (this.displayValue === 100) {
            this.transferFinished.next();
          }
        } else {
          this.setShow(this.alwaysShow);
        }
      },
    });
  }

  @debounce(1500)
  setShow(value: boolean) {
    this.show = value;
  }

  private getPercentDone(data: DataTransfer): number | undefined {
    if (data.loaded && data.total) {
      return Math.round((100 * data.loaded) / data.total);
    } else {
      return undefined;
    }
  }
}
