import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthAuthenticationService } from '../OAuth/oAuthAuthentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private oauthService: OAuthAuthenticationService,
    private router: Router) { }

  ngOnInit(): void {
    if (this.oauthService.hasValidToken()) {
      this.logout();
    } else {
      this.router.navigate(['/home']);
    }
  }

  public logout() {
    this.oauthService.logout();
  }
}
