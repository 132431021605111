import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FacilityRootGuard } from './OAuth/facilityroot.guard';
import { OidcGuard } from './OAuth/oidc.guard';
import { WorkspaceRootGuard } from './OAuth/workspaceroot.guard';
import { LicenseComponent } from './common-components/license-info/license-info.component';
import { Page404Component } from './common-components/page404/page404.component';
import { LoadingComponent } from './loading/loading.component';
import { LogginginComponent } from './loggingin/loggingin.component';
import { LogoutComponent } from './logout/logout.component';
import { FacilityNavigatorGuard } from './navigation/facilitynavigator.guard';
import { GlobalNavigatorGuard } from './navigation/global-navigation.guard';
import { WorkspaceNavigatorGuard } from './navigation/workspacenavigator.guard';
import { StatusComponent } from './status/status.component';
import { WorkspaceWizardComponent } from './workspace-wizard/workspace-wizard.component';
import { FacilityRootComponent } from './workspace/facility-root/facility-root.component';
import { GlobalRootComponent } from './workspace/global-root/global-root.component';
import { NoWorkspaceComponent } from './workspace/no-workspace/no-workspace.component';
import { WorkspaceRootComponent } from './workspace/workspace-root/workspace-root.component';
import { WorkspaceSelectComponent } from './workspace/workspace-select/workspace-select.component';
import { WorkspaceComponent } from './workspace/workspace/workspace.component';

const routes: Routes = [
  { path: 'home', redirectTo: '/workspace' },
  { path: 'status', component: StatusComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'loading', component: LoadingComponent },
  { path: 'loggingin', component: LogginginComponent },
  { path: 'licenses', component: LicenseComponent },
  {
    path: 'facility-wizard',
    redirectTo: '/workspace',
    pathMatch: 'full',
  },
  { path: '', redirectTo: '/workspace', pathMatch: 'full' },
  {
    path: 'workspace',
    component: WorkspaceComponent,
    canActivate: [OidcGuard],
    children: [
      {
        path: '',
        component: WorkspaceSelectComponent,
        pathMatch: 'full',
        canActivate: [WorkspaceNavigatorGuard],
      },
      {
        path: 'none',
        component: NoWorkspaceComponent,
      },
      {
        path: ':workspaceId/workspace-wizard',
        component: WorkspaceWizardComponent,
        pathMatch: 'full',
      },
      {
        path: ':workspaceId',
        component: WorkspaceRootComponent,
        canActivate: [WorkspaceRootGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'facility',
          },
          {
            path: 'calculation',
            loadChildren: () =>
              import(`./calculation/calculation.module`).then(
                (m) => m.CalculationModule
              ),
          },
          {
            path: 'facility/:facilityId',
            component: FacilityRootComponent,
            canActivate: [FacilityNavigatorGuard],
            pathMatch: 'full',
          },
          {
            path: 'facility/:facilityId',
            component: FacilityRootComponent,
            canActivate: [FacilityRootGuard],
            children: [
              {
                path: 'dashboard',
                loadChildren: () =>
                  import(`./dashboard/dashboard.module`).then(
                    (m) => m.DashboardModule
                  ),
                canActivate: [OidcGuard],
              },
            ],
          },
          {
            path: 'facility',
            component: FacilityRootComponent,
            canActivate: [FacilityNavigatorGuard],
            pathMatch: 'full',
          },
          {
            path: 'global',
            component: GlobalRootComponent,
            canActivate: [GlobalNavigatorGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard',
              },
              {
                path: 'dashboard',
                loadChildren: () =>
                  import(`./dashboard/dashboard.module`).then(
                    (m) => m.DashboardModule
                  ),
                canActivate: [OidcGuard],
              },
            ],
          },
          { path: 'dashboard', redirectTo: 'global/dashboard' },

          { path: '**', redirectTo: 'dashboard' },
        ],
      },
    ],
  },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
