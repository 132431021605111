import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { OAuthAuthenticationService } from './OAuth/oAuthAuthentication.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  constructor(
    private settingsService: SettingsService,
    private authService: OAuthAuthenticationService,
    private logger: NGXLogger
  ) {}

  public startConnection(subUrl: string): SignalRConnection {
    const logger: signalR.ILogger = {
      log: (level: signalR.LogLevel, message: string) => {
        if (level === signalR.LogLevel.Warning) {
          this.logger.warn(message);
          return;
        }
        if (level === signalR.LogLevel.Trace) {
          this.logger.trace(message);
          return;
        }
        if (level === signalR.LogLevel.Information) {
          this.logger.info(message);
          return;
        }
        if (level === signalR.LogLevel.Error) {
          this.logger.error(message);
          return;
        }
        if (level === signalR.LogLevel.Debug) {
          this.logger.debug(message);
          return;
        }
        if (level === signalR.LogLevel.Critical) {
          this.logger.fatal(message);
          return;
        }
      },
    };

    const httpOptions: signalR.IHttpConnectionOptions = {
      accessTokenFactory: () => firstValueFrom(this.getToken()),
      withCredentials: true,
      logMessageContent: false,
    };

    const hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(logger)
      .withUrl(this.settingsService.active.websocketUrl + subUrl, httpOptions)
      .withAutomaticReconnect()
      .build();

    return new SignalRConnection(hubConnection);
  }

  private getToken(): Observable<string> {
    return this.authService.isAuthenticated.pipe(
      filter((item) => item === true),
      map(() => this.authService.accessToken),
      filter((item) => item !== undefined),
      take(1)
    );
  }
}

export class SignalRConnection {
  private initFinished = false;

  private disconnected = false;

  constructor(private hubConnection: signalR.HubConnection) {}

  private reconnectingEventEmitter = new Subject<void>();

  private reconnectedEventEmitter = new Subject<void>();

  private closeEventEmitter = new Subject<Error | undefined>();

  private subscriptions: Subscription[] = [];

  onReconnected(): Observable<void> {
    return this.reconnectedEventEmitter.asObservable();
  }

  onReconnecting(): Observable<void> {
    return this.reconnectingEventEmitter.asObservable();
  }

  onClose(): Observable<Error | undefined> {
    return this.closeEventEmitter.asObservable();
  }

  connect(): Observable<void> {
    if (this.initFinished) {
      throw new Error('Cannot connect again');
    }

    this.initFinished = true;

    return new Observable<void>((observer) => {
      this.hubConnection.onreconnected((connectionId) => {
        this.reconnectedEventEmitter.next();
      });

      this.hubConnection.onreconnecting((connectionId) => {
        this.reconnectingEventEmitter.next();
      });

      this.hubConnection.onclose((error) => {
        this.closeEventEmitter.next(error);
      });

      this.hubConnection.start().then(
        (onfulfilled) => {
          observer.next();
        },
        (onreject) => {
          if (this.disconnected) {
            return;
          }
          this.closeEventEmitter.next(onreject);
        }
      );
    });
  }

  get connectionId(): string | null {
    if (this.hubConnection) {
      return this.hubConnection.connectionId;
    }
    return null;
  }

  disconnect(): Observable<void> {
    if (!this.initFinished) {
      throw new Error('Cannot disconnect an unconnected connection');
    }
    this.disconnected = true;

    return new Observable<void>((observer) => {
      this.hubConnection
        .stop()
        .then(() => {
          for (const subscrition of this.subscriptions) {
            subscrition.unsubscribe();
          }
          observer.next();
        })
        .catch((err) => console.error(err));
    });
  }

  send(methodName: string, ...data: any[]): Observable<void> {
    if (!this.initFinished) {
      throw new Error('Cannot send on an unconnected connection');
    }

    return new Observable<void>((observer) => {
      this.hubConnection.send(methodName, ...data).then(
        (result) => observer.next(),
        (err) => observer.error(err)
      );
    });
  }

  on<T>(methodName: string, convert: (...data: any[]) => T): Observable<T> {
    return new Observable<T>((observer) => {
      this.hubConnection.on(methodName, (...data: any[]) => {
        const result = convert(data);
        observer.next(result);
      });
    });
  }

  collect(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }
}
