import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent implements OnInit {
  @Input()
  width?: string;

  @Input()
  asset?: string;

  constructor() { }

  ngOnInit(): void {
  }

  options: AnimationOptions = {
    path: this.getAnimation(this.asset || 'nista'),
  };

  get imageWidth(): string {
    if (!this.width) {
      return '300px';
    }
    return this.width;
  }

  getAnimation(asset: string): string {
    if(!asset || asset === 'nista')
    {
      return '/assets/nista-logo-animated-no-text.json';
    }
    if(asset === 'square')
    {
      return '/assets/square-animation.json';
    }
    return '/assets/nista-logo-animated-no-text.json';
  }
}
