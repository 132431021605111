import { Injectable } from '@angular/core';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsOAuthModuleConfig extends OAuthModuleConfig {
  constructor(private settings: SettingsService) {
    super();
    SettingsService.settingsChanged.subscribe((newSettings) => {
      if (!newSettings) {
        return;
      }

      this.resourceServer = {
        allowedUrls: [
          newSettings.issuerURL,
          newSettings.fileApiUrl,
          newSettings.facilityApiUrl,
          newSettings.dataPointApiUrl,
          newSettings.sampleApiUrl,
          newSettings.searchApiUrl,
          newSettings.agentApiUrl,
          newSettings.workspaceApiUrl,
          newSettings.calculationApiUrl,
          newSettings.analysisApiUrl,
          newSettings.reportApiUrl,
          newSettings.notificationApiUrl,
          newSettings.trackingApiUrl,
          newSettings.liveDataApiUrl,
          newSettings.bigBrainApiUrl,
          newSettings.taskApiUrl,
          newSettings.violationApiUrl,
        ],
        sendAccessToken: true,
      };
    });
  }
}
