import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { WorkspaceClient, WorkspaceRequest } from '../workspace-service-client';
import { WorkspaceService } from '../workspace.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-workspace-wizard',
  templateUrl: './workspace-wizard.component.html',
  styleUrls: ['./workspace-wizard.component.scss'],
})
export class WorkspaceWizardComponent implements OnInit {
  allValid = new BehaviorSubject(false);

  companyName?: string;

  sending = new BehaviorSubject(false);
  constructor(
    private workspaceClient: WorkspaceClient,
    private workspaceService: WorkspaceService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(take(1)).subscribe((params: ParamMap) => {
      const workspaceId = String(params.get('workspaceId'));
      this.workspaceService
        .setWorkspaceId(workspaceId)
        .pipe(take(1))
        .subscribe();
    });
  }

  companyNameValid(value: string | null | undefined): boolean {
    if (!value) {
      return false;
    }

    if (value.length <= 0) {
      return false;
    }

    return true;
  }

  setCompanyName($event: string) {
    this.companyName = $event;
    this.allValid.next(this.companyNameValid($event));
  }

  updateWorkspace() {
    const workspaceId = this.workspaceService.workspaceId;
    if (!workspaceId) {
      return;
    }

    this.sending.next(true);

    const workspacecRequest = new WorkspaceRequest({
      name: this.companyName,
      companyName: this.companyName,
    });

    this.workspaceClient
      .updateWorkspace(workspaceId, workspacecRequest)
      .subscribe(() => {
        this.router.navigate(['/', 'workspace', workspaceId]);
      });
  }
}
