import { DecimalPipe } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Instance, createPopper } from '@popperjs/core';
import { ReplaySubject, filter, fromEvent, takeUntil } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent
  implements AfterViewInit, OnDestroy, AfterContentInit
{
  @Input()
  public title?: string = 'Uhrzeit';

  @Input()
  public size: Size = 'medium';

  @Input()
  public initialValue?: string;

  @Input()
  public currentValueIsInvalid = false;

  @Input()
  public disabled = false;

  private _hours?: number;
  private _mintues?: number;

  selectedHour: number | undefined;
  selectedMinute: number | undefined;

  typedInvalidCharacter = false;

  userTypedValue = '';

  closed = new EventEmitter();

  @Output()
  currentTime = new EventEmitter<Time | undefined>();

  @Output()
  currentTimeAsString = new ReplaySubject<string | undefined>(1);

  openState = false;

  private popperRef?: Instance;
  private view?: EmbeddedViewRef<any>;

  @ViewChild('root')
  rootElementRef!: ElementRef<HTMLElement>;

  rootElement!: HTMLElement;

  @ViewChild('dropdown')
  dropdownTpl!: TemplateRef<any>;

  private ro: ResizeObserver | null = null;
  dropdown: any;

  constructor(
    private _decimalPipe: DecimalPipe,
    private vcr: ViewContainerRef,
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    this.setWithInitialValue();
  }

  private setWithInitialValue() {
    if (!this.initialValue) {
      this.setTimeToNow();
      this.updateTimeString();
      this.userTypedValue =
        this._decimalPipe.transform(this._hours, '2.0-0') +
        ':' +
        this._decimalPipe.transform(this._mintues, '2.0-0');
    } else {
      this.typedTime(this.initialValue);
    }
  }

  private registerObserver() {
    this.ro = new ResizeObserver(() => {
      this.popperRef?.update();
    });

    if (!this.rootElement) {
      return;
    }

    this.ro.observe(this.rootElement);
  }

  get hours(): number | undefined {
    return this._hours ?? undefined;
  }

  trySetHours(newValue: number | undefined): boolean {
    if (newValue !== undefined && (newValue < 0 || newValue > 23)) {
      return false;
    }
    this.setTime(newValue, this._mintues);
    this.updateTimeString();
    return true;
  }

  get mintues(): number | undefined {
    return this._mintues ?? undefined;
  }

  get selectedTimeAsTime(): Time {
    return { hour: this.selectedHour, minute: this.selectedMinute };
  }

  trySetMintues(newValue: number | undefined): boolean {
    if (newValue !== undefined && (newValue < 0 || newValue > 59)) {
      return false;
    }

    this.setTime(this._hours, newValue);
    this.updateTimeString();
    return true;
  }

  updateTimeString() {
    const timeString =
      this._decimalPipe.transform(this._hours, '2.0-0') +
      ':' +
      this._decimalPipe.transform(this._mintues, '2.0-0');
    this.currentTimeAsString.next(timeString);
  }

  get availableHours(): number[] {
    return [...Array(24).keys()];
  }

  get availableMinutes(): number[] {
    return [...Array(60).keys()];
  }

  setTime(newHour: number | undefined, newMinute: number | undefined): void {
    this._hours = newHour;
    this.selectedHour = newHour;
    this._mintues = newMinute;
    this.selectedMinute = newMinute;
  }

  setTimeToNow(): void {
    const now = new Date();
    this.trySetHours(now.getHours());
    this.trySetMintues(now.getMinutes());
  }

  typedTime(typedValue: string) {
    this.userTypedValue = typedValue;

    typedValue = typedValue.trim();

    if (typedValue === undefined || typedValue === '') {
      this.trySetHours(undefined);
      this.trySetMintues(undefined);
      this.typedInvalidCharacter = true;
      return;
    }

    const validCharacterRegex = new RegExp(
      '^([0-1]?[0-9]|2[0-3])(:)?([0-5][0-9])?$'
    );
    if (!validCharacterRegex.test(typedValue)) {
      this.trySetHours(undefined);
      this.trySetMintues(undefined);
      this.typedInvalidCharacter = true;
      return;
    } else {
      this.typedInvalidCharacter = false;
    }

    const typpedOnlyHoursRegex = new RegExp('^([0-1]?[0-9]|2[0-3])(:)?$');
    if (typpedOnlyHoursRegex.test(typedValue)) {
      this.selectedHour = Number.parseInt(typedValue.split(':')[0], 10);
      this._mintues = undefined;
    }

    const typpedAllRegex = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');
    if (typpedAllRegex.test(typedValue)) {
      this.trySetHours(Number.parseInt(typedValue.split(':')[0], 10));

      this.trySetMintues(Number.parseInt(typedValue.split(':')[1], 10));
    }
  }

  selectHour(selection: number) {
    this.selectedHour = selection;
  }

  selectMinute(selection: number) {
    this.selectedMinute = selection;
  }

  setTimeWithSelection(time: Time) {
    if (time.hour === undefined) {
      return;
    }
    if (time.minute === undefined) {
      return;
    }

    this.trySetHours(time.hour);
    this.trySetMintues(time.minute);
    this.typedInvalidCharacter = false;
    this.userTypedValue =
      this._decimalPipe.transform(this._hours, '2.0-0') +
      ':' +
      this._decimalPipe.transform(this._mintues, '2.0-0');
    this.currentTime.emit(time);
  }

  ngAfterViewInit(): void {
    this.rootElement = this.rootElementRef.nativeElement;
    this.registerObserver();
  }

  get isOpen() {
    return this.popperRef !== undefined;
  }

  toggleOpen(mouseEvent: MouseEvent | undefined = undefined) {
    if (this.disabled) {
      return;
    }
    if (this.isOpen) {
      this.close();
      return;
    }
    this.openState = true;
    this.view = this.vcr.createEmbeddedView(this.dropdownTpl);
    this.dropdown = this.view.rootNodes[0];

    document.body.appendChild(this.dropdown);

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(this.rootElement, this.dropdown, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      });
    });
    this.handleClickOutside();

    this.opened.next();
  }

  opened: EventEmitter<void> = new EventEmitter();

  private handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        filter(
          ({ target }) =>
            this.rootElement.contains(target as HTMLElement) === false
        ),
        takeUntil(this.closed)
      )
      .subscribe((event) => {
        const dropdownElement = this.dropdown as HTMLElement;
        if (dropdownElement.contains(event.target as HTMLElement)) {
          event.stopPropagation();
        } else {
          this.close();
          this.cdr.detectChanges();
        }
      });
  }

  @HostListener('keydown.tab', ['$event'])
  @HostListener('keydown.shift.tab', ['$event'])
  loseFocus(event: FocusEvent) {
    this.close();
  }

  close() {
    this.closed.emit();
    this.popperRef?.destroy();
    this.view?.destroy();
    this.view = undefined;
    this.popperRef = undefined;
    this.openState = false;
  }

  ngOnDestroy(): void {
    this.close();
  }
}

export type Size = keyof typeof SIZE;

export const SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export type Time = {
  hour: number | undefined;
  minute: number | undefined;
};
