<div class="tab-navigation-bar-container">
  <div class="tab-navigation-bar-wrapper">
    <div class="scrollWrapper" *ngIf="hasScrolling">
      <div class="scrollIndicator">
        <button
          app-nista-icon-button
          [icon]="'chevronLeft'"
          class="scrollIcon"
          (click)="scroll($event, -1)"
          [disabled]="!enableScrollLeft"
        ></button>
        <app-gnista-icon
          [icon]="'chevronLeft'"
          style="visibility: hidden"
        ></app-gnista-icon>
      </div>
      <div class="spacer"></div>
    </div>
    <div class="tab-navigation-bar">
      <ng-scrollbar
        class="ngx-scrollbar"
        thumbClass="scroll-thumb"
        barClass="scroll-bar"
        track="horizontal"
        viewClass="scrollview-2"
        #scrollView
        visibility="hover"
      >
        <div
          class="all-buttons"
          #tabNavigationBar
          [ngClass]="{ scrollVisible: hasScrolling }"
        >
          <app-tab-navigation-button
            *ngFor="let tabItem of tabItems"
            [label]="tabItem.label"
            [isActive]="tabItem.isActive"
            [unreadIndicator]="tabItem.unreadIndicator"
            [showUnreadIndicator]="tabItem.showUnreadIndicator"
            (clicked)="activate(tabItem)"
          ></app-tab-navigation-button>
        </div>
      </ng-scrollbar>
    </div>
    <div class="scrollWrapper" *ngIf="hasScrolling">
      <div class="scrollIndicator">
        <button
          app-nista-icon-button
          [icon]="'chevronRight'"
          class="scrollIcon"
          (click)="scroll($event, 1)"
          [disabled]="!enableScrollRight"
        ></button>
        <app-gnista-icon
          [icon]="'chevronRight'"
          style="visibility: hidden"
        ></app-gnista-icon>
      </div>
      <div class="spacer"></div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
