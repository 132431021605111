import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { Icon } from './../gnista-icon/gnista-icon.component';

@Component({
  selector: 'app-nista-input',
  templateUrl: './nista-inputfield.component.html',
  styleUrls: ['./nista-inputfield.component.scss'],
})
export class NistaInputfieldComponent
  implements OnChanges, AfterViewInit, OnInit, OnDestroy
{
  @Input()
  size: Size = 'medium';

  @Input()
  disabled?: boolean;

  @Input()
  numberOfLines = 1;

  @Input()
  showOptional?: boolean;

  @Input()
  helpText?: string;

  @Input()
  title?: string;

  @Input()
  placeholder?: string;

  placeholderToShow?: string;

  @Input()
  displayValue?: string;

  savedValue?: string;

  @Input()
  editAllowed = true;

  @Input()
  iconLeft?: Icon = undefined;

  @Input()
  autoFocus = true;

  @Input()
  selectAllOnFocus = false;

  @Input()
  iconRight?: Icon = undefined;

  iconRightSetByUser = false;

  @Input()
  isPassword?: boolean = false;

  @Input()
  blurSave?: boolean = true;

  @Input()
  valid?: (value: string | undefined | null) => boolean = undefined;

  @ViewChild('singleLine')
  singleLineEdit!: TemplateRef<any>;

  @ViewChild('singleLineInput')
  singleLineInput?: ElementRef<HTMLInputElement>;

  @ViewChild('multiLineInput')
  multiLineInput?: ElementRef<HTMLTextAreaElement>;

  @ViewChild('multiLine')
  multiLineEdit!: TemplateRef<any>;

  @ViewChild(MatAutocompleteTrigger)
  autocomplete?: MatAutocompleteTrigger;

  inputElement!: TemplateRef<any>;

  @Input()
  isValid?: boolean;

  @Input()
  isError = false;

  @Input()
  autoCompleteList?: KeyValue<string, unknown>[];

  @Input()
  autoCompleteTemplate: TemplateRef<any> | null = null;

  @Output()
  typed: EventEmitter<string> = new EventEmitter();

  @Output()
  saved: EventEmitter<ViewValue> = new EventEmitter();

  @Output()
  canceled: EventEmitter<void> = new EventEmitter();

  @Output()
  focused: EventEmitter<void> = new EventEmitter();

  @Output()
  validChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.typed.subscribe({
      next: (value: string) => {
        if (!this.valid) {
          this.isValid = undefined;
          return;
        }
        this.handleValidation(value);
      },
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.iconRightSetByUser = this.iconRight !== undefined;
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  ngAfterViewInit(): void {
    this.updateTemplate();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayValue) {
      this.displayValue = changes.displayValue.currentValue;
      this.savedValue = changes.displayValue.currentValue;
    }

    if (changes.numberOfLines) {
      this.numberOfLines = changes.numberOfLines.currentValue ?? 1;
    }

    if (changes.placeholder) {
      this.placeholder = changes.placeholder.currentValue;
      this.placeholderToShow = this.setPlaceholder();
    }

    if (changes.showOptional) {
      this.showOptional = changes.showOptional.currentValue;
      this.placeholderToShow = this.setPlaceholder();
    }

    this.updateTemplate();
  }

  private setPlaceholder(): string | undefined {
    if (!this.placeholder) {
      return undefined;
    }

    if (this.title) {
      return this.placeholder;
    }

    if (this.showOptional === undefined) {
      return this.placeholder;
    }

    return this.showOptional
      ? this.placeholder + ' (optional)'
      : this.placeholder + ' (Pflichtfeld)';
  }

  public clear() {
    this.typed.emit('');
    this.savedValue = '';
    this.displayValue = '';

    if (this.singleLineInput) {
      this.singleLineInput.nativeElement.value = '';
    }

    if (this.multiLineInput) {
      this.multiLineInput.nativeElement.value = '';
    }
  }

  scrollEvent = (event: any): void => {
    if (this.autocomplete?.panelOpen) {
      this.autocomplete.updatePosition();
    }
  };

  updateTemplate() {
    if (this.numberOfLines > 1) {
      this.inputElement = this.multiLineEdit;
    } else {
      this.inputElement = this.singleLineEdit;
    }
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.saved.emit({ reason: 'select', text: event.option.value });
  }

  trackKey(index: number, value: KeyValue<string, unknown>) {
    return value.key;
  }

  onBlur(event: FocusEvent): void {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (
      relatedTarget instanceof Element &&
      relatedTarget.tagName.toLocaleLowerCase() === 'mat-option'
    ) {
      return;
    }
    if (!this.blurSave) {
      return;
    }
    this.onEnter(event);
  }

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: Event): void {
    if (!this.editAllowed) {
      return;
    }
    if (
      !(event.target instanceof HTMLInputElement) &&
      !(event.target instanceof HTMLTextAreaElement)
    ) {
      return;
    }
    const eventTarget = event.target as HTMLInputElement;
    eventTarget.blur();

    if (eventTarget.value === this.savedValue) {
      return;
    }
    if (this.valid !== undefined && this.isValid === undefined) {
      this.handleValidation(eventTarget.value);
    }

    this.saved.emit({
      reason: event.type,
      text: eventTarget.value,
      valid: this.isValid,
    });
    this.autocomplete?.closePanel();
    this.savedValue = eventTarget.value;
  }

  private handleValidation(value: string) {
    if (!this.valid) {
      return;
    }
    this.isValid = this.valid(value);
    this.validChanged.next(this.isValid);
    this.isError = !this.isValid;
    if (!this.iconRightSetByUser) {
      this.iconRight = this.isValid ? 'done' : 'warning';
    }
  }

  @HostListener('keyup.esc')
  onEscape(): void {
    if (!this.editAllowed) {
      return;
    }
    this.canceled.emit();
  }

  onInput(event: Event): void {
    if (
      !(event.target instanceof HTMLInputElement) &&
      !(event.target instanceof HTMLTextAreaElement)
    ) {
      return;
    }

    this.typed.emit(event.target.value);
  }

  onFocus(event: Event): void {
    this.focused.emit();

    if (
      !(event.target instanceof HTMLInputElement) &&
      !(event.target instanceof HTMLTextAreaElement)
    ) {
      return;
    }

    if (this.selectAllOnFocus) {
      event.target.select();
    }
  }
}

export type Size = keyof typeof SIZE;

export const SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export interface ViewValue {
  reason?: string;
  data?: any;
  valid?: boolean;
  text: string;
}
