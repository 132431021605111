import { EventEmitter, Injectable } from '@angular/core';
import { Settings } from './settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private static settings: Settings = {
    baseUrl: '',
    analysisApiUrl: '',
    calculationApiUrl: '',
    sampleApiUrl: '',
    searchApiUrl: '',
    agentApiUrl: '',
    dataPointApiUrl: '',
    fileApiUrl: '',
    facilityApiUrl: '',
    issuerURL: '',
    websocketUrl: '',
    loggingApiUrl: '',
    statusApiUrl: '',
    trackingApiUrl: '',
    experimental: '',
    workspaceApiUrl: '',
    reportApiUrl: '',
    notificationApiUrl: '',
    liveDataApiUrl: '',
    bigBrainApiUrl: '',
    taskApiUrl: '',
    violationApiUrl: '',
  };

  public static settingsChanged = new EventEmitter<Settings>();

  public set active(settings: Settings) {
    SettingsService.settings = settings;
    SettingsService.settingsChanged?.emit(settings);
  }

  public get active(): Settings {
    return SettingsService.settings;
  }

  public get experimental(): boolean {
    return this.active.experimental.toLocaleUpperCase() === 'TRUE';
  }
}
