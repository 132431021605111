import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FacilityResponse } from 'src/app/facility-service-client';
import {
  LiveDataStatusClient,
  LiveDataStatusResponse,
} from 'src/app/liveData-service-client';
import {
  ReportListDetailsResponse,
  ReportClient,
  ReportListResponse,
} from 'src/app/report-service-client';
import { RequestQueueService } from 'src/app/request-queue.service';

@Component({
  selector: 'app-facility-status-widget',
  templateUrl: './facility-status-widget.component.html',
  styleUrls: ['./facility-status-widget.component.scss'],
})
export class FacilityStatusWidgetComponent implements OnChanges, OnDestroy {
  @Input() facility?: FacilityResponse;
  @Input() workspaceId?: string;

  @Output() loadingEmitter: EventEmitter<[string, boolean]> = new EventEmitter<
    [string, boolean]
  >();

  @Output() errorEmitter: EventEmitter<[string, string]> = new EventEmitter<
    [string, string]
  >();

  reportsResponse?: ReportListDetailsResponse[];
  liveDataStatusResponse?: LiveDataStatusResponse[];

  loading = new BehaviorSubject<boolean>(true);

  private destroyed$ = new Subject<void>();
  liveDataLoadingMap?: Map<string, boolean>;

  constructor(
    private liveDataStatusClient: LiveDataStatusClient,
    private reportClient: ReportClient,
    private queueService: RequestQueueService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.facility) {
      this.facility = changes.facility.currentValue;
      this.destroyed$.next();
      if (!!this.facility?.facilityId) {
        this.loadingEmitter.next([this.facility?.facilityId, true]);
      }
    }
    if (changes.workspaceId) {
      this.workspaceId = changes.workspaceId.currentValue;
      this.destroyed$.next();
    }
    this.updateIfPossible();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateIfPossible(): void {
    if (!this.workspaceId) {
      return;
    }

    if (!this.facility) {
      return;
    }

    if (!this.facility.facilityId) {
      return;
    }
    this.loading.next(true);
    this.loadingEmitter.next([this.facility?.facilityId, true]);

    this.updateLiveData(this.workspaceId, this.facility.facilityId);
    this.updateReportData(this.workspaceId, this.facility.facilityId);

    this.loading.next(!this.liveDataStatusResponse && !this.reportsResponse);
  }

  updateLiveData(workspaceId: string, facilityId: string): void {
    this.queueService
      .addToQueue<LiveDataStatusResponse[]>(
        this.liveDataStatusClient.get(workspaceId, facilityId),
        this.destroyed$
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.liveDataStatusResponse = response;
          this.liveDataLoadingMap = new Map(
            response.map((r) => [r.liveDataId ?? '', true])
          );
          if (response.length === 0) {
            this.loadingEmitter.emit([this.facility?.facilityId ?? '', false]);
          }
        },
      });
  }

  updateReportData(workspaceId: string, facilityId: string): void {
    if (!facilityId) {
      return;
    }

    this.queueService
      .addToQueue<ReportListResponse>(
        this.reportClient.list(workspaceId, 'Dashboard', facilityId),
        this.destroyed$
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (reports) => {
          this.reportsResponse = reports.reports;
          if (this.reportsResponse?.length === 0) {
            this.errorEmitter.emit([facilityId, 'No Dashboard']);
          }
        },
      });
  }

  updateLoadingMap(liveDataId: string | undefined, state: boolean) {
    if (!this.liveDataLoadingMap) {
      return;
    }

    if (!liveDataId) {
      return;
    }

    if (!this.facility?.facilityId) {
      return;
    }

    this.liveDataLoadingMap.set(liveDataId, !state);

    if (
      Array.from(this.liveDataLoadingMap.values()).every(
        (value) => value === false
      )
    ) {
      this.loadingEmitter.emit([this.facility?.facilityId, false]);
    }
  }
}
