import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, take, takeUntil, timeout } from 'rxjs';
import { OAuthAuthenticationService } from 'src/app/OAuth/oAuthAuthentication.service';
import {
  ActivityClient,
  CreateWorkspaceRequest,
} from 'src/app/bigbrain-service-client';
import { ExternalNavigationService } from 'src/app/external-navigation.service';
import { FeatureFlag } from 'src/app/featureflag.service';
import { Guid } from 'src/app/guid';
import { IdentityEventsService } from 'src/app/identity-events.service';
import { UserDetailsService } from 'src/app/user-details.service';
import { WorkspaceService } from 'src/app/workspace.service';

@Component({
  selector: 'app-no-workspace',
  templateUrl: './no-workspace.component.html',
  styleUrls: ['./no-workspace.component.scss'],
})
export class NoWorkspaceComponent implements OnInit, OnDestroy {
  userComparisonDate = new Date(2024, 0, 19);
  globalComparisonDate = new Date(2024, 3, 1);
  showFreeWorkspaceMessage = false;

  constructor(
    public workspaceService: WorkspaceService,
    private router: Router,
    public authService: OAuthAuthenticationService,
    private userDetailsService: UserDetailsService,
    protected featureFlag: FeatureFlag,
    private activityClient: ActivityClient,
    private identityEventService: IdentityEventsService,
    private externalNavigationService: ExternalNavigationService
  ) {}

  ngOnInit(): void {
    if (new Date().valueOf() > this.globalComparisonDate.valueOf()) {
      this.showFreeWorkspaceMessage = false;
    } else {
      if (!this.authService.email) {
        return;
      }
      this.userDetailsService
        .getUser(this.authService.email)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.showFreeWorkspaceMessage = result?.created
              ? result?.created?.valueOf() < this.userComparisonDate.valueOf()
              : true;
          },
        });
    }
  }

  destroyed$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get email(): string {
    return this.authService.email?.toLocaleLowerCase() ?? '';
  }

  public logout() {
    this.router.navigate(['/logout']);
  }

  public createWorkspace() {
    const newWorkspaceId = Guid.newGuid().toString();
    const newFacilityId = Guid.newGuid().toString();

    const request = new CreateWorkspaceRequest({
      workspaceId: newWorkspaceId,
      workspaceName: 'Starter Workspace',
      facilityId: newFacilityId,
      facilityName: 'Starter Facility',
      companyName: 'Starter Company',
      forUser: this.authService.email ?? '',
    });

    this.identityEventService.workspaceCreated
      .pipe(takeUntil(this.destroyed$), timeout(60000))
      .subscribe({
        next: (wce) => {
          if (wce.workspaceId === newWorkspaceId) {
            this.externalNavigationService.changeWorkspace(newWorkspaceId);
          }
          return;
        },
        error: () => {
          this.externalNavigationService.changeWorkspace(newWorkspaceId);
          return;
        },
      });

    this.activityClient.createWorkspace(request).subscribe();
  }
}
