<div
  #root
  class="timepicker-wrapper"
  [ngClass]="{
    'timepicker-lg': size === 'large',
    'timepicker-md': size === 'medium',
    'timepicker-sm': size === 'small'
  }"
  (click)="toggleOpen($event)"
>
  <app-nista-input
    [title]="title"
    [iconRight]="'time'"
    [editAllowed]="true"
    [size]="size"
    type="text"
    [displayValue]="userTypedValue"
    [isError]="typedInvalidCharacter || currentValueIsInvalid"
    (typed)="typedTime($event)"
    [selectAllOnFocus]="true"
    [disabled]="disabled"
  ></app-nista-input>
</div>

<ng-template #dropdown>
  <app-time-picker-dropdown
    (selected)="setTimeWithSelection($event)"
    [selectedHour]="selectedHour"
    [selectedMinute]="selectedMinute"
    [size]="size"
  ></app-time-picker-dropdown>
</ng-template>
