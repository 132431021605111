
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let BandJS: any;

export class MusicPlayer {
    player: any;

    private audioObj = new Audio();

    constructor() {
        this.play();
    }

    stop() {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
    }

    play() {
        // Play audio
        this.audioObj.src = './assets/bg_music.mp3';
        this.audioObj.loop = true;
        this.audioObj.load();
        this.audioObj.play();
    }
}
