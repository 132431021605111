<div class="loading-center">
  <div style="width: 300px; height: 120px">
    <app-loading-animation width="250px"></app-loading-animation>
  </div>

  <div #contentWrapper>
    <ng-content></ng-content>
  </div>

  <h1 *ngIf="contentWrapper.children.length === 0">Loading ...</h1>
</div>
