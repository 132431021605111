<div
  class="timepicker-dropdown"
  [ngClass]="{
    'timepicker-lg': size === 'large',
    'timepicker-md': size === 'medium',
    'timepicker-sm': size === 'small'
  }"
>
  <div class="dropdown-content">
    <ng-scrollbar
      #hourscroll
      thumbClass="scroll-thumb"
      barClass="scroll-bar"
      visibility="hover"
    >
      <div scrollViewport class="custom-viewport">
        <div class="scroll-content-wrapper">
          <div class="scroll-content-wrapper-content">
            <span
              *ngFor="let hour of availableHours"
              [ngClass]="{
                selected: hour === selectedHour
              }"
              (click)="selectHour(hour)"
              >{{ hour | number: "2.0-0" }}</span
            >
          </div>
        </div>
      </div>
    </ng-scrollbar>
    <ng-scrollbar
      #minutescroll
      thumbClass="scroll-thumb"
      barClass="scroll-bar"
      visibility="hover"
    >
      <div scrollViewport class="custom-viewport">
        <div class="scroll-content-wrapper">
          <div class="scroll-content-wrapper-content">
            <span
              *ngFor="let minute of availableMinutes"
              [ngClass]="{
                selected: minute === selectedMinute
              }"
              (click)="selectMinute(minute)"
              >{{ minute | number: "2.0-0" }}</span
            >
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
