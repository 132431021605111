<div class="container">
  <span class="text">
    <app-gnista-icon
      icon="wifiOff"
      matTooltip="Lost connection"
    ></app-gnista-icon>
    <span *ngIf="reconnecting">Trying to connect</span>
    <span *ngIf="!reconnecting"
      >Disconnected
      <a [routerLink]="" (click)="reloadCurrentPage()">Reload</a>
    </span>
  </span>
</div>

<div class="darken-overlay"></div>
