<app-snackbar
  [text]="obj.text"
  [icon]="obj.icon"
  [closeable]="obj.closeable ?? false"
  [headline]="obj.headline"
  [action1]="obj.action1"
  [action2]="obj.action2"
  (closeClicked)="closeRequested()"
  (action1Clicked)="action1Clicked()"
  (action2Clicked)="action2Clicked()"
></app-snackbar>
