import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { empty } from 'rxjs/internal/observable/empty';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class DataTransferService {
  private _dataTransfer: Subject<DataTransfer> = new Subject<DataTransfer>();

  public newValue(value: DataTransfer) {
    this._dataTransfer.next(value);
  }

  public get data(): Observable<DataTransfer> {
    return this._dataTransfer.asObservable();
  }
}

export enum HttpDirection {
  down = 'DOWN',
  up = 'UP',
}

export class DataTransfer {
  public loaded: number;
  public total?: number;
  public direction: HttpDirection;
  public url: string;

  constructor(
    direction: HttpDirection,
    loaded: number,
    url: string,
    total?: number
  ) {
    this.direction = direction;
    this.loaded = loaded;
    this.url = url;
    this.total = total;
  }

  public getPercentDone(): number | null {
    if (this.loaded && this.total) {
      return Math.round((100 * this.loaded) / this.total);
    } else {
      return null;
    }
  }
}

@Injectable()
export class ServiceBaseConfiguration {
  constructor(public dataTransferService: DataTransferService) {}
}

@Injectable()
export class ServiceBase {
  constructor(private serviceBaseConfiguration: ServiceBaseConfiguration) {}

  public transformOptions(options: any): Promise<any> {
    options.reportProgress = true;
    options.observe = 'events';
    return Promise.resolve(options);
  }

  public transformResult(
    url: string,
    response: any,
    processor: (response: any) => any
  ): Observable<any> {
    const type: HttpEventType = response.type;
    switch (type) {
      case HttpEventType.Sent:
        // The request was sent out over the wire.

        break;
      case HttpEventType.UploadProgress:
        // An upload progress event was received.
        this.serviceBaseConfiguration.dataTransferService.newValue(
          new DataTransfer(
            HttpDirection.up,
            response.loaded,
            url,
            response.total
          )
        );
        break;
      case HttpEventType.DownloadProgress:
        // A download progress event was received.
        this.serviceBaseConfiguration.dataTransferService.newValue(
          new DataTransfer(
            HttpDirection.down,
            response.loaded,
            url,
            response.total
          )
        );
        break;
      case HttpEventType.ResponseHeader:
        // The response status code and headers were received.
        break;
      //case HttpEventType.Response:
      //  // The full response including the body was received.
      //  break;
      default:
        this.serviceBaseConfiguration.dataTransferService.newValue(
          new DataTransfer(HttpDirection.up, 1, url, 1)
        );
        this.serviceBaseConfiguration.dataTransferService.newValue(
          new DataTransfer(HttpDirection.down, 1, url, 1)
        );
        return processor(response);
    }
    return empty();
  }
}
