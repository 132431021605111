import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import {
  Subject,
  Subscription,
  combineLatest,
  map,
  startWith,
  takeUntil,
} from 'rxjs';
import { TabNavigationItemComponent } from '../tab-navigation-item/tab-navigation-item.component';

@Component({
  selector: 'app-tab-navigation-group',
  templateUrl: './tab-navigation-group.component.html',
  styleUrls: ['./tab-navigation-group.component.scss'],
})
export class TabNavigationGroupComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  @ContentChildren(TabNavigationItemComponent)
  tabItems!: QueryList<TabNavigationItemComponent>;

  private destroyed$ = new Subject<void>();

  @ViewChild(NgScrollbar)
  scrollbarRef: NgScrollbar | undefined;

  @ViewChild('tabNavigationBar')
  tabNavigationBarRef: any;

  @Output()
  tabSelected: EventEmitter<string> = new EventEmitter<string>();

  enableScrollLeft = false;
  enableScrollRight = true;
  hasScrolling = false;

  scrollSubscription = Subscription.EMPTY;

  scrollPos = 0;

  private ro: ResizeObserver | null = null;

  tabGroupQueryName?: string;

  constructor(private actRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  private registerObserver() {
    this.ro = new ResizeObserver(() => {
      this.updateHasScrolling();
    });

    if (!this.scrollbarRef) {
      return;
    }

    this.ro.observe(this.scrollbarRef.nativeElement);
  }

  ngAfterContentInit(): void {
    this.initActiveTab();

    if (this.scrollbarRef) {
      this.scrollSubscription = this.scrollbarRef.scrolled
        .pipe(
          takeUntil(this.destroyed$),
          map((e: any) => this.handleScrollButtons(e))
        )
        .subscribe();
    }
    this.registerObserver();
  }

  initActiveTab() {
    if (this.tabGroupQueryName) {
      combineLatest([
        this.tabItems.changes.pipe(startWith(this.tabItems)),
        this.actRoute.queryParams.pipe(
          map((params) => {
            const paramsFound = params[this.tabGroupQueryName ?? ''];
            if (paramsFound) {
              return paramsFound;
            } else {
              return this.tabItems.first.label;
            }
          })
        ),
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(([tabs, param]) => {
          const paramVal = decodeURIComponent(param);
          if (paramVal) {
            tabs.forEach((tab: TabNavigationItemComponent) => {
              if (tab.label === paramVal) {
                this.activate(tab);
              }
            });
          }
        });
    } else {
      this.activate(this.tabItems.first);
    }
  }

  private handleScrollButtons(e: any): void {
    this.scrollPos = e.target.scrollLeft;

    if (e.target.scrollLeft + e.target.clientWidth >= e.target.scrollWidth) {
      this.setScrollRight(false);
    } else {
      this.setScrollRight(true);
    }

    if (-e.target.scrollLeft >= 0) {
      this.setScrollLeft(false);
    } else {
      this.setScrollLeft(true);
    }
  }

  scroll(event: Event, direction: number) {
    const button = event?.currentTarget as HTMLButtonElement;
    if (button) {
      button.blur();
    }
    if (direction > 0) {
      this.scrollbarRef?.scrollTo({
        left:
          this.scrollPos +
          this.tabNavigationBarRef.nativeElement.clientWidth * 0.15,
      });
    } else {
      this.scrollbarRef?.scrollTo({
        left:
          this.scrollPos -
          this.tabNavigationBarRef.nativeElement.clientWidth * 0.15,
      });
    }
  }

  updateHasScrolling(): void {
    if (!this.scrollbarRef) {
      return;
    }

    if (!this.tabNavigationBarRef) {
      return;
    }

    this.hasScrolling =
      this.tabNavigationBarRef.nativeElement.clientWidth >=
      this.scrollbarRef.nativeElement.scrollWidth;
  }

  setScrollLeft(newState: boolean) {
    this.enableScrollLeft = newState;
  }

  setScrollRight(newState: boolean) {
    this.enableScrollRight = newState;
  }

  activate(tabItem: TabNavigationItemComponent) {
    this.tabItems.forEach((i) => {
      i.isActive = false;
    });
    tabItem.isActive = true;

    if (this.tabGroupQueryName) {
      const tabName = this.tabGroupQueryName;
      const queryParams: Params = {};
      queryParams[tabName] = encodeURIComponent(tabItem.label);
      this.router.navigate([], {
        relativeTo: this.actRoute,
        replaceUrl: true,
        queryParams,
        queryParamsHandling: 'merge',
        preserveFragment: true,
      });
    }

    this.tabSelected.next(tabItem.label);
  }

  ngOnDestroy(): void {
    this.ro?.disconnect();
    this.scrollSubscription?.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
