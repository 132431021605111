import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlag {
  private localStorageKey = 'FeatureFlag';

  public shouldBeShown = new BehaviorSubject(false);

  public shouldBeShownOriginal = false;

  private override?: boolean = undefined;

  constructor(
    private settings: SettingsService,
    private storageService: StorageService
  ) {
    this.shouldBeShownOriginal = this.initShouldBeShown();
    this.override = this.loadOverride();
    if (this.override !== undefined) {
      this.setOverride(this.override);
    } else {
      this.shouldBeShown.next(this.shouldBeShownOriginal);
    }
  }

  public setOverride(show: boolean | undefined) {
    this.override = show;
    this.storageService.setItem(
      this.localStorageKey,
      show === true ? 'true' : 'false'
    );

    if (this.override !== undefined) {
      this.shouldBeShown.next(this.override);
    } else {
      this.shouldBeShown.next(this.shouldBeShownOriginal);
    }
  }

  private loadOverride(): boolean | undefined {
    const initValue = this.storageService.getItem(this.localStorageKey);
    if (initValue === 'true') {
      return true;
    } else if (initValue === 'false') {
      return false;
    }
    return undefined;
  }

  private initShouldBeShown() {
    if (document.baseURI === 'http://demo.local/') {
      return true;
    }

    if (document.baseURI === 'https://demo.local/') {
      return true;
    }

    if (document.baseURI === 'http://localhost:4200/') {
      return true;
    }

    if (document.baseURI === 'https://localhost:4200/') {
      return true;
    }

    if (document.baseURI === 'http://gerasdorf.nista.io/') {
      return true;
    }

    if (document.baseURI === 'https://gerasdorf.nista.io/') {
      return true;
    }

    if (this.settings.experimental) {
      return true;
    }

    return false;
  }
}
