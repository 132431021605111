import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable, switchMap, tap } from 'rxjs';
import { Guid } from '../guid';
import { WorkspaceService } from '../workspace.service';
import { OAuthAuthenticationService } from './oAuthAuthentication.service';
import { TrackingService } from '../tracking.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceRootGuard implements CanActivate {
  constructor(
    private workspaceService: WorkspaceService,
    private trackingService: TrackingService,
    private authService: OAuthAuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable<boolean>((subscriber) => {
      const toSet = next.paramMap.get('workspaceId') ?? undefined;

      if (!Guid.fromString(toSet ?? '').isValid()) {
        subscriber.next(false);
        return;
      }

      this.authService.isAuthenticated
        .pipe(
          switchMap(() => this.workspaceService.checkWorkspaceName(toSet)),
          switchMap(() => this.workspaceService.setWorkspaceId(toSet)),
          tap(() => this.trackingService.workspaceSelected(toSet))
        )
        .subscribe(subscriber);
    });
  }
}
