import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  distinct,
  distinctUntilChanged,
  filter,
  merge,
  takeUntil,
} from 'rxjs';
import { ErrorhandlerService } from './errorhandler.service';
import { OAuthAuthenticationService } from './OAuth/oAuthAuthentication.service';
import { SignalRConnection, SignalRService } from './signal-rservice.service';
import { WebsocketConnectionStateService } from './websocket-connection-state.service';

@Injectable({
  providedIn: 'root',
})
export class IdentityEventsService implements OnDestroy {
  private connection?: SignalRConnection;

  private notificationUpdatedSubject = new Subject<void>();
  get notificationUpdated(): Observable<void> {
    return this.notificationUpdatedSubject.asObservable();
  }

  private workspaceCreatedSubject = new Subject<WorkspaceCreatedEvent>();
  get workspaceCreated(): Observable<WorkspaceCreatedEvent> {
    return this.workspaceCreatedSubject.asObservable();
  }

  private userUpdatedSubject = new Subject<IdentityUserUpdateEvent>();
  get userUpdated(): Observable<IdentityUserUpdateEvent> {
    return this.userUpdatedSubject.asObservable();
  }

  private connectedSubject = new ReplaySubject<boolean>(1);

  private destroyed$ = new Subject<void>();

  constructor(
    private signalRService: SignalRService,
    private authenticationService: OAuthAuthenticationService,
    private errorHandler: ErrorhandlerService,
    private connectionState: WebsocketConnectionStateService
  ) {
    this.subscribeUpdateEvents();

    this.authenticationService
      .ensureLoggedIn()
      .pipe(
        takeUntil(this.destroyed$),
        filter((item) => item)
      )
      .subscribe({
        next: () => {
          this.connectedSubject
            .pipe(
              takeUntil(this.destroyed$),
              filter((item) => item)
            )
            .subscribe({
              next: () => {
                this.connection?.collect(
                  this.connection
                    .send(
                      'RegisterForIdentity',
                      this.authenticationService.email
                    )
                    .subscribe({ error: (err) => this.errorHandler.error(err) })
                );
              },
            });
        },
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.connection?.disconnect().subscribe();
  }

  dispose(): void {
    if (this.connection && this.connection.connectionId) {
      this.connectionState.remove(this.connection);
    }
    this.connection?.disconnect().subscribe();
  }

  subscribeUpdateEvents() {
    this.connection = this.signalRService.startConnection('/hubs/identity');

    this.connection.collect(
      this.connection.connect().subscribe({
        next: () => {
          if (!this.connection) {
            return;
          }

          this.connection.collect(
            this.connection
              .on('NotificationUpdated', (...data: any[]) => {})
              .subscribe({
                next: (received) => {
                  this.notificationUpdatedSubject.next();
                },
              })
          );

          this.connection.collect(
            this.connection
              .on<IdentityUserUpdateEvent>('UserUpdated', (...data: any[]) => ({
                givenName: data[0][0],
                familyName: data[0][1],
                phoneNumber: data[0][2],
                email: data[0][3],
              }))
              .subscribe(this.userUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<WorkspaceCreatedEvent>(
                'WorkspaceCreated',
                (...data: any[]) => ({
                  workspaceId: data[0][0],
                  workspaceName: data[0][1],
                })
              )
              .subscribe(this.workspaceCreatedSubject)
          );

          this.connectedSubject.next(true);
        },
        error: (err) => {
          this.errorHandler.error(err);
        },
      })
    );
  }
}

export interface WorkspaceCreatedEvent {
  workspaceId: string;
  workspaceName: string;
}

export interface IdentityUserUpdateEvent {
  givenName: string;
  familyName: string;
  phoneNumber: string;
  email: string;
}
