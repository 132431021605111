<div
  *ngIf="liveData"
  class="livedata-status-widget-root"
  style="display: flex; flex-flow: column"
  [ngClass]="{
    loading: (loading | async) === true
  }"
>
  <div>
    <span>{{ liveData.liveDataName }}</span>
    <a
      app-nista-button
      color="primary"
      size="small"
      [routerLink]="[
        '/workspace',
        workspaceId,
        'facility',
        facility?.facilityId,
        'dashboard',
        'datalibrary',
        'livedata',
        'eda',
        liveData.liveDataId
      ]"
      >Open '{{ liveData.liveDataName }}'</a
    >
  </div>
  <div *ngIf="dataPoint">
    <app-datapoint-inline-input
      [dataPointId]="dataPoint.dataPointId"
      [workspaceId]="workspaceId"
      [editAllowed]="false"
      [placeHolder]="liveData.edaLiveDataStatus?.dataType ?? ''"
    ></app-datapoint-inline-input>
    <div *ngIf="datapointStore">
      <app-nista-alert
        *ngIf="datapointStore.lastEntry"
        headline="Outdated"
        details="Did not receive data since {{
          datapointStore.lastEntry | dateAgo
        }}"
        [closeable]="false"
        [color]="'error'"
      ></app-nista-alert>
      <span>Last Entry: {{ datapointStore.lastEntry | date }}</span>
    </div>
  </div>
  <div *ngIf="!dataPoint">
    <app-nista-alert
      headline="No Datapoint linked!"
      details="Never received data or datapoitn was removed"
      [closeable]="false"
      [color]="'error'"
    ></app-nista-alert>
  </div>
</div>
