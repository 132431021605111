<div
  *ngIf="facility"
  class="facility-status-widget-root"
  style="display: flex; flex-flow: column"
  [ngClass]="{
    loading: (loading | async) === true
  }"
>
  <div style="display: flex; flex-flow: row">
    <span>Has Dashboard:</span>
    <app-gnista-icon
      [icon]="(this.reportsResponse?.length ?? 0) > 0 ? 'done' : 'warning'"
      [colorOverride]="
        (this.reportsResponse?.length ?? 0) > 0 ? 'green' : 'red'
      "
    ></app-gnista-icon>
  </div>
  <div style="display: flex; flex-flow: row; gap: 8px">
    <span>Livedata:</span>
    <div
      *ngFor="let liveData of liveDataStatusResponse"
      class="livedata-widget"
    >
      <app-eda-livedata-status-widget
        [workspaceId]="workspaceId"
        [facility]="facility"
        [liveData]="liveData"
        (doneLoadingEmitter)="updateLoadingMap(liveData.liveDataId, $event)"
        (errorEmitter)="errorEmitter.emit($event)"
      >
      </app-eda-livedata-status-widget>
    </div>
    <span *ngIf="liveDataStatusResponse?.length === 0">No Livedata found</span>
  </div>
</div>
