import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nista-expander',
  templateUrl: './nista-expander.component.html',
  styleUrls: ['./nista-expander.component.scss'],
})
export class NistaExpanderComponent {
  @Input()
  title?: string;

  @HostBinding('class.expanded')
  @Input()
  expanded = false;

  @HostBinding('class.expander-loading')
  @Input()
  loading? = false;

  @HostBinding('class.expander-error')
  @Input()
  error? = false;

  constructor() {}

  toggle() {
    this.expanded = !this.expanded;
  }
}
