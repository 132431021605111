import { Component } from '@angular/core';
import { WorkspaceService } from '../../workspace.service';

@Component({
  selector: 'app-workspace-select',
  templateUrl: './workspace-select.component.html',
  styleUrls: ['./workspace-select.component.scss'],
  providers: [WorkspaceService],
})
export class WorkspaceSelectComponent {
  constructor() {}
}
