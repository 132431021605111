import { OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { NGXLogger } from 'ngx-logger';
import { throwError } from 'rxjs';
import { OAuthAuthenticationService } from './oAuthAuthentication.service';

export class CustomErrorHandler extends OAuthResourceServerErrorHandler {

  constructor(private logger: NGXLogger, private authService: OAuthAuthenticationService) {
    super();
  }

  handleError(err: any) {
    this.logger.error('There has been an error response: ', err);

    return throwError(err);
  }
}
