import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Icon } from './common-components/gnista-icon/gnista-icon.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private toastr: ToastrService) {}

  public openSnackbar(details: SnackbarDetails) {
    let timeout;

    if (details.closeable === undefined) {
      details.closeable = false;
    }

    if (!details.closeable) {
      timeout = details.timeout ?? 4000;
    } else {
      timeout = details.timeout;
    }

    this.toastr.show('', '', {
      closeButton: false,
      disableTimeOut: timeout === undefined,
      timeOut: timeout,
      payload: details,
      positionClass: 'toast-bottom-center',
      tapToDismiss: false,
    });
  }

  public open(message: string) {
    this.openSnackbar({ text: message });
  }
}

export class SnackbarDetails {
  headline?: string;

  text?: string;

  action1?: string;

  action2?: string;

  closeable?: boolean = undefined;

  icon?: Icon;

  action1Clicked?: () => void;

  action2Clicked?: () => void;

  timeout?: number;
}
