import { debounce } from '@agentepsilon/decko';
import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subject, filter, take, takeUntil } from 'rxjs';
import { Colors } from 'src/app/colors';
import { ViewValue } from 'src/app/common-components/inline-input/inline-input-display/inline-input-display.component';
import { ViewValueSavedEvent } from 'src/app/common-components/inline-input/inline-input.component';
import {
  DataBucketBase,
  DataPointResponseBase,
} from 'src/app/datapoint-service-client';
import { DatapointService } from 'src/app/datapoint.service';
import { FacilityService } from 'src/app/facility.service';
import {
  SearchArtefactResponse,
  SearchArtefactResult,
  SearchClient,
} from 'src/app/search-service-client';
import { WorkspaceService } from 'src/app/workspace.service';

@Component({
  selector: 'app-datapoint-inline-input',
  templateUrl: './datapoint-inline-input.component.html',
  styleUrls: ['./datapoint-inline-input.component.scss'],
})
export class DataPointInlineInputComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  dataPointId?: string;

  @Input()
  workspaceId?: string;

  @Input()
  dataPointVersion?: number = undefined;

  dataPointCurrentVersion?: number;

  @Input()
  minimizeWidth = false;

  @Input()
  showIcon = true;

  viewValue?: ViewValue;

  datapointText?: string;

  dataPoint?: DataPointResponseBase;

  datapointColor = 'white';

  @Input()
  editAllowed = true;

  dataPointVersionMatch?: boolean;

  @Input()
  placeHolder = 'Select DataPoint';

  @Input()
  displaySaveState = true;

  @Output()
  datapointTyped: EventEmitter<string> = new EventEmitter();

  @Output()
  datapointChanged: EventEmitter<ViewValueSavedEvent> = new EventEmitter();

  autoCompleteDataPoints: KeyValue<string, SearchArtefactResult>[] | undefined;

  destroyed$ = new Subject<void>();

  constructor(
    private searchClient: SearchClient,
    private datapointService: DatapointService,
    private workspaceService: WorkspaceService,
    protected facilityService: FacilityService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataPointId) {
      this.dataPointId = changes.dataPointId.currentValue;
      this.datapointText = changes.dataPointId.currentValue;
      this.updateValue().pipe(takeUntil(this.destroyed$)).subscribe();
    }

    if (changes.workspaceId) {
      this.workspaceId = changes.workspaceId.currentValue;
      this.updateValue().pipe(takeUntil(this.destroyed$)).subscribe();
    }

    if (changes.dataPointVersion) {
      this.dataPointVersionMatch = this.doesDataPointVersionMatch();
    }
  }

  doesDataPointVersionMatch(): boolean | undefined {
    if (this.dataPointVersion === undefined) {
      return undefined;
    }
    return this.dataPointCurrentVersion === this.dataPointVersion;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateValue(): Observable<DataPointResponseBase> {
    return new Observable<DataPointResponseBase>((subscriber) => {
      if (!this.dataPointId) {
        this.datapointText = undefined;
        this.dataPoint = undefined;
        this.datapointColor = 'white';
        this.viewValue = { text: this.datapointText ?? '' };
        return;
      }

      if (!this.workspaceService.workspaceId && !this.workspaceId) {
        return;
      }

      this.datapointService
        .loadDatapoint(this.dataPointId, this.destroyed$, this.workspaceId)
        .pipe(
          takeUntil(this.destroyed$),
          filter((a) => !!a)
        )
        .subscribe({
          next: (dataPointResponse) => {
            this.dataPoint = dataPointResponse;
            this.datapointColor = Colors.fromQuantity(
              dataPointResponse.store?.gnistaUnit?.physicalQuantity
            );
            this.datapointText = dataPointResponse.name ?? '';
            this.viewValue = { text: this.datapointText, data: this.dataPoint };
            this.dataPointCurrentVersion = dataPointResponse.store?.version;
            this.dataPointVersionMatch = this.doesDataPointVersionMatch();
            subscriber.next(dataPointResponse);
          },
          error: (err) => subscriber.error(err),
          complete: () => subscriber.complete(),
        });
    }).pipe(take(1));
  }

  ngOnInit(): void {}

  updateDataPoint(newDataPoint: ViewValueSavedEvent) {
    if (newDataPoint.text) {
      this.dataPointId = newDataPoint.text;
      this.updateValue()
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (dataPoint) => {
            this.datapointChanged.emit({
              text: dataPoint.name ?? '',
              data: {
                id: dataPoint.dataPointId,
                version: dataPoint.store?.version,
                unit: dataPoint.store?.unit,
              },
              saveFinished: newDataPoint.saveFinished,
            });
          },
        });
    }
  }

  @debounce(500)
  datapointInputTyped(value: string) {
    if (!this.workspaceService.workspaceId && !this.workspaceId) {
      return;
    }

    this.searchClient
      .searchArtefact(
        this.workspaceId ?? this.workspaceService.workspaceId ?? '',
        value,
        this.facilityService.facilityId,
        10,
        ['DataPoint']
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (result) => {
          this.updateSearchArtefactResult(result);
        },
      });
  }

  @debounce(300)
  updateSearchArtefactResult(result: SearchArtefactResponse) {
    if (!result.artefacts) {
      this.autoCompleteDataPoints = [];
      return;
    }
    this.autoCompleteDataPoints = this.convertMapToObject(result.artefacts);
  }

  convertMapToObject(
    items: SearchArtefactResult[]
  ): KeyValue<string, SearchArtefactResult>[] | undefined {
    const newObject: KeyValue<string, SearchArtefactResult>[] = [];
    for (const item of items) {
      newObject.push({ key: item.id ?? '', value: item });
    }

    return newObject;
  }

  unit(record: SearchArtefactResult | DataBucketBase): string | undefined {
    if (record?.gnistaUnit) {
      return record.gnistaUnit.name;
    } else {
      return record?.unit;
    }
  }

  physicalQuantity(
    record: SearchArtefactResult | DataBucketBase
  ): string | undefined {
    if (record?.gnistaUnit) {
      return record.gnistaUnit.physicalQuantity;
    } else {
      return '';
    }
  }

  color(record: SearchArtefactResult | DataBucketBase): string | undefined {
    if (record?.gnistaUnit) {
      return Colors.fromQuantity(record.gnistaUnit?.physicalQuantity);
    } else {
      return 'white';
    }
  }

  updateVersion() {
    this.updateValue()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (dataPoint) => {
          this.datapointChanged.emit({
            text: dataPoint.name ?? '',
            data: {
              id: dataPoint.dataPointId,
              version: dataPoint.store?.version,
              unit: dataPoint.store?.unit,
            },
          });
        },
      });
  }
}
