<button class="tab-wrapper" (click)="click()" [ngClass]="{ active: isActive }">
  <span>{{ label }}</span>
  <div
    class="unreadIndicator"
    [ngClass]="{ small: unreadIndicator === undefined }"
    *ngIf="showUnreadIndicator || unreadIndicator"
  >
    <span>{{ unreadIndicator ?? '' }}</span>
  </div>
</button>
