export class Numbers {
  static parseFloat(value: string | undefined): number | undefined {
    if (!value) {
      return undefined;
    }

    value = value.trim();
    if (value === '') {
      return undefined;
    }

    if (!value) {
      return undefined;
    }

    const seperator = Numbers.getLocaleDecimalSeparator();

    let result = this.parseFloatCultural(value, seperator);
    if (Number.isNaN(result) || result === undefined) {
      result = this.parseFloatFallback(value);
    }
    return result;
  }

  static parseGermanFloat(value: string | undefined): number | undefined {
    if (!value) {
      return undefined;
    }

    value = value.trim();
    if (value === '') {
      return undefined;
    }

    if (!value) {
      return undefined;
    }

    let result = this.parseFloatCultural(value, ',');
    if (Number.isNaN(result) || result === undefined) {
      result = this.parseFloatFallback(value);
    }
    return result;
  }

  private static combineAndParse(leftPart: string, rightPart: string): number {
    leftPart = leftPart.replace(/[^0-9]/g, '');
    rightPart = rightPart.replace(/[^0-9]/g, '');

    const stringToParse = leftPart + '.' + rightPart;
    return parseFloat(stringToParse);
  }

  private static parseFloatFallback(value: string) {
    const lastIndex = value.length;
    const commaIndex = value.lastIndexOf(',');
    const dotIndex = value.lastIndexOf('.');
    let searchIndex = lastIndex;

    if (commaIndex !== -1 || dotIndex !== -1) {
      if (commaIndex > dotIndex) {
        searchIndex = commaIndex;
      } else if (dotIndex > commaIndex) {
        searchIndex = dotIndex;
      }
    }

    const leftPart = value.slice(0, searchIndex);
    const rightPart = value.slice(searchIndex + 1, lastIndex);
    return Numbers.combineAndParse(leftPart, rightPart);
  }

  private static parseFloatCultural(value: string, seperator: string) {
    const lastIndex = value.length;
    const commaIndex = value.lastIndexOf(seperator);
    let searchIndex = lastIndex;

    if (commaIndex !== -1) {
      searchIndex = commaIndex;
    }

    const leftPart = value.slice(0, searchIndex);
    const rightPart = value.slice(searchIndex + 1, lastIndex);

    return Numbers.combineAndParse(leftPart, rightPart);
  }

  public static getLocaleDecimalSeparator(): string {
    const n = 1.1;
    return n.toLocaleString().substring(1, 2);
  }

  public static getStringForNumber(
    value: number,
    maximumFractionDigits = 2
  ): string {
    return value.toLocaleString('de-de', {
      maximumFractionDigits,
    });
  }
}
