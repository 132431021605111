import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  filter,
  map,
  merge,
  retry,
  take,
  takeUntil,
  timer,
} from 'rxjs';
import {
  CreateFacilityRequest,
  FacilityClient,
  FacilityResponse,
  IFacilityResponse,
} from './facility-service-client';
import { Guid } from './guid';
import { StorageService } from './storage.service';
import { WorkspaceEventsService } from './workspace-events.service';
import { WorkspaceService } from './workspace.service';

@Injectable({
  providedIn: 'root',
})
export class FacilityService implements OnDestroy {
  public static defaultSections = [
    {
      value: 'production',
      germanName: 'Produktion',
    },
    {
      value: 'energySupply',
      germanName: 'Energieversorger',
    },
    {
      value: 'construction',
      germanName: 'Bau',
    },
    {
      value: 'retail',
      germanName: 'Handel',
    },
    {
      value: 'hospitality',
      germanName: 'Hotelerie',
    },
    {
      value: 'home',
      germanName: 'Haushalt',
    },
    {
      value: 'other',
      germanName: 'Andere',
    },
  ];

  facilities: IFacilityResponse[] = [];

  private readonly storageKey = 'lastFacilityId';

  private userFacilityListSubject = new ReplaySubject<IFacilityResponse[]>(1);

  private facilityNameSubject = new ReplaySubject<string | undefined>(1);

  get facilityName(): Observable<string | undefined> {
    return this.facilityNameSubject.asObservable();
  }

  public get facilityIdChanged(): Observable<string | undefined> {
    return this.facilityIdSubject.asObservable();
  }

  destroyed$ = new Subject<void>();

  facilityIdSubject = new BehaviorSubject<string | undefined>(undefined);

  energyPriceSubject = new BehaviorSubject<number>(0);

  sectionSubject = new BehaviorSubject<string | undefined>(undefined);

  public get facilityId(): string | undefined {
    return this.facilityIdSubject.value;
  }

  public get energyPrice(): Observable<number> {
    return this.energyPriceSubject.asObservable();
  }

  public get section(): string | undefined {
    return this.sectionSubject.value;
  }

  constructor(
    private facilityClient: FacilityClient,
    private router: Router,
    private workspaceService: WorkspaceService,
    private workspaceEventService: WorkspaceEventsService,
    private storageService: StorageService,
    private titleService: Title
  ) {
    combineLatest([
      this.facilityNameSubject,
      this.workspaceService.workspaceName,
    ])
      .pipe(
        map(
          ([facilityName, workspaceName]) =>
            (facilityName ?? 'Global') + ' - ' + workspaceName + ' - nista.io'
        )
      )
      .subscribe({
        next: (title) => {
          this.titleService.setTitle(title);
        },
      });

    merge(
      this.workspaceEventService.facilityCreated,
      this.workspaceEventService.workspaceDeleted,
      this.workspaceEventService.workspaceMarkedForDelete,
      this.workspaceService.workspaceIdChanged
    )
      .pipe(
        takeUntil(this.destroyed$),
        filter((a) => !!a)
      )
      .subscribe({
        next: (_) => {
          if (!this.workspaceService.workspaceId) {
            return;
          }
          this.facilityClient
            .list(this.workspaceService.workspaceId)
            .subscribe({
              next: (facilities) => {
                this.facilities = facilities;
                this.userFacilityListSubject.next(facilities);
              },
            });
        },
      });

    this.sectionsSubject.next(FacilityService.defaultSections);
  }

  facilitySelected(facility?: FacilityResponse) {
    this.facilityIdSubject.next(facility?.facilityId);
    this.energyPriceSubject.next(facility?.energyPrice ?? 0);
    this.facilityNameSubject.next(facility?.facilityName);
    this.sectionSubject.next(facility?.section);
  }

  selectFacility(facility?: string, navigate: boolean = false) {
    const workspaceId = this.workspaceService.workspaceId;
    if (!workspaceId) {
      return;
    }

    this.facilityIdSubject.next(facility);
    this.setLastFacilityId(facility);

    if (!facility) {
      this.facilitySelected(undefined);
      if (navigate) {
        this.navigateToDashboard();
      }
      return;
    }

    this.facilityClient
      .get(workspaceId, facility)
      .pipe(
        takeUntil(this.destroyed$),
        retry({ count: 10, delay: (err, attempt) => timer(1000 * attempt) })
      )
      .subscribe({
        next: (facilityResult) => {
          this.facilitySelected(facilityResult);
          if (navigate) {
            this.navigateToDashboard();
          }
        },
      });
  }

  setLastFacilityId(value?: string) {
    this.storageService.setItem(this.storageKey, value ?? 'global');
  }

  navigateToDashboard() {
    const original = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.events.pipe(take(1)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
      this.router.routeReuseStrategy.shouldReuseRoute = original;
    });

    this.router.navigate(this.createAbsoluteUrl(['dashboard', 'dashboard']));
  }

  public get facilityUrl(): (string | undefined)[] {
    if (!this.workspaceService.workspaceId) {
      return ['/'];
    }

    if (!this.facilityId) {
      return ['/', 'workspace', this.workspaceService.workspaceId, 'global'];
    }

    return [
      '/',
      'workspace',
      this.workspaceService.workspaceId,
      'facility',
      this.facilityId,
    ];
  }

  public createAbsoluteUrl(parts: (string | undefined)[]) {
    return this.facilityUrl.concat(parts);
  }

  createFacility(
    name: string,
    workspaceId?: string
  ): Observable<FacilityResponse | null> {
    return new Observable<FacilityResponse | null>((subscriber) => {
      if (!name) {
        subscriber.next(null);
        return;
      }

      if (!workspaceId) {
        subscriber.next(null);
        return;
      }

      const request = new CreateFacilityRequest({
        facilityName: name,
      });

      const newFacilityId = Guid.newGuid().toString();

      this.workspaceEventService.facilityCreated
        .pipe(
          filter((item) => item === newFacilityId),
          take(1)
        )
        .subscribe({
          next: () => {
            this.facilityClient
              .get(workspaceId, newFacilityId)
              .pipe(
                retry({
                  count: 5,
                  delay: (err, attempt) => timer(1000 * attempt),
                })
              )
              .subscribe(subscriber);
          },
        });

      this.facilityClient
        .createFacility(newFacilityId, workspaceId, request)
        .subscribe();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get facilityList(): Observable<IFacilityResponse[]> {
    return this.userFacilityListSubject.asObservable();
  }

  public get sortedFacilityList(): Observable<IFacilityResponse[]> {
    return this.userFacilityListSubject.pipe(
      map((item) => item.sort(this.sortFacility))
    );
  }

  private sortFacility(a: IFacilityResponse, b: IFacilityResponse): number {
    if (!a || !a.facilityName) {
      return -1;
    }
    if (!b || !b.facilityName) {
      return -1;
    }

    if (
      a.facilityName.toLocaleLowerCase() < b.facilityName.toLocaleLowerCase()
    ) {
      return -1;
    }
    if (
      a.facilityName.toLocaleLowerCase() > b.facilityName.toLocaleLowerCase()
    ) {
      return 1;
    }

    return 0;
  }

  private sectionsSubject = new ReplaySubject<ISection[]>(1);

  public getSections(): Observable<ISection[]> {
    return this.sectionsSubject.asObservable();
  }
}

export interface ISection {
  value: string;
  germanName: string;
}
