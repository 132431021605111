<div
  class="snackbar-root"
  [ngClass]="{
    'snackbar-closeable': closeable,
    'snackbar-non-closeable': !closeable,
    'snackbar-with-icon': icon,
    'snackbar-noaction': type === 'noaction',
    'snackbar-oneaction': type === 'oneaction',
    'snackbar-twoaction': type === 'twoaction'
  }"
>
  <div class="first-row">
    <app-gnista-icon
      [icon]="icon"
      *ngIf="icon && type !== 'twoaction'"
      class="icon"
      iconSize="medium"
    ></app-gnista-icon>
    <div class="text-container">
      <span class="headline" *ngIf="headline">{{ headline }}</span>
      <span class="text">
        {{ text }}
      </span>
    </div>
    <div
      class="action-container-right"
      *ngIf="type === 'oneaction' || (type === 'twoaction' && !closeable)"
    >
      <div
        *ngIf="action1"
        class="action action-button-1"
        (click)="action1Clicked.next(undefined)"
      >
        <span>
          {{ action1 }}
        </span>
      </div>
      <div
        *ngIf="action2"
        class="action action-button-2"
        (click)="action2Clicked.next(undefined)"
      >
        <span>
          {{ action2 }}
        </span>
      </div>
    </div>
    <app-gnista-icon
      icon="close"
      *ngIf="closeable"
      class="closeicon"
      (click)="closeClicked.next(undefined)"
      iconSize="medium"
    ></app-gnista-icon>
  </div>
  <div
    class="action-container-bottom"
    *ngIf="type === 'twoaction' && closeable"
  >
    <div
      *ngIf="action1"
      class="action action-button-1"
      (click)="action1Clicked.next(undefined)"
    >
      <span>
        {{ action1 }}
      </span>
    </div>
    <div
      *ngIf="action2"
      class="action action-button-2"
      (click)="action2Clicked.next(undefined)"
    >
      <span>
        {{ action2 }}
      </span>
    </div>
  </div>
</div>
