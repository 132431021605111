import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Colors } from 'src/app/colors';
import { IUserDetailsResponse } from 'src/app/identity-server-client';
import { isRequired } from 'src/app/is-required.decorator';

@Component({
  selector: 'app-user-badge-display',
  templateUrl: './user-badge-display.component.html',
  styleUrls: ['./user-badge-display.component.scss'],
})
export class UserBadgeDisplayComponent implements OnInit, OnChanges {
  @Input()
  userDetails?: IUserDetailsResponse | null;

  email?: string;

  fullname?: string;

  initials?: string;

  isAI = false;

  @Input()
  @isRequired()
  size = 36;

  @Input()
  showTooltip?: boolean = true;

  @Input()
  active = false;

  @Input()
  inviteOnly = false;

  tooltip!: string;

  textColor?: string;

  colorCode?: string;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fullname) {
      this.fullname = changes.fullname.currentValue;
      this.tooltip = this.calcTooltip() ?? '';
    }

    if (changes.initials) {
      this.initials = changes.initials.currentValue;
    }

    if (changes.userDetails) {
      this.userDetails = changes.userDetails.currentValue;

      if (!this.userDetails) {
        return;
      }

      this.tooltip = this.calcTooltip() ?? '';
      this.email = this.userDetails.email;
      this.isAI = this.email?.toLocaleUpperCase() === 'AI@NISTA.IO';
      this.updateUserColor();
      this.initials = this.getInitials();
      this.fullname = this.getFullName();
    }
    if (changes.active) {
      this.updateUserColor();
    }
  }

  private updateUserColor() {
    this.colorCode = this.active
      ? Colors.getUserColor(this.email)
      : 'rgba(255,255,255,0.2)';
    this.textColor = this.active
      ? this.idealTextColor(this.colorCode)
      : 'rgba(151, 151, 151, 1)';
  }

  ngOnInit(): void {}

  calcTooltip(): string | undefined {
    if (!this.showTooltip) {
      return '';
    }
    if (this.fullname) {
      return this.fullname;
    }

    return this.email?.toLocaleLowerCase();
  }

  idealTextColor(bgColor: string) {
    const nThreshold = 105;
    const components = this.getRGBComponents(bgColor);
    const bgDelta =
      components.r * 0.299 + components.g * 0.587 + components.b * 0.114;

    return 255 - bgDelta < nThreshold ? '#000000' : '#ffffff';
  }

  private getRGBComponents(color: string) {
    const r = color.substring(1, 3);
    const g = color.substring(3, 5);
    const b = color.substring(5, 7);

    return {
      r: parseInt(r, 16),
      g: parseInt(g, 16),
      b: parseInt(b, 16),
    };
  }

  getFullName(): string {
    if (!this.userDetails) {
      return '';
    }

    let name = '';
    if (this.userDetails.givenName && this.userDetails.givenName.length > 0) {
      name = this.userDetails.givenName;
    }

    if (this.userDetails.familyName && this.userDetails.familyName.length > 0) {
      if (name?.length > 0) {
        name += ' ';
      }
      name += this.userDetails.familyName;
    }

    return name;
  }

  getInitials(): string | undefined {
    if (!this.userDetails) {
      return undefined;
    }

    let initials = '';
    if (this.userDetails.givenName && this.userDetails.givenName.length > 0) {
      initials += this.userDetails.givenName.slice(0, 1);
    }

    if (this.userDetails.familyName && this.userDetails.familyName.length > 0) {
      if (initials.length === 0) {
        initials = this.userDetails.familyName.slice(0, 2);
      } else {
        initials += this.userDetails.familyName.slice(0, 1);
      }
    }

    if (initials.length === 2) {
      return initials.toUpperCase();
    }

    return undefined;
  }
}
