import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mousedown-stop-propagation]'
})
export class MouseDownpPropagationDirective {

  @HostListener('mousedown', ['$event'])
  public onMouseDown22(event: Event): void {
    event.stopPropagation();
  }
}
