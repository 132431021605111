import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Subject, map, take, takeUntil } from 'rxjs';
import { FacilityService } from 'src/app/facility.service';
import { WorkspaceService } from 'src/app/workspace.service';

@Component({
  selector: 'app-facility-root',
  templateUrl: './facility-root.component.html',
  styleUrls: ['./facility-root.component.scss'],
})
export class FacilityRootComponent {
  constructor() {}
}
