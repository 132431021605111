<div
  class="nista-alert-root"
  [class.hidden]="!show"
  [class.error]="color === 'error'"
  [class.info]="color === 'info'"
  [class.warn]="color === 'warning'"
  [class.success]="color === 'success'"
>
  <div class="icon-col">
    <app-gnista-icon [icon]="icon"></app-gnista-icon>
  </div>
  <div class="content-col">
    <div class="headline">{{ headline }}</div>
    <div class="details" *ngIf="details">{{ details }}</div>
    <div class="actions" *ngIf="action1 || action2">
      <div class="action1" (click)="action1Requested.next()">{{ action1 }}</div>
      <div class="action2" (click)="action2Requested.next()">{{ action2 }}</div>
    </div>
  </div>
  <div class="close-col">
    <app-gnista-icon
      class="close-icon"
      *ngIf="closeable"
      icon="close"
      (click)="closeRequested.next()"
    ></app-gnista-icon>
  </div>
</div>
