<div class="workspace-wizard-root">
  <div class="banner">
    <a href="https://nista.io" rel="noopener">
      <img src="assets/wordmark.svg" />
    </a>
  </div>
  <div class="container narrow">
    <div class="content">
      <div class="heading">Unternehmensprofil anlegen</div>
      <div class="sub-heading">Jetzt nista.io Profil erstellen</div>
      <div class="input-boxes">
        <app-nista-input
          data-testid="companyNameInput"
          [autoFocus]="true"
          title="Name des Unternehmens"
          [displayValue]="companyName"
          [valid]="companyNameValid"
          (typed)="setCompanyName($event)"
        >
        </app-nista-input>
        <div class="actions">
          <button
            app-nista-button
            color="primary"
            size="large"
            [disabled]="(allValid | async) === false"
            (click)="updateWorkspace()"
            *ngIf="(sending | async) === false"
          >
            Profil anlegen
          </button>
          <div *ngIf="sending | async">Wird angelegt, bitte warten</div>
        </div>
      </div>
    </div>
  </div>
</div>
