import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { Size, Time } from '../time-picker.component';

@Component({
  selector: 'app-time-picker-dropdown',
  templateUrl: './time-picker-dropdown.component.html',
  styleUrls: ['./time-picker-dropdown.component.scss'],
})
export class TimePickerDropDownComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input()
  public size: Size = 'medium';

  @Input()
  selectedHour: number | undefined;

  @Input()
  selectedMinute: number | undefined;

  @Output()
  selected: EventEmitter<Time> = new EventEmitter();

  typedInvalidCharacter = false;

  @ViewChild('hourscroll', { static: true })
  scrollhours!: NgScrollbar;

  @ViewChild('minutescroll', { static: true })
  scrollminutes!: NgScrollbar;

  constructor() {}

  ngAfterViewInit(): void {
    this.scrollIt();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedHour) {
      this.selectedHour = changes.selectedHour.currentValue;
    }

    if (changes.selectedMinute) {
      this.selectedMinute = changes.selectedMinute.currentValue;
    }
  }

  ngOnInit(): void {}

  scrollIt(): void {
    let selected = this.scrollhours.nativeElement.getElementsByClassName(
      'selected'
    )[0] as HTMLElement;
    selected?.scrollIntoView();

    selected = this.scrollminutes.nativeElement.getElementsByClassName(
      'selected'
    )[0] as HTMLElement;
    selected?.scrollIntoView();
  }

  get availableHours(): number[] {
    return [...Array(24).keys()];
  }

  get availableMinutes(): number[] {
    return [...Array(60).keys()];
  }

  selectHour(selection: number) {
    this.selectedHour = selection;
    this.selected.next({
      hour: this.selectedHour,
      minute: this.selectedMinute,
    });
  }

  selectMinute(selection: number) {
    this.selectedMinute = selection;
    this.selected.next({
      hour: this.selectedHour,
      minute: this.selectedMinute,
    });
  }
}
