import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FeedbackClient,
  FeedbackRequest,
} from 'src/app/identity-server-client';
import { TrackingService } from 'src/app/tracking.service';
import { SnackbarService } from './../../snackbar.service';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    public dialog: MatDialog,
    private feedbackClient: FeedbackClient,
    private snackbarService: SnackbarService,
    private trackingService: TrackingService
  ) {}

  giveFeedback(details?: string) {
    this.openDialog(details);
  }

  openDialog(datails?: string) {
    if (
      document.body.contains(document.getElementsByClassName('feedbackText')[0])
    ) {
      return;
    }
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      width: '50%',
      data: datails,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result instanceof FeedbackRequest) {
          this.trackingService.trackUserFeedback(result.rating?.toString());
          this.feedbackClient.send(result).subscribe({
            next: () => {
              this.snackbarService.openSnackbar({
                text: 'Your Feedback has been submitted',
              });
            },
            error: (err) => {
              this.snackbarService.openSnackbar({
                text: 'We were having difficulties receiving your Feedback',
              });
            },
          });
        }
      },
    });
  }
}
