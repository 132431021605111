import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import _ from 'lodash';
import { Colors, PHYSICAL_QUANTITY, PhysicalQuantity } from 'src/app/colors';
import { Icon } from 'src/app/common-components/gnista-icon/gnista-icon.component';

@Component({
  selector: 'app-unit-icon',
  templateUrl: './unit-icon.component.html',
  styleUrls: ['./unit-icon.component.scss'],
})
export class UnitIconComponent implements OnInit, OnChanges {
  @Input()
  physicalQuantity?: string;

  icon?: Icon;

  color?: string;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.physicalQuantity) {
      this.physicalQuantity = changes.physicalQuantity.currentValue;
      this.icon = this.detechtIcon(this.physicalQuantity);
      this.color = Colors.fromQuantity(this.physicalQuantity);
    }
  }

  ngOnInit(): void {}

  detechtIcon(
    quantity: string | PhysicalQuantity | undefined
  ): Icon | undefined {
    if (!quantity) {
      return undefined;
    }

    if (!Object.values(PHYSICAL_QUANTITY).includes(quantity)) {
      return undefined;
    }

    const index = Object.values(PHYSICAL_QUANTITY).indexOf(quantity);
    const physicalQuantity = Object.keys(PHYSICAL_QUANTITY)[
      index
    ] as PhysicalQuantity;
    return this.detechtIconQuantity(physicalQuantity);
  }

  detechtIconQuantity(
    physicalQuantity: PhysicalQuantity | undefined
  ): Icon | undefined {
    if (!physicalQuantity) {
      return undefined;
    }

    switch (physicalQuantity) {
      case 'energy':
        return 'energy';
      case 'power':
        return 'power';
      case 'thermodynamicTemperature':
        return 'temperature';
      case 'mass':
        return 'mass';
      case 'area':
        return 'area';
      case 'money':
        return 'currency';
      case 'length':
        return 'distance';
      case 'force':
        return 'force';
      case 'flowRate':
        return 'volumeFlowRate';
      case 'massFlowRate':
        return 'massFlowRate';
      case 'ratio':
        return 'ratio';
      case 'time':
        return 'time';
      case 'volume':
        return 'volume';
      default:
        return undefined;
    }
  }
}
