<div class="icon-container" [matTooltip]="matTooltip" [ngClass]="iconClass">
  <i
    [ngClass]="{
      'icon-small': iconSize === 'small',
      'icon-medium': iconSize === 'medium',
      'icon-large': iconSize === 'large',
      'icon-xlarge': iconSize === 'xlarge',
      'inverted-icon': inverted === true,
      icon: true
    }"
    *ngIf="icon"
    [style.-webkit-mask-image]="cleanImageSrc"
    [style.mask-image]="cleanImageSrc"
    [style.background-color]="colorOverride"
  ></i>
</div>
