import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { FacilityService } from '../facility.service';
import { LoadingService } from '../loading.service';
import { StorageService } from '../storage.service';
import { WorkspaceService } from '../workspace.service';
import { FacilityClient } from '../facility-service-client';

@Injectable({
  providedIn: 'root',
})
export class FacilityNavigatorGuard implements CanActivate, OnDestroy {
  constructor(
    private facilityService: FacilityService,
    private workspaceService: WorkspaceService,
    private router: Router,
    private storageService: StorageService,
    private loadingService: LoadingService,
    private facilityClient: FacilityClient
  ) {}

  private destroyed$ = new Subject<void>();

  private readonly storageKey = 'lastFacilityId';

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.loadingService.setLoading(true);
    return new Observable<boolean>((subscriber) => {
      const facilityId = next.paramMap.get('facilityId') ?? undefined;
      if (!this.workspaceService.workspaceId) {
        subscriber.next(false);
        return;
      }

      if (!facilityId) {
        this.facilityClient.list(this.workspaceService.workspaceId).subscribe({
          next: (list) => {
            const storageFacility = this.getFacilityIdFromStorage();
            if (list.length === 0 || storageFacility === 'global') {
              this.router.navigate([
                '/',
                'workspace',
                this.workspaceService.workspaceId,
                'global',
                'dashboard',
              ]);
              return;
            }

            let randomFacility = list[0].facilityId;
            if (
              storageFacility &&
              list.find((f) => f.facilityId === storageFacility)
            ) {
              randomFacility = storageFacility;
            }

            this.router.navigate([
              '/',
              'workspace',
              this.workspaceService.workspaceId,
              'facility',
              randomFacility,
            ]);
          },
        });
        subscriber.next(false);
        return;
      }

      this.facilityService.selectFacility(facilityId, true);
      subscriber.next(false);
    });
  }

  getFacilityIdFromStorage(): string | null {
    return this.storageService.getItem(this.storageKey);
  }
}
