import { Location } from '@angular/common';
import { Injectable, TemplateRef } from '@angular/core';
import {
  ActivatedRoute,
  ActivationStart,
  Data,
  NavigationEnd,
  Router,
} from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { WorkspaceService } from 'src/app/workspace.service';
import { ArtefactType } from './common-components/share-button/share-dialog/share-dialog.component';
import { NotificationService } from './notification.service';
import { FacilityService } from './facility.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];

  private separator = ';;';
  private storageKey = 'gnistahistory';

  private upUrl: (string | undefined)[] = [];

  showCommentsSubject = new BehaviorSubject<boolean>(false);

  get showComments(): Observable<boolean> {
    return this.showCommentsSubject.asObservable();
  }

  shareArtefactSubject = new ReplaySubject<ShareArtefact | undefined>(1);
  get shareArtefact(): Observable<ShareArtefact | undefined> {
    return this.shareArtefactSubject.asObservable();
  }

  setShareArtefact(value: ShareArtefact | undefined) {
    this.shareArtefactSubject.next(value);
  }

  workTogetherSubject = new ReplaySubject<WorkTogetherUser[]>(1);
  get workTogether(): Observable<WorkTogetherUser[]> {
    return this.workTogetherSubject.asObservable();
  }

  setWorkTogehter(value: WorkTogetherUser[]) {
    this.workTogetherSubject.next(value);
  }

  componentButtonsTemplateSubject = new ReplaySubject<TemplateRef<any> | null>(
    1
  );
  get componentButtonsTemplate(): Observable<TemplateRef<any> | null> {
    return this.componentButtonsTemplateSubject.asObservable();
  }

  enableBackSubject = new BehaviorSubject(false);
  get enableBack(): Observable<boolean> {
    return this.enableBackSubject.asObservable();
  }

  backUrl?: string[];

  enableCommentsSubject = new BehaviorSubject(false);
  get enableComments(): Observable<boolean> {
    return this.enableCommentsSubject.asObservable();
  }

  subNavigationSubject = new ReplaySubject<SubNavigationEntry[]>(1);
  get subNavigation(): Observable<SubNavigationEntry[]> {
    return this.subNavigationSubject.asObservable();
  }

  setSubNavigation(values: SubNavigationEntry[]) {
    this.subNavigationSubject.next(values);
  }

  setEnableBack(value: boolean) {
    this.enableBackSubject.next(value);
  }

  setEnableComments(value: boolean) {
    if (!value) {
      this.showCommentsSubject.next(value);
    }

    this.enableCommentsSubject.next(value);
  }

  setComponentButtonsTemplate(value: TemplateRef<any> | null) {
    this.componentButtonsTemplateSubject.next(value);
  }

  setShowComments(value: boolean) {
    this.showCommentsSubject.next(value);
  }

  constructor(
    private router: Router,
    private location: Location,
    private activaedRoute: ActivatedRoute,
    private workspaceService: WorkspaceService,
    private notificationService: NotificationService,
    private facilityService: FacilityService
  ) {
    const historyAsString = sessionStorage.getItem(this.storageKey);
    if (historyAsString) {
      this.history = historyAsString.split(this.separator);
    }

    this.activaedRoute.queryParams.subscribe({
      next: (item) => {
        if (item?.notificationId) {
          this.notificationService.markRead(item.notificationId);
          this.router.navigate([], {
            relativeTo: this.activaedRoute,
            queryParams: {
              notificationId: null,
            },
            replaceUrl: true,
            queryParamsHandling: 'merge',
            preserveFragment: true,
          });
        }
      },
    });

    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof ActivationStart) {
          this.setEnableBack(false);
          this.setComponentButtonsTemplate(null);
          this.setShareArtefact(undefined);
          this.setWorkTogehter([]);
          this.setEnableComments(false);
        }
        if (event instanceof NavigationEnd) {
          const fullRoute = this.createFullRoute(this.activaedRoute.root);
          fullRoute.splice(fullRoute.length - 1, 1);
          this.upUrl = fullRoute;

          this.history.push(event.urlAfterRedirects);
          if (this.history.length > 50) {
            this.history.splice(0, 10);
          }
          sessionStorage.setItem(
            this.storageKey,
            this.history.join(this.separator)
          );
        }
      },
    });
  }

  public setFromData(data: Data, up?: (string | undefined)[]) {
    const navigation = data as NavigationData;
    this.upUrl = up ?? [];

    if (!navigation) {
      return;
    }

    if (navigation.back?.enabled) {
      if (navigation.back.url !== undefined) {
        const backUrl = navigation.back.url
          .map((item) =>
            item === ':workspaceId'
              ? this.workspaceService.workspaceId ?? ''
              : item
          )
          .map((item) =>
            item === ':facilityId'
              ? this.facilityService.facilityId ?? ''
              : item
          );
        this.backUrl = backUrl;
      } else {
        this.backUrl = undefined;
      }

      this.setEnableBack(navigation.back?.enabled);
    }
    if (navigation.comments?.enabled) {
      this.setEnableComments(navigation.comments?.enabled);
    }
  }

  private createFullRoute(route: ActivatedRoute, url: string[] = []): string[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return url;
    }

    for (const child of children) {
      const routeURL: string[] = child.snapshot.url.map(
        (segment) => segment.path
      );
      url.push(...routeURL);

      return this.createFullRoute(child, url);
    }
    return url;
  }

  up() {
    if (this.backUrl) {
      this.router.navigate(this.backUrl);
      return;
    }

    this.router.navigate(this.upUrl);
  }

  back(): void {
    if (this.backUrl) {
      this.router.navigate(this.backUrl);
      return;
    }

    if (this.history.length > 0) {
      this.history.pop();
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  openInNewWindow(route: (string | undefined)[]) {
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(this.router.createUrlTree(route));

    window.open(url, '_blank', 'noopener');
  }
}

export interface NavigationData {
  subItems: SubNavigationEntry[] | undefined;
  back: {
    enabled: boolean;
    url: string[];
  };
  comments: {
    enabled: boolean;
  };
}

export interface SubNavigationEntry {
  name: string;
  url: string[];
  enabled?: Observable<boolean>;
}

export interface WorkTogetherUser {
  userName: string;
  colorCode: string;
}

export interface ShareArtefact {
  artefactId: string;
  artefactType: ArtefactType;
}
