/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { OAuthLogger, OAuthModule, OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { NGXLogger } from 'ngx-logger';
import { StorageService } from '../storage.service';
import { CustomOAuthLogger } from './custom-oAuthLogger';
import { CustomErrorHandler } from './customErrorHandler';
import { JwtInterceptor } from './jwt-intercepter';
import { OAuthAuthenticationService } from './oAuthAuthentication.service';

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        StorageService,
        { provide: OAuthStorage, useClass: StorageService },
        OAuthAuthenticationService,
        { provide: OAuthResourceServerErrorHandler, useClass: CustomErrorHandler, deps: [NGXLogger] },
        { provide: OAuthLogger, useClass: CustomOAuthLogger, deps: [NGXLogger] },
        JwtInterceptor
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
