import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, ReplaySubject, Subject, merge, timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, retry, switchMap, take, takeUntil } from 'rxjs/operators';
import { Guid } from './guid';
import { OAuthAuthenticationService } from './OAuth/oAuthAuthentication.service';
import { StorageService } from './storage.service';
import { UserDetailsService } from './user-details.service';
import { WorkspaceEventsService } from './workspace-events.service';
import {
  EnWorkspaceTierDTO,
  PermissionsClient,
  UpdateTierRequest,
  UserPermissionsRequest,
  WorkspaceClient,
  WorkspaceRequest,
  WorkspaceResponseBase,
  WorkspaceUsersResponse,
} from './workspace-service-client';
import { Title } from '@angular/platform-browser';
import { IdentityEventsService } from './identity-events.service';

export class Claim {
  name: string;

  private loaded = new BehaviorSubject<boolean>(false);

  constructor(name: string) {
    this.name = name;
  }

  private subject = new BehaviorSubject<boolean>(false);

  public get observable(): Observable<boolean> {
    return this.loaded.pipe(
      filter((item) => item),
      take(1),
      switchMap((item) => this.subject.asObservable())
    );
  }

  public get current(): boolean {
    return this.subject.getValue();
  }

  public set(newValue: boolean) {
    this.subject.next(newValue);
    this.loaded.next(true);
  }
}

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService implements OnDestroy {
  private userWorkspaceListSubject = new ReplaySubject<WorkspaceResponseBase[]>(
    1
  );

  private workspaceTierSubject = new BehaviorSubject<
    EnWorkspaceTierDTO | undefined
  >(undefined);

  private workspaceNameSubject = new BehaviorSubject<string | undefined>(
    undefined
  );

  get workspaceName(): Observable<string | undefined> {
    return this.workspaceNameSubject.asObservable();
  }

  get workspaceTier(): Observable<EnWorkspaceTierDTO | undefined> {
    return this.workspaceTierSubject.asObservable();
  }

  get workspaceUserList(): Observable<WorkspaceUsersResponse | undefined> {
    return this.workspaceUserListSubject.asObservable();
  }

  private workspaceUserListSubject = new BehaviorSubject<
    WorkspaceUsersResponse | undefined
  >(undefined);

  public claims: Claims = new Claims();

  private claimsSubject = new BehaviorSubject<Claims | undefined>(undefined);

  get claimsObservable(): Observable<Claims | undefined> {
    return this.claimsSubject.asObservable();
  }

  private destroyed$ = new Subject<void>();

  private readonly storageKey = 'lastWorkspaceId';

  constructor(
    private workspaceEventService: WorkspaceEventsService,
    private workspaceClient: WorkspaceClient,
    private permissionClient: PermissionsClient,
    private authService: OAuthAuthenticationService,
    private userDetailsService: UserDetailsService,
    private router: Router,
    private storageService: StorageService,
    private logger: NGXLogger,
    private titleService: Title,
    private identityEventService: IdentityEventsService
  ) {
    this.authService.isAuthenticated
      .pipe(
        filter((item) => item),
        take(1)
      )
      .subscribe({
        next: (result) => {
          this.updateWorkspaceList();

          this.workspaceEventService.workspaceMarkedForDelete
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: (workspaceResponse) => {
                if (
                  workspaceResponse.workspaceId ===
                  this.storageService.getItem(this.storageKey)
                ) {
                  this.storageService.removeItem(this.storageKey);
                }

                if (workspaceResponse.workspaceId === this.workspaceId) {
                  window.location.href = '/';
                }
              },
            });
        },
      });

    this.workspaceNameSubject.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (name) => {
        if (!name) {
          this.titleService.setTitle('nista.io');
        } else {
          this.titleService.setTitle(name + ' - nista.io');
        }
      },
    });

    this.workspaceEventService.workspaceTierUpdated
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (tierUpdatedResponse) => {
          this.workspaceTierSubject.next(tierUpdatedResponse.tier);
        },
      });

    merge(
      this.workspaceEventService.workspaceUpdated,
      this.workspaceEventService.workspaceDeleted,
      this.workspaceEventService.workspaceMarkedForDelete
    )
      .pipe(
        takeUntil(this.destroyed$),
        filter((a) => !!a)
      )
      .subscribe({
        next: (_) => {
          this.updateWorkspaceList();
        },
      });

    this.identityEventService.workspaceCreated
      .pipe(
        takeUntil(this.destroyed$),
        filter((a) => !!a)
      )
      .subscribe({
        next: (_) => {
          this.updateWorkspaceList();
        },
      });
  }

  private updateWorkspaceList() {
    this.workspaceClient
      .getUserWorkspaceList()
      .pipe(
        map((item) => {
          if (!item.workspaces) {
            return [];
          }
          return item.workspaces.sort(this.compareWorkspace);
        })
      )
      .subscribe({
        next: (sortedList) => {
          this.userWorkspaceListSubject.next(sortedList);

          const currentWorkspace = sortedList?.find(
            (a) => a.workspaceId === this.workspaceId
          );
          if (!currentWorkspace) {
            return;
          }
          if (this.workspaceNameSubject.value !== currentWorkspace.name) {
            this.workspaceNameSubject.next(currentWorkspace.name);
          }
          if (this.workspaceTierSubject.value !== currentWorkspace.tier) {
            this.workspaceTierSubject.next(currentWorkspace.tier);
          }
        },
        error: (err) => {
          this.userWorkspaceListSubject.error(err);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public static role = {
    guest: 'guest',
    readOnly: 'readonly',
    editor: 'editor',
    owner: 'owner',
    nista: 'nista',
  };

  private workspaceIdSubject = new BehaviorSubject<string | undefined>(
    undefined
  );

  public get workspaceIdChanged(): Observable<string | undefined> {
    return this.workspaceIdSubject.asObservable();
  }

  public get workspaceId(): string | undefined {
    return this.workspaceIdSubject.value;
  }

  updateWorkspace(request: WorkspaceRequest) {
    if (!this.workspaceId) {
      return;
    }
    this.workspaceClient.updateWorkspace(this.workspaceId, request).subscribe();
  }

  setLastWorkspaceId(value: string) {
    this.storageService.setItem(this.storageKey, value);
  }

  public checkWorkspaceName(
    workspaceId: string | undefined
  ): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      if (!workspaceId) {
        subscriber.next(false);
        return;
      }

      this.workspaceClient
        .getWorkspace(workspaceId)
        .pipe(
          takeUntil(this.destroyed$),
          retry({ count: 10, delay: (err, attempt) => timer(1000 * attempt) })
        )
        .subscribe({
          next: (result) => {
            if (result.companyName && result.companyName !== '') {
              subscriber.next(true);
              return;
            }

            this.router.navigate([
              '/',
              'workspace',
              workspaceId,
              'workspace-wizard',
            ]);
            subscriber.next(false);
            return;
          },
        });
    });
  }

  public setWorkspaceId(value: string | undefined): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      if (!value) {
        subscriber.next(false);
        return;
      }

      this.workspaceIdSubject.next(value);

      this.workspaceClient
        .getWorkspace(value)
        .pipe(
          takeUntil(this.destroyed$),
          retry({ count: 10, delay: (err, attempt) => timer(1000 * attempt) })
        )
        .subscribe({
          next: (result) => {
            this.workspaceIdSubject.next(result.workspaceId);
            this.setLastWorkspaceId(result.workspaceId);
            this.workspaceNameSubject.next(result.name);
            this.workspaceTierSubject.next(result.tier);
            this.workspaceEventService.registerForWorkspace(this.workspaceId);
            this.updateClaims();
            this.titleService.setTitle(result.name + ' - nista.io');
            subscriber.next(true);
          },
          error: (err) => {
            this.workspaceIdSubject.next(value);
            this.titleService.setTitle('nista.io');
            subscriber.next(false);
            if (err.status === 404) {
              this.workspaceIdSubject.next(undefined);
              this.router.navigate(['/notfound']);
            }
            if (err.status === 403) {
              this.workspaceIdSubject.next(value);
              this.updateClaims();
            }
          },
        });

      this.workspaceClient
        .getWorkspaceUsers(value)
        .pipe(
          takeUntil(this.destroyed$),
          retry({ count: 10, delay: (err, attempt) => timer(1000 * attempt) }),
          filter((l) => !!l)
        )
        .subscribe({
          next: (result) => {
            this.workspaceUserListSubject.next(result);
          },
          error: (err) => {
            this.workspaceUserListSubject.next(undefined);
          },
        });
    });
  }

  public get workspaceUrl(): (string | undefined)[] {
    if (!this.workspaceId) {
      return ['/'];
    }

    return ['/', 'workspace', this.workspaceId];
  }

  public createAbsoluteUrl(parts: (string | undefined)[]) {
    return this.workspaceUrl.concat(parts);
  }

  loadNewWorkspace() {
    const workspaceId = Guid.newGuid().toString();
    if (this.authService.email) {
      this.userDetailsService.getUser(this.authService.email).subscribe({
        next: (response) => {
          let name =
            response?.givenName ??
            response?.familyName ??
            response?.email?.split('@')[0];
          // eslint-disable-next-line @typescript-eslint/quotes
          name = name ? name + "'s" : 'My';
          this.createWorkspace(name + ' Workspace', workspaceId);
        },
        error: () => {
          this.createWorkspace('My Workspace', workspaceId);
        },
      });
    } else {
      this.createWorkspace('My Workspace', workspaceId);
    }
  }

  createWorkspace(
    name: string,
    workspaceId?: string,
    companyName?: string,
    navigate = true
  ): void {
    const request = new WorkspaceRequest({
      name,
      companyName,
    });

    const newWorkspaceId = workspaceId ?? Guid.newGuid().toString();

    this.workspaceClient
      .createWorkspace(newWorkspaceId, request)
      .pipe(take(1))
      .subscribe({
        next: (_) => {
          if (navigate) {
            this.router.navigate(['/workspace', newWorkspaceId]);
          }
        },
      });
  }

  private updateClaims(): void {
    if (!this.authService.email || !this.workspaceId) {
      return;
    }
    const request = new UserPermissionsRequest({
      userEmail: this.authService.email,
      workspaceId: this.workspaceId,
    });
    this.permissionClient
      .getPermissions(request)
      .pipe(take(1))
      .subscribe({
        next: (permissions) => {
          Claims.update(this.claims, permissions.claims, this.logger);
          this.claimsSubject.next(this.claims);
        },
      });
  }

  private compareWorkspace(
    a: WorkspaceResponseBase,
    b: WorkspaceResponseBase
  ): number {
    if (!a || !a.name) {
      return -1;
    }
    if (!b || !b.name) {
      return -1;
    }

    if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
      return -1;
    }
    if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
      return 1;
    }

    return 0;
  }

  public get userWorkspaceList(): Observable<WorkspaceResponseBase[]> {
    return this.userWorkspaceListSubject.asObservable();
  }

  public updateWorkspaceTier(tier: EnWorkspaceTierDTO): Observable<void> {
    if (!this.workspaceId) {
      return new Observable<void>();
    }

    return this.workspaceClient.updateWorkspaceTier(
      this.workspaceId,
      new UpdateTierRequest({
        tier,
      })
    );
  }

  public deleteWorkspace(): Observable<void> {
    if (!this.workspaceId) {
      return new Observable<void>();
    }
    return this.workspaceClient.deleteWorkspace(this.workspaceId);
  }
}

export class Claims {
  public static update(
    claims: Claims,
    permissionsSet: string[] | undefined,
    logger: NGXLogger
  ): void {
    const keys = Object.keys(claims);
    for (const key of keys) {
      const accessKey = key as keyof typeof claims;
      const value = claims[accessKey];

      const found =
        permissionsSet?.find((item) => item === value.name) !== undefined;
      logger.debug('Permission found', found, value.name);
      value.set(found);
    }
  }

  readWorkspace = new Claim('read_workspace');
  changeWorkspace = new Claim('change_workspace');
  deleteWorkspace = new Claim('delete_workspace');
  changeTier = new Claim('change_tier');
  createCalculation = new Claim('create_calculation');
  readCalculation = new Claim('read_calculation');
  changeCalculation = new Claim('change_calculation');
  deleteCalculation = new Claim('delete_calculation');
  createDataPoint = new Claim('create_datapoint');
  readDataPoint = new Claim('read_datapoint');
  changeDataPoint = new Claim('change_datapoint');
  deleteDataPoint = new Claim('delete_datapoint');
  createSample = new Claim('create_sample');
  readAgent = new Claim('read_agent');
  changeAgent = new Claim('change_agent');
  createReport = new Claim('create_report');
  readReport = new Claim('read_report');
  changeReport = new Claim('change_report');
  deleteReport = new Claim('delete_report');
  createTemplate = new Claim('create_template');
  readTemplate = new Claim('read_template');
  changeTemplate = new Claim('change_template');
  deleteTemplate = new Claim('delete_template');
  readFile = new Claim('read_file');
  changeFile = new Claim('change_file');
  deleteFile = new Claim('delete_file');
  createLiveData = new Claim('create_livedata');
  readLiveData = new Claim('read_livedata');
  changeLiveData = new Claim('change_livedata');
  deleteLiveData = new Claim('delete_livedata');
  readFacility = new Claim('read_facility');
  changeFacility = new Claim('change_facility');
  createFacility = new Claim('create_facility');
  deleteFacility = new Claim('delete_facility');
  readAnalysis = new Claim('read_analysis');
  changeAnalysis = new Claim('change_analysis');
  createAnalysis = new Claim('create_analysis');
  deleteAnalysis = new Claim('delete_analysis');
  readTask = new Claim('read_task');
  changeTask = new Claim('change_task');
  createTask = new Claim('create_task');
  deleteTask = new Claim('delete_task');
  readViolation = new Claim('read_violation');
  changeViolation = new Claim('change_violation');
  createViolation = new Claim('create_violation');
  deleteViolation = new Claim('delete_violation');
}
