import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  Observable,
  Subject,
  Subscription,
  debounce,
  takeUntil,
  timer,
} from 'rxjs';
import { FeedbackService } from './common-components/feedback/feedback.service';
import { ConnectionLostOverlayComponent } from './connection-lost-overlay/connection-lost-overlay.component';
import { ErrorhandlerService } from './errorhandler.service';
import { OAuthAuthenticationService } from './OAuth/oAuthAuthentication.service';
import { WebsocketConnectionStateService } from './websocket-connection-state.service';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('tryingToConnect')
  tryingToConnectTemplate!: TemplateRef<any> | null;

  loading: Observable<boolean>;

  destroyed$ = new Subject<void>();

  constructor(
    public errorHandler: ErrorhandlerService,
    private feedbackService: FeedbackService,
    private connectionState: WebsocketConnectionStateService,
    private authService: OAuthAuthenticationService,
    private overlay: Overlay,
    public loadingService: LoadingService
  ) {
    this.authService.runInitialLoginSequence();
    this.loading = this.loadingService.loading.pipe(
      takeUntil(this.destroyed$),
      debounce((val) => timer(val ? 0 : 1000))
    );
  }

  showDetails = false;

  private overlayRef?: OverlayRef;

  connectionChangedSubscription?: Subscription;

  disconnected = false;

  ngOnInit(): void {
    this.connectionChangedSubscription?.unsubscribe();
    this.connectionChangedSubscription = this.connectionState
      .onConnectionChanged()
      .subscribe((newConnectionState) => {
        this.disconnected = !newConnectionState;
        if (!newConnectionState) {
          this.blockRouterOutlet();
        } else {
          this.overlayRef?.dispose();
        }
      });
  }

  ngOnDestroy() {
    this.connectionChangedSubscription?.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get errorMessage(): string | undefined {
    if (this.errorHandler.hasError) {
      return this.errorHandler.errorMessage;
    }
    return undefined;
  }

  giveFeedback() {
    this.feedbackService.giveFeedback(
      'An Error has happened: \r\n-------------------------------\r\n' +
        this.errorMessage
    );
  }

  blockRouterOutlet() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    this.overlayRef = this.overlay.create({
      height: '100%',
      width: '100%',
      panelClass: 'darken-overlay',
    });

    const userProfilePortal = new ComponentPortal(
      ConnectionLostOverlayComponent
    );
    this.overlayRef.attach(userProfilePortal);
  }
}
