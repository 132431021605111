import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignalRConnection } from './signal-rservice.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketConnectionStateService {

  private onConnectionChangedSubject = new BehaviorSubject(true);

  private onReConnectionChangedSubject = new BehaviorSubject(false);

  private connectionStates: ConnectionState[] = [];

  constructor() { }

  register(connection: SignalRConnection) {
    if (!connection) {
      return;
    }

    const connectionState: ConnectionState = { connection, healthy: true, reconnecting: false };
    this.connectionStates.push(connectionState);

    connection.onClose().subscribe({
      next: err => {
        if (err?.message.indexOf('401') ?? -1 >= 0) {
          connectionState.healthy = true;
          connectionState.reconnecting = false;
        } else {
          connectionState.healthy = false;
          connectionState.reconnecting = false;
        }
        this.updateState();
      }
    });

    connection.collect(
      connection.onReconnecting().subscribe({
        next: () => {
          connectionState.healthy = false;
          connectionState.reconnecting = true;
          this.updateState();
        }
      }));

    connection.collect(
      connection.onReconnected().subscribe({
        next: () => {
          connectionState.healthy = true;
          connectionState.reconnecting = false;
          this.updateState();
        }
      }));
  }

  remove(connection: SignalRConnection | undefined) {
    if (!connection) {
      return;
    }

    const index = this.connectionStates.findIndex(item => item.connection === connection);
    if (index !== undefined) {
      this.connectionStates.splice(index, 1);
    }
  }

  updateState() {
    const unhealthy = this.connectionStates.find(item => !item.healthy);
    if (unhealthy) {
      this.onConnectionChangedSubject.next(false);
    } else {
      this.onConnectionChangedSubject.next(true);
    }

    const reconnecting = this.connectionStates.find(item => item.reconnecting);
    if (reconnecting) {
      this.onReConnectionChangedSubject.next(true);
    } else {
      this.onReConnectionChangedSubject.next(false);
    }
  }


  onConnectionChanged(): Observable<boolean> {
    return this.onConnectionChangedSubject.asObservable();
  }

  onReconnectChanged(): Observable<boolean> {
    return this.onReConnectionChangedSubject.asObservable();
  }

}

interface ConnectionState {
  connection: SignalRConnection;
  healthy: boolean;
  reconnecting: boolean;
}
