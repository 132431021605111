<div class="screen">
  <div class="topbar navigation-root">
    <div class="left">
      <a [routerLink]="['/workspace']">
        <img src="/assets/wordmark.svg" class="wordmark" />
      </a>
    </div>
    <div class="right">
      <div class="user-icon">
        <app-user-badge [email]="email"></app-user-badge>
      </div>
      <div class="button">
        <button app-nista-button color="secondary" (click)="logout()">
          Abmelden
        </button>
      </div>
    </div>
  </div>
  <div class="main">
    <div class="section">
      <div class="illustration">
        <img
          src="/assets/sunglasses_no_color.svg"
          *ngIf="!showFreeWorkspaceMessage"
        />
        <img src="/assets/images/ghost.svg" *ngIf="showFreeWorkspaceMessage" />
      </div>
      <div class="text" *ngIf="!showFreeWorkspaceMessage">
        <div class="heading-xxl grey50">Willkomen bei nista.io!</div>
        <div class="body-xxl grey150">
          Sie haben sich erfolgreich bei nista.io registriert! Um die Nutzung
          von nista.io fortzusetzen, setzen Sie sich mit uns in Verbindung und
          buchen Sie eine Demo. Wenn Sie bereits einen nista-Plan haben, bitten
          Sie die verantwortliche Person in Ihrem Unternehmen, Sie zu dem
          Unternehmens-workspace einzuladen.
        </div>
      </div>
      <div class="text" *ngIf="showFreeWorkspaceMessage">
        <div class="heading-xxl grey50">
          Kostenlose Workspaces wurden eingestellt
        </div>
        <div class="body-xxl grey150">
          Wir haben kostenlose Workspaces eingestellt. Um die Nutzung von
          nista.io fortzusetzen, setzen Sie sich mit uns in Verbindung und
          buchen Sie eine Demo. Wenn Sie bereits einen nista-Plan haben, bitten
          Sie die verantwortliche Person in Ihrem Unternehmen, Sie zu dem
          Unternehmens-workspace einzuladen.
        </div>
      </div>
      <div class="button">
        <a
          *ngIf="(featureFlag.shouldBeShown | async) === false"
          app-nista-button
          size="large"
          href="https://meetings.hubspot.com/anna-poelzl/meetnista"
          >Demo buchen</a
        >
        <a
          *ngIf="(featureFlag.shouldBeShown | async) === true"
          app-nista-button
          size="large"
          (click)="createWorkspace()"
          >Workspace erstellen</a
        >
      </div>
    </div>
  </div>
</div>
