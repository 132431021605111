<div #element class="inline-input-wrapper">
  <ng-container
    *ngTemplateOutlet="displayTemplate; context: { $implicit: value }"
  >
  </ng-container>
  <div
    class="save-icon"
    [ngClass]="{ outside: minimizeWidth, inside: !minimizeWidth }"
    *ngIf="displaySaveState && false"
  >
    <div class="icon-container">
      <ng-container *ngTemplateOutlet="saveOverlayTemplate"></ng-container>
    </div>
  </div>
</div>

<ng-template #defaultViewTemplate let-value>
  <div
    *ngIf="value && value.text"
    style="position: relative"
    class="view valueField"
  >
    {{ value.text ?? '' }}
  </div>
  <div
    *ngIf="!value || !value.text"
    style="position: relative"
    class="view valueField placeholder-inline"
  >
    {{ placeHolder }}
  </div>
</ng-template>

<ng-template #defaultSavingView>
  <app-gnista-icon icon="done" class="info"></app-gnista-icon>
</ng-template>

<ng-template #defaultSavedView>
  <app-gnista-icon icon="done" class="info"></app-gnista-icon>
</ng-template>

<ng-template #defaultErrorView>
  <app-gnista-icon icon="info" class="error">info</app-gnista-icon>
</ng-template>

<ng-template #defaultEditTemplate let-value>
  <div style="position: relative" class="editField">
    <input
      appEditableFocusable
      mousedown-stop-propagation
      (focus)="updateWidth($event)"
      matInput
      (input)="updateWidth($event)"
      [style.width]="minimizeWidth ? textWidthForStyle : '100%'"
      class="edit"
      required
      [formControl]="control"
      [placeholder]="placeHolder"
      [matAutocomplete]="auto"
      [readonly]="!editAllowed"
    />
  </div>
</ng-template>

<mat-autocomplete #auto (optionSelected)="optionSelected($event)">
  <mat-option
    *ngFor="let option of autoCompleteList; trackBy: trackKey"
    [value]="option.key"
  >
    <ng-container
      *ngTemplateOutlet="autoCompleteTemplate; context: { $implicit: option }"
    >
    </ng-container>
  </mat-option>
</mat-autocomplete>
