import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RatingChangeEvent } from 'angular-star-rating';
import { NgxCaptureService } from 'ngx-capture';
import { FeedbackRequest } from 'src/app/identity-server-client';
import { WorkspaceService } from 'src/app/workspace.service';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
  feedbackText?: string;

  image?: string;

  rating = 3;

  ngOnInit(): void {}

  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private captureService: NgxCaptureService,
    public workspaceService: WorkspaceService,
    @Inject(MAT_DIALOG_DATA) data?: string
  ) {
    this.feedbackText = data;
    this.workspaceService = workspaceService;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteCapture() {
    this.image = undefined;
  }

  onRatingChange(event: RatingChangeEvent) {
    this.rating = event.rating;
  }

  get result(): FeedbackRequest {
    return new FeedbackRequest({
      feedbackText: this.feedbackText,
      rating: this.rating || -1,
      screenshot: this.image,
      internal: false,
      workspaceId: this.workspaceService.workspaceId,
    });
  }

  capture() {
    const element = document.getElementsByClassName('screenshot-root')[0];

    const bodyElement = element.parentElement;
    if (!bodyElement) {
      return;
    }
    this.captureService.getImage(bodyElement, true).subscribe({
      next: (img) => {
        this.image = img;
      },
    });
  }
}
