import { Injectable, OnDestroy } from '@angular/core';
import {
  CustomDimensionsService,
  UserManagementService,
} from '@piwikpro/ngx-piwik-pro';
import { NGXLogger } from 'ngx-logger';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  filter,
  map,
  take,
  takeUntil,
} from 'rxjs';
import { OAuthAuthenticationService } from './OAuth/oAuthAuthentication.service';
import {
  StalkerClient,
  StalkerRequest,
  WorkspaceInfoUpdateRequest,
} from './tracking-service-client';
import { WorkspaceService } from './workspace.service';
import { FacilityService } from './facility.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService implements OnDestroy {
  destroyed$ = new Subject<void>();

  private workspaceStatusSubject = new BehaviorSubject<boolean | undefined>(
    undefined
  );

  constructor(
    private trackingClient: StalkerClient,
    private workspaceService: WorkspaceService,
    private facilityService: FacilityService,
    private logger: NGXLogger,
    private authenticationService: OAuthAuthenticationService,
    private userManagementService: UserManagementService,
    private customDimensionsService: CustomDimensionsService
  ) {
    combineLatest([
      this.authenticationService.isDoneLoading,
      this.authenticationService.isAuthenticated,
    ])
      .pipe(
        takeUntil(this.destroyed$),
        map((values) => values.every((b) => b)),
        filter((item) => item)
      )
      .subscribe({
        next: () => {
          if (!this.authenticationService.email) {
            try {
              this.userManagementService.resetUserId();
            } catch {}
            return;
          }
          if (this.authenticationService.email) {
            try {
              this.userManagementService.setUserId(
                this.authenticationService.email
              );
            } catch {}
          }
        },
      });

    this.workspaceService.workspaceIdChanged
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (workspaceId) => {
          if (!workspaceId) {
            try {
              this.customDimensionsService.deleteCustomDimension('WorkspaceId');
            } catch {}
            return;
          }
          try {
            this.customDimensionsService.setCustomDimensionValue(
              'WorkspaceId',
              workspaceId
            );
          } catch {}

          this.trackingClient.getWorkspaceInfo(workspaceId).subscribe({
            next: (response) => {
              this.workspaceStatusSubject.next(response.enabled ?? false);
            },
            error: (err) => {
              this.workspaceStatusSubject.next(undefined);
            },
          });
        },
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get workspaceStatus(): Observable<boolean | undefined> {
    return this.workspaceStatusSubject.asObservable();
  }

  private sendWorkspaceTracking(
    eventType: string,
    objectId?: string,
    notes?: string
  ) {
    combineLatest([
      this.workspaceService.workspaceIdChanged,
      this.workspaceService.workspaceName,
      this.facilityService.facilityIdSubject,
    ])
      .pipe(
        filter((a) => a.every((e) => !!e)),
        take(1)
      )
      .subscribe({
        next: (infos) => {
          const request = new StalkerRequest({
            eventType,
            workspaceId: infos[0],
            facilityId: infos[2],
            workspaceName: infos[1],
            notes,
            objectId,
          });
          this.trackingClient.createStalker(request).subscribe({
            error: (err) => {
              this.logger.debug(err);
            },
          });
        },
      });
  }

  //user clicks on “New calculation”
  public trackNewCalculation(calculationId: string) {
    this.sendWorkspaceTracking('New calculation created', calculationId);
  }

  //user clicks on “Share”
  public trackShareLinkCreated(artefactId: string, sharedUrl: string) {
    this.sendWorkspaceTracking('Share Link created', artefactId, sharedUrl);
  }

  // Existing calculation was opened by user
  public trackCalculationOpened(calculationId: string) {
    this.sendWorkspaceTracking('Calculation opened', calculationId);
  }

  //user clicks on “Add data”
  public trackAddData() {
    this.sendWorkspaceTracking('Add Data Button clicked');
  }

  // user invites another person to nista app
  public trackInvitedUser(invitedUserEmail?: string) {
    this.sendWorkspaceTracking('Invited user', undefined, invitedUserEmail);
  }

  // Users feedback through the feedback overlay
  public trackUserFeedback(rating?: string) {
    this.sendWorkspaceTracking('Overall rating', undefined, rating);
  }

  // Dashboad report opened
  trackDashboardOpened(reportId: string) {
    this.sendWorkspaceTracking('Dashboard opened', reportId);
  }

  //user clicks on “Upgrade button clicked”
  public trackGetInTouch() {
    combineLatest([
      this.workspaceService.workspaceIdChanged,
      this.workspaceService.workspaceName,
    ])
      .pipe(
        filter((a) => a.every((e) => !!e)),
        take(1)
      )
      .subscribe({
        next: (workspace) => {
          const request = new StalkerRequest({
            eventType: 'Upgrade button clicked',
            workspaceId: workspace[0],
            workspaceName: workspace[1],
          });
          this.trackingClient.upgradeClicked(request).subscribe({
            error: (err) => {
              this.logger.debug(err);
            },
          });
        },
      });
  }

  //user opened a report
  public trackReportOpened(id?: string) {
    this.sendWorkspaceTracking('Report has been openend', id);
  }

  //user downloaded a report
  public trackReportDownloaded(id?: string) {
    this.sendWorkspaceTracking('Report downloaded', id);
  }

  public trackFileDownloaded(id?: string) {
    this.sendWorkspaceTracking('File downloaded', id);
  }

  public trackFileUploaded(id?: string, name?: string) {
    this.sendWorkspaceTracking('File uploaded', id, `name: ${name}`);
  }

  public workspaceSelected(id?: string) {
    this.sendWorkspaceTracking('Workspace Selected', id);
  }

  public updateWorkspaceTracking(id: string, enabled: boolean) {
    const request = new WorkspaceInfoUpdateRequest({
      enabled,
    });
    this.trackingClient
      .updateWorkspaceTracking(this.workspaceService.workspaceId ?? '', request)
      .subscribe({
        error: (err) => {
          this.logger.debug(err);
        },
      });
  }
}
