import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../settings.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthAuthenticationService {
  private loggedOut$ = new Subject<void>();

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public get isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  private isDoneLoadingSubject = new BehaviorSubject<boolean>(false);
  public get isDoneLoading(): Observable<boolean> {
    return this.isDoneLoadingSubject.asObservable();
  }

  public ensureLoggedIn(url?: string): Observable<boolean> {
    return this.isDoneLoading.pipe(
      takeUntil(this.loggedOut$),
      filter((isDone) => isDone),
      switchMap((_) => this.isAuthenticated),
      take(1),
      tap((isAuthenticated) => isAuthenticated || this.login(url))
    );
  }

  public ensureLoggedInGetToken(url?: string): Observable<string> {
    return this.isDoneLoading.pipe(
      takeUntil(this.loggedOut$),
      filter((isDone) => isDone),
      switchMap((_) => this.isAuthenticated),
      take(1),
      tap((isAuthenticated) => isAuthenticated || this.login(url)),
      filter((isAuthenticated) => isAuthenticated),
      map((_) => this.accessToken)
    );
  }

  private navigateToLoginPage(url?: string) {
    this.logger.info('Trying to login');
    const toRoute = window.location.pathname;
    this.login(url ?? toRoute);
  }

  private errorResponsesRequiringUserInteraction = [
    'interaction_required',
    'login_required',
    'account_selection_required',
    'consent_required',
  ];

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    settingsService: SettingsService,
    private logger: NGXLogger
  ) {
    const authConfig: AuthConfig = {
      // Url of the Identity Provider
      issuer: settingsService.active.issuerURL,

      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin + '/loggingin',

      clearHashAfterLogin: false,
      sessionChecksEnabled: environment.production,
      requireHttps: true,
      // The SPA's id. The SPA is registerd with this id at the auth-server
      // clientId: 'server.code',
      clientId: 'ng',

      // Just needed if your auth server demands a secret. In general, this
      // is a sign that the auth server is not configured with SPAs in mind
      // and it might not enforce further best practices vital for security
      // such applications.
      // dummyClientSecret: 'secret',

      responseType: 'code',

      // set the scope for the permissions the client should request
      // The first four are defined by OIDC.
      // Important: Request offline_access to get a refresh token
      // The api scope is a usecase specific one
      scope:
        'openid profile offline_access IdentityServerApi websocket-api data-api scheduler-api calculation-api search-api agent-api sample-api status-api workspace-api tracking-api report-api file-api notification-api live-data-api facility-api analysis-api bigbrain-api task-api violation-api',

      showDebugInformation: !environment.production,
      useSilentRefresh: false,
      silentRefreshTimeout: 0,
    };

    this.oauthService.configure(authConfig);

    // Useful for debugging:
    this.oauthService.events.subscribe({
      next: (event) => {
        if (event instanceof OAuthErrorEvent) {
          this.logger.error(
            'OAuthErrorEvent Object:',
            event,
            event.reason,
            event.params
          );
        } else {
          this.logger.debug('OAuth Object:', event);
        }
      },
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      this.logger.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
      );
      this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      } else {
        this.updateLoggerCredentials();
      }
    });

    this.oauthService.events.subscribe({
      next: (event) => {
        if (event.type === 'session_unchanged') {
          return;
        }

        const hasValidToken = this.oauthService.hasValidAccessToken();
        this.isAuthenticatedSubject.next(hasValidToken);

        if (hasValidToken) {
          this.updateLoggerCredentials();
        }
      },
    });

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe({
        next: () => {
          this.oauthService.loadUserProfile();

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }

            setTimeout(() => {
              this.logger.info(
                `There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`
              );
              this.router.navigateByUrl(stateUrl);
              window.location.href = stateUrl;
            }, 1000);
          }
        },
      });

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe({ next: (e) => this.navigateToLoginPage() });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      this.logger.debug(
        'Encountered hash fragment, plotting as table...',
        location.hash
          .substr(1)
          .split('&')
          .map((kvp) => kvp.split('='))
      );
    }

    const params = new URL(window.location.href).searchParams as any;

    const customQueryParams: any = {};
    for (const param of params) {
      const key = param[0];
      if (key?.startsWith('utm_')) {
        customQueryParams[key] = param[1];
      }
    }

    this.oauthService.customQueryParams = customQueryParams;
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocumentAndTryLogin()
        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLoginCodeFlow())

        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }

          // 2. SILENT LOGIN:
          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
          return this.oauthService
            .silentRefresh()
            .then(() => Promise.resolve())
            .catch((result) => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              if (
                result &&
                result.reason &&
                result.reason.params &&
                this.errorResponsesRequiringUserInteraction.indexOf(
                  result.reason.params.error
                ) >= 0
              ) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                //              this.login(null);
                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        })

        .then(() => {
          this.isDoneLoadingSubject.next(true);
        })
        .catch(() => this.isDoneLoadingSubject.next(true))
    );
  }

  public login(url?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initCodeFlow(url || this.router.url);
  }

  public logout() {
    this.loggedOut$.next();
    this.loggedOut$.complete();
    this.oauthService.logOut();
  }

  public hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public get email(): string | null {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims.sub;
  }

  public get userName(): string | null {
    const claims: any = this.oauthService.getIdentityClaims();

    if (!claims) {
      return null;
    }

    let userName = null;

    if (claims.given_name) {
      userName = claims.given_name;
    }

    if (claims.family_name) {
      userName += ' ' + claims.family_name;
    }

    if (userName) {
      return userName;
    }

    return null;
  }

  public get givenName(): string | null {
    const claims: any = this.oauthService.getIdentityClaims();

    if (!claims) {
      return null;
    }

    if (claims.given_name) {
      return claims.given_name;
    }

    return null;
  }

  public get familyName(): string | null {
    const claims: any = this.oauthService.getIdentityClaims();

    if (!claims) {
      return null;
    }

    if (claims.family_name) {
      return claims.family_name;
    }

    return null;
  }

  private updateLoggerCredentials() {
    const token = this.accessToken;
    if (token && this.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.logger.setCustomHttpHeaders(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken })
      );
      const updatedConfig = this.logger.getConfigSnapshot();
      updatedConfig.serverLogLevel = NgxLoggerLevel.OFF;
      this.logger.updateConfig(updatedConfig);
    } else {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.logger.setCustomHttpHeaders(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken })
      );
      const updatedConfig = this.logger.getConfigSnapshot();
      updatedConfig.serverLogLevel = NgxLoggerLevel.OFF;
      this.logger.updateConfig(updatedConfig);
    }
  }
}
