<div class="dialog-container">
  <div class="top">
    <h2 mat-dialog-title>License information</h2>
  </div>
  <div class="content">
    <cdk-virtual-scroll-viewport class="license-viewport" itemSize="60">
      <dl class="license-dl">
        <ng-container *cdkVirtualFor="let license of licenses">
          <dt class="license-dt">{{ license.packageName }}</dt>
          <dd class="license-dd">
            <a matLine href="{{ license.licenseLink }}" rel="noreferrer">{{
              license.licenseLink
            }}</a>
          </dd>
        </ng-container>
      </dl>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
