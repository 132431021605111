import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-http-loading-bar-display',
  templateUrl: './http-loading-bar-display.component.html',
  styleUrls: ['./http-loading-bar-display.component.scss']
})
export class HttpLoadingBarDisplayComponent implements OnInit {

  @Input()
  value?: number;

  @Input()
  showDone?: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
