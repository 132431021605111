import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

export class MemoryStorage implements Storage {
  internalStorage = new Map<string, string | null>();

  [name: string]: any;
  get length(): number {
    return this.internalStorage.size;
  }

  clear(): void {
    this.internalStorage.clear();
  }

  getItem(key: string): string | null {
    return this.internalStorage.get(key) ?? null;
  }

  key(index: number): string | null {
    const array = Array.from(this.internalStorage, ([name, value]) => (value));
    return array[index];
  }

  removeItem(key: string): void {
    this.internalStorage.delete(key);
  }

  setItem(key: string, value: string): void {
    this.internalStorage.set(key, value);
  }

}

@Injectable({
  providedIn: 'root'
})
export class StorageService implements Storage {

  constructor(private logger: NGXLogger) {
    this.instance = this.decideStorage();
  }
  [name: string]: any;

  get length(): number {
    return this.instance.length;
  }

  clear(): void {
    this.instance?.clear();
  }

  getItem(key: string): string | null {
    return this.instance.getItem(key);
  }

  key(index: number): string | null {
    return this.instance[index];
  }

  removeItem(key: string): void {
    this.instance.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.instance.setItem(key, value);
  }

  instance: Storage;

  static memoryStorage = new MemoryStorage();

  private decideStorage(): Storage {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      this.logger.info('Using Local Storage ');
      return localStorage;
    }
    catch (e) {
      this.logger.debug('Not using Local Storage because of exception', e);
    }

    try {
      sessionStorage.setItem('test', 'test');
      sessionStorage.removeItem('test');
      this.logger.info('Using Session Storage ');
      return sessionStorage;
    } catch (e) {
      this.logger.debug('Not using Session Storage because of exception', e);
    }

    this.logger.info('Using in Memory Storage');
    return StorageService.memoryStorage;
  }
}


