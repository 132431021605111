import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { POSSIBLECOLORS, PossibleColors } from 'src/app/colors';

@Component({
  selector: 'app-nista-avatar',
  templateUrl: './nista-avatar.component.html',
  styleUrls: ['./nista-avatar.component.scss'],
})
export class NistaAvatarComponent implements OnInit, OnChanges {
  @Input()
  avatar?: Face;

  @Input()
  matTooltip!: string;

  @Input()
  size?: number = 40;

  @Input()
  colorOverride? = 'white';

  @Input()
  bgColorOverride?: PossibleColors | string;

  bgColor?: string;

  cleanImageSrc?: SafeUrl;

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.calcImageSrc();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.avatar) {
      this.avatar = changes.avatar.currentValue;
      this.calcImageSrc();
    }
    if (changes.bgColorOverride) {
      this.bgColorOverride = changes.bgColorOverride.currentValue;
      if (typeof this.bgColorOverride === 'string') {
        this.bgColor = this.bgColorOverride;
      } else {
        this.bgColor = POSSIBLECOLORS[this.bgColorOverride ?? 'power'];
      }
    }
    if (changes.colorOverride) {
      this.colorOverride = changes.colorOverride.currentValue;
      if (this.colorOverride === undefined || this.colorOverride === '') {
        this.colorOverride = 'white';
      }
    }
  }

  get faceSource(): string {
    return './assets/images/faces/' + this.avatar + '.svg';
  }

  public calcImageSrc(): void {
    if (!this.faceSource) {
      this.cleanImageSrc = undefined;
    }

    this.cleanImageSrc = this._sanitizer.bypassSecurityTrustStyle(
      'url(' + this.faceSource + ')'
    );
  }
}

export type Face = keyof typeof FACES;

export const FACES = {
  angry: 'angry',
  coolGuy: 'coolGuy',
  cowboy: 'cowboy',
  happy: 'happy',
  laugh: 'laugh',
  love: 'love',
  mad: 'mad',
  moustacheGlasses: 'moustacheGlasses',
  nisti: 'nisti',
  nokay: 'nokay',
  okay: 'okay',
  perplex: 'perplex',
  sad: 'sad',
  shame: 'shame',
  sunglasses: 'sunglasses',
  surprised: 'surprised',
  sweat: 'sweat',
  uncertain: 'uncertain',
  what: 'what',
  wink: 'wink',
  yelling: 'yelling',
};
