import { Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  Subscriber,
  catchError,
  mergeMap,
  retry,
  takeUntil,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestQueueService {
  private queue = new Subject<GenericLoader>();

  constructor() {
    this.queue
      .pipe(
        mergeMap((l) => l.load().pipe(), 3),
        retry({
          count: 3,
          delay: 200,
        }),
        catchError((error, caught) => caught)
      )
      .subscribe();
  }

  public addToQueue<T>(
    observableToLoad: Observable<T>,
    cancelToken: Observable<void>
  ): Observable<T> {
    return new Observable<T>((subscriber) => {
      const loader = new GenericLoader(
        observableToLoad,
        subscriber,
        cancelToken
      );
      this.queue.next(loader);
    });
  }
}

export class GenericLoader {
  loaderObservable?: Observable<any>;

  constructor(
    private observableToLoad: Observable<any>,
    private result: Subscriber<any>,
    private cancelToken: Observable<void>
  ) {}

  public load(): Observable<any> {
    return new Observable((sub) => {
      this.observableToLoad.pipe(takeUntil(this.cancelToken)).subscribe({
        next: (result) => {
          this.result.next(result);
          sub.next(result);
        },
        error: (err) => {
          this.result.error(err);
          sub.error(err);
        },
        complete: () => {
          this.result.complete();
          sub.complete();
        },
      });
    });
  }
}
