import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings } from './settings';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsHttpService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  initializeApp(): Observable<void> {
    return new Observable<void>((resolve) => {
      this.http.get('assets/settings.json').subscribe({
        next: (response) => {
          this.settingsService.active = response as Settings;
          resolve.next();
          resolve.complete();
        },
        error: (err) => resolve.error(err),
      });
    });
  }
}
