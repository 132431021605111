import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import {
  DataPointClient,
  DataPointResponseBase,
  SeriesDataBucket,
} from 'src/app/datapoint-service-client';
import { FacilityResponse } from 'src/app/facility-service-client';
import { LiveDataStatusResponse } from 'src/app/liveData-service-client';
import { RequestQueueService } from 'src/app/request-queue.service';

@Component({
  selector: 'app-eda-livedata-status-widget',
  templateUrl: './eda-livedata-status-widget.component.html',
  styleUrls: ['./eda-livedata-status-widget.component.scss'],
})
export class EdaLivedataStatusWidgetComponent implements OnChanges, OnDestroy {
  @Input() liveData?: LiveDataStatusResponse;
  @Input() facility?: FacilityResponse;
  @Input() workspaceId?: string;

  @Output() doneLoadingEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>(false);

  @Output() errorEmitter: EventEmitter<[string, string]> = new EventEmitter<
    [string, string]
  >();

  dataPoint?: DataPointResponseBase;
  datapointStore?: SeriesDataBucket;

  dataPointOutdated = false;

  loading = new BehaviorSubject<boolean>(true);

  private destroyed$ = new Subject<void>();

  constructor(
    private dataPointClient: DataPointClient,
    private queueService: RequestQueueService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.facility) {
      this.facility = changes.facility.currentValue;
      this.destroyed$.next();
    }
    if (changes.workspaceId) {
      this.workspaceId = changes.workspaceId.currentValue;
      this.destroyed$.next();
    }
    if (changes.liveData) {
      this.liveData = changes.liveData.currentValue;
      this.destroyed$.next();
    }
    this.updateIfPossible();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateIfPossible(): void {
    if (!this.workspaceId) {
      return;
    }

    if (!this.facility) {
      return;
    }

    if (!this.facility.facilityId) {
      return;
    }

    if (!this.liveData) {
      return;
    }

    if (!this.liveData.edaLiveDataStatus?.dataPointId) {
      this.doneLoadingEmitter.emit(true);
      this.errorEmitter.emit([
        this.facility?.facilityId,
        `No Datapoint linked to LiveDataObject`,
      ]);
      return;
    }

    this.doneLoadingEmitter.emit(false);

    this.queueService
      .addToQueue<DataPointResponseBase>(
        this.dataPointClient.getDataPoint(
          this.liveData.edaLiveDataStatus?.dataPointId ?? '',
          this.workspaceId
        ),
        this.destroyed$
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.dataPoint = response;
          this.datapointStore = response.store as SeriesDataBucket;
          this.loading.next(false);

          this.doneLoadingEmitter.emit(true);

          if (!this.datapointStore) {
            return;
          }

          if (!this.datapointStore.lastEntry) {
            return;
          }

          if (!this.facility?.facilityId) {
            return;
          }

          const now = new Date();
          const diff = now.getTime() - this.datapointStore.lastEntry.getTime();
          const diffDays = diff / (1000 * 3600 * 24);
          this.dataPointOutdated = diffDays > 7;
          if (this.dataPointOutdated) {
            this.errorEmitter.emit([
              this.facility?.facilityId,
              `No Data received since ${this.datapointStore.lastEntry}.`,
            ]);
          }
        },
      });

    this.loading.next(!this.dataPoint);
  }
}
