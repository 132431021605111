import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appEditableFocusable]',
})
export class EditableFocusDirective implements OnInit, AfterViewInit {
  @Input() appEditableFocusable: string | boolean = 'true';

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit(): void {
    if (
      (this.appEditableFocusable && this.appEditableFocusable === 'false') ||
      this.appEditableFocusable === false
    ) {
      return;
    }
    this.el.nativeElement.focus();
    this.el.nativeElement.select();
  }

  ngOnInit(): void {
    if (
      (this.appEditableFocusable && this.appEditableFocusable === 'false') ||
      this.appEditableFocusable === false
    ) {
      return;
    }
    this.el.nativeElement.focus();
    this.el.nativeElement.select();
  }
}
