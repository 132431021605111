import { Component, OnInit } from '@angular/core';
import { Icon } from '../common-components/gnista-icon/gnista-icon.component';
import {
  DeploymentStatusResponse,
  PublicStatusClient,
  StatusResponse,
} from '../status-service-client';
import { OAuthAuthenticationService } from '../OAuth/oAuthAuthentication.service';
import { filter, switchMap, take } from 'rxjs';
import {
  WorkspaceClient,
  WorkspaceResponseBase,
} from '../workspace-service-client';
import { Guid } from '../guid';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  constructor(
    private statusClient: PublicStatusClient,
    private authService: OAuthAuthenticationService,
    private workspaceClient: WorkspaceClient
  ) {}

  doneLoading = false;

  status?: StatusResponse;
  allLinesOfCode = 0;

  workspaceList?: WorkspaceResponseBase[];
  isNista = false;

  ngOnInit(): void {
    this.authService
      .ensureLoggedIn()
      .pipe(
        take(1),
        filter(() => this.authService.email?.endsWith('@NISTA.IO') ?? false),
        switchMap(() => this.workspaceClient.getUserWorkspaceList())
      )
      .subscribe({
        next: (list) => {
          this.isNista = true;
          this.workspaceList = list.workspaces;
        },
      });

    this.statusClient.getStatus().subscribe({
      next: (result) => {
        this.status = result;
        this.buildLanguageMap();
        this.buildCommitMap();
        this.allLinesOfCode =
          result.linesOfCode?.find((item) => item.language === 'SUM')?.code ??
          0;
        this.doneLoading = true;
      },
    });
  }

  getStatusIcon(deployment: DeploymentStatusResponse): {
    color: string;
    icon: Icon;
  } {
    if (deployment.status === 'Available = True') {
      return {
        color: 'green',
        icon: 'done',
      };
    }

    if (deployment.status === 'Starting = True') {
      return {
        color: 'yellow',
        icon: 'warning',
      };
    }

    return {
      color: 'red',
      icon: 'error',
    };
  }

  buildLanguageMap() {
    if (!this.status?.deployments) {
      return;
    }
    if (!this.status?.linesOfCode) {
      return;
    }

    for (const deployment of this.status.deployments) {
      let sum = 0;
      const children = [];
      if (deployment.linesOfCode) {
        for (const loc of deployment.linesOfCode) {
          if (loc.language === 'SUM') {
            sum = loc.code ?? 0;
            continue;
          }

          const languageSeries = {
            name: loc.language,
            value: loc.code,
          };

          children.push(languageSeries);
        }
      }
    }
  }

  buildCommitMap() {
    if (!this.status?.deployments) {
      return;
    }
    if (!this.status?.linesOfCode) {
      return;
    }

    for (const pod of this.status.deployments) {
      const children = [];
      if (pod.commits) {
        for (const loc of pod.commits) {
          const languageSeries = {
            name: loc.name,
            value: loc.commits,
          };

          children.push(languageSeries);
        }
      }
    }
  }
}
