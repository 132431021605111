import { KeyValue } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-inline-input-display',
  templateUrl: './inline-input-display.component.html',
  styleUrls: ['./inline-input-display.component.scss'],
})
export class InlineInputDisplayComponent
  implements OnInit, AfterViewInit, OnChanges, AfterViewChecked, OnDestroy
{
  @Input()
  editMode = false;

  @Input()
  placeHolder = '';

  @Input()
  value?: ViewValue;

  @Input()
  editAllowed = true;

  @Input()
  autoCompleteList?: KeyValue<string, unknown>[];

  @Input()
  autoCompleteTemplate: TemplateRef<any> | null = null;

  @Input()
  minimizeWidth = true;

  @Input()
  showSaving = true;

  control: UntypedFormControl = new UntypedFormControl();

  @Input()
  viewTemplate: TemplateRef<any> | null = null;

  @Input()
  editTemplate: TemplateRef<any> | null = null;

  @Input()
  displayState: State = State.unchanged;

  @Input()
  displaySaveState = true;

  displayTemplate: TemplateRef<any> | null = null;

  saveOverlayTemplate: TemplateRef<any> | null = null;

  @ViewChild('defaultViewTemplate')
  defaultViewTemplate!: TemplateRef<any>;

  @ViewChild('defaultEditTemplate')
  defaultEditTemplate!: TemplateRef<any>;

  @ViewChild('defaultSavingView')
  defaultSavingView!: TemplateRef<any>;

  @ViewChild('defaultSavedView')
  defaultSavedView!: TemplateRef<any>;

  @ViewChild('defaultSavedView')
  defaultErrorView!: TemplateRef<any>;

  textWidthForStyle = '200px';

  @Output()
  typed: EventEmitter<string> = new EventEmitter();

  @Output()
  saved: EventEmitter<ViewValue> = new EventEmitter();

  @Output()
  canceled: EventEmitter<void> = new EventEmitter();

  destroyed$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewChecked(): void {
    this.updateEditMode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.value = changes.value.currentValue;
      this.control.setValue(this.value?.text, { onlySelf: true });
    }

    if (changes.viewTemplate) {
      this.viewTemplate = changes.viewTemplate.currentValue;
    }

    if (changes.editTemplate) {
      this.editTemplate = changes.editTemplate.currentValue;
    }

    if (changes.editMode) {
      this.editMode = changes.editMode.currentValue;
    }

    if (changes.displayState) {
      this.displayState = changes.displayState.currentValue;
      if (this.displayState === State.saved) {
        this.saveOverlayTemplate = this.defaultSavedView;
      } else if (this.displayState === State.saving) {
        this.saveOverlayTemplate = this.defaultSavingView;
      } else if (this.displayState === State.error) {
        this.saveOverlayTemplate = this.defaultErrorView;
      } else {
        this.saveOverlayTemplate = null;
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.viewTemplate) {
      this.viewTemplate = this.defaultViewTemplate;
    }

    if (!this.editTemplate) {
      this.editTemplate = this.defaultEditTemplate;
    }
  }

  updateEditMode() {
    if (this.editMode && this.editAllowed) {
      this.displayTemplate = this.editTemplate;
    } else {
      this.displayTemplate = this.viewTemplate;
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.updateEditMode();
    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => this.typed.emit(value));
  }

  updateWidth(event: Event) {
    if (!this.minimizeWidth) {
      return;
    }
    const element = event.target as HTMLInputElement;
    const value =
      !element.value || element.value.length === 0
        ? this.placeHolder
        : element.value;
    this.textWidthForStyle = this.textWidth(value, element) + 'px';
    this.changeDetectorRef.detectChanges();
  }

  textWidth(value: string, element: any): number {
    const ctx = this.renderer.createElement('canvas').getContext('2d');
    const { fontStyle, fontVariant, fontWeight, fontSize, fontFamily } =
      getComputedStyle(element, '');

    // font string format: {normal, normal, 700, 20px, Roboto, "Helvetica Neue", sans-serif}
    ctx.font =
      fontStyle +
      ' ' +
      fontVariant +
      ' ' +
      fontWeight +
      ' ' +
      fontSize +
      ' ' +
      fontFamily;
    const textWidth = ctx.measureText(value).width + 18;
    if (textWidth < 48) {
      return 48;
    }
    return textWidth;
  }

  trackKey(index: number, value: KeyValue<string, unknown>) {
    return value.key;
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.saved.emit({ text: event.option.value });
  }

  @HostListener('keyup.enter')
  onEnter(): void {
    if (!this.editMode || !this.editAllowed) {
      return;
    }
    this.saved.emit({ text: this.control.value });
  }

  @HostListener('keyup.esc')
  onEscape(): void {
    if (!this.editMode || !this.editAllowed) {
      return;
    }
    this.canceled.emit();
  }
}

export interface ViewValue {
  data?: any;
  text: string;
}

export enum State {
  'unchanged',
  'changed',
  'saving',
  'saved',
  'error',
}
