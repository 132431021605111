import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from './settings.service';
import { WorkspaceService } from './workspace.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalNavigationService {
  constructor(
    private settingsService: SettingsService,
    public dialog: MatDialog,
    private workspaceService: WorkspaceService
  ) {}

  public changeWorkspace(workspaceId: string) {
    window.location.href = window.location.origin + '/workspace/' + workspaceId;
  }

  public manageAccount() {
    window.open(
      this.settingsService.active.issuerURL +
        '/Profile?returnUrl=' +
        window.location.origin +
        '/home',
      '_blank'
    );
  }

  public privacyPolicy() {
    window.open(
      'https://www.nista.io/de/datenschutzerklaerung',
      '_blank',
      'noopener'
    );
  }

  public impressum() {
    window.open('https://www.nista.io/de/impressum',
    '_blank',
    'noopener'
    );
  }

  public licenseInfo() {
    window.open(window.location.origin + '/licenses',
    '_blank',
    'noopener'
    );
  }

  public termsOfUse() {
    window.open(
      'https://www.nista.io/de/agb',
      '_blank',
      'noopener'
    );
  }

  public openWiki() {
    window.open(
      'https://campfire-solutions.atlassian.net/wiki/spaces/GNIS/overview',
      '_blank',
      'noopener'
    );
  }

  public wikiPrepareData() {
    window.open(
      'https://campfire-solutions.atlassian.net/wiki/spaces/GNIS/pages/337608883/How+to+prepare+your+data+for+upload+to+nista.io',
      '_blank',
      'noopener'
    );
  }
}
