import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, interval, takeUntil } from 'rxjs';

@Component({
  selector: 'app-loading-page-overlay',
  templateUrl: './loading-page-overlay.component.html',
  styleUrls: ['./loading-page-overlay.component.scss'],
})
export class LoadingPageOverlayComponent implements OnInit, OnDestroy {
  tip?: ITip;

  @Input()
  specificTip?: ITip;

  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.tip = this.specificTip ?? this.getGenericTips();

    interval(9000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (val) => {
          if (this.specificTip && val % 2 === 1) {
            this.tip = this.specificTip;
          } else {
            this.tip = this.getGenericTips();
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getGenericTips(): ITip {
    const genericTips: ITip[] = [
      {
        body: 'Lösungen zu finden ist im Team einfacher.\nTeile deine Berechnung und Sensoren mit Kollegen und fange an, gemeinsam an der gleichen Datei zu arbeiten.',
      },
      {
        body: 'Hast du einige Ideen oder möchtest uns Feedback geben?\nEs gibt ein Feedback-Symbol in der oberen rechten Ecke, auf das du klicken kannst, um uns all deine Gedanken zu senden.',
      },
      {
        body: 'Möchtest du weitere Benutzer in deinem Workspace haben?\nUnter "Einstellungen" kannst du ganz einfach Einladungen versenden und die Zugriffsrechte der neuen Mitglieder festlegen.',
      },
      {
        body: 'Derzeit kann nista.io deinen Smartmeter anbinden oder ganz einfach Daten über .csv-Dateien importierten.',
      },
    ];
    const randomIndex = Math.floor(Math.random() * genericTips.length);
    return genericTips[randomIndex];
  }
}

export interface ITip {
  body: string;
  header?: string;
}
