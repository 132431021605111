import { Pipe, PipeTransform } from '@angular/core';
import { Numbers } from '../numbers';

@Pipe({
  name: 'nistaNumber',
})
export class NistaNumberPipe implements PipeTransform {
  transform(
    value: number | string | undefined,
    numberOfDecimal?: number
  ): string | undefined {
    if (!value && typeof value !== 'number') {
      return undefined;
    }
    if (!numberOfDecimal) {
      numberOfDecimal = 2;
    }
    if (value && typeof value === 'number' && !isNaN(value)) {
      return Numbers.getStringForNumber(value, numberOfDecimal);
      // return this.transform(value.toString(), numberOfDecimal);
    }
    if (value && typeof value === 'string') {
      let numStr = value.toString();
      let integerLen = -1;

      let unitString;
      const unitRegex = /(?<=[0-9]+)( )+[A-Za-z]+$/g;
      if (numStr.match(unitRegex)) {
        unitString = numStr.match(unitRegex)?.[0];
      }

      const regex = /^(-)?([0-9]{1,3}|([0-9]{1,3})(\.[0-9]{3})+)(,[0-9]+)?$/g;
      if (numStr.match(regex)) {
        integerLen = numStr.indexOf(',');

        if (
          integerLen !== -1 &&
          numStr.length > integerLen + numberOfDecimal + 1
        ) {
          numStr = numStr.substring(0, integerLen + numberOfDecimal + 1);
        }

        return `${numStr}${unitString !== undefined ? unitString : ''}`;
      }

      if ((integerLen = numStr.indexOf('.')) > -1) {
        numStr = this.replaceAt(numStr, integerLen, ',');
      } else {
        integerLen = numStr.length;
      }

      //Remove decimals without rounding
      if (numStr.length > integerLen + numberOfDecimal + 1) {
        numStr = numStr.substring(0, integerLen + numberOfDecimal + 1);
      }

      numStr = numStr.replace('.', '');

      const numbersBetweenGrouping = 3;
      if (integerLen > 3) {
        const y =
          integerLen > -1
            ? integerLen - numbersBetweenGrouping
            : numStr.length - numbersBetweenGrouping;
        for (let x = y; x > 0; x = x - numbersBetweenGrouping) {
          numStr = this.insertAt(numStr, x, '.');
        }
      }

      return `${numStr}${unitString !== undefined ? unitString : ''}`;
    }
    return value.toString();
  }

  replaceAt = (value: string, index: number, replacement: string) =>
    value.substring(0, index) +
    replacement +
    value.substring(index + replacement.length);

  insertAt = (value: string, index: number, insert: string) =>
    value.substring(0, index) + insert + value.substring(index);
}
