import { OAuthLogger } from 'angular-oauth2-oidc';
import { NGXLogger } from 'ngx-logger';

export class CustomOAuthLogger extends OAuthLogger {
    constructor(private logger: NGXLogger) {
        super();
    }

    debug(message?: any, ...optionalParams: any[]): void {
        this.logger.debug(message, optionalParams);
    }
    info(message?: any, ...optionalParams: any[]): void {
        this.logger.info(message, optionalParams);
    }
    log(message?: any, ...optionalParams: any[]): void {
        this.logger.log(message, optionalParams);
    }
    warn(message?: any, ...optionalParams: any[]): void {
        this.logger.warn(message, optionalParams);
    }
    error(message?: any, ...optionalParams: any[]): void {
        this.logger.error(message, optionalParams);
    }

}
