<app-loading-page-overlay *ngIf="loading | async"></app-loading-page-overlay>

<div
  class="alert alert-danger alert-dismissible fade show"
  role="alert"
  *ngIf="errorMessage && !disconnected"
>
  <span
    >This should not have happened. If you want to help us improve, please
    consider sending us
    <a [routerLink]="" (click)="giveFeedback()">feedback!</a></span
  ><br />
  <a [routerLink]="" (click)="showDetails = !showDetails">
    {{ !showDetails ? "Show" : "Hide" }} Details
  </a>
  <div class="collapse" [ngClass]="{ show: showDetails }">
    <pre style="background-color: lightgray; border-radius: 10px">
        {{ errorMessage }}
    </pre>
  </div>

  <button
    type="button"
    class="close"
    data-dismiss="alert"
    aria-label="Close"
    (click)="errorHandler.reset()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="content screenshot-root">
  <router-outlet></router-outlet>
</div>
