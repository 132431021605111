import { DataPointInfo } from './datapoint-service-client';

export class Colors {
  static getUserColor(seed?: string): string {
    if (seed === undefined) {
      return Object.values(POSSIBLECOLORS)[0];
    }

    const upperCaseEmail = seed.toUpperCase();
    let hash = 5381;

    for (let i = 0; i < upperCaseEmail.length; i++) {
      const charCode = upperCaseEmail.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash * 33) ^ charCode;
    }

    const hashedIndex = Math.floor(
      // eslint-disable-next-line no-bitwise
      (hash >>> 0) % (Object.values(POSSIBLECOLORS).length - 1)
    );

    return Object.values(POSSIBLECOLORS)[hashedIndex];
  }

  static fromQuantityType(quantity: PhysicalQuantity): string {
    switch (quantity) {
      case 'thermodynamicTemperature':
        return '#ff4858';
      case 'energy':
      case 'power':
        return '#f8961e';
      case 'mass':
      case 'massFlowRate':
        return '#5acdff';
      case 'time':
        return '#4895ef';
      case 'length':
        return '#02c6d1';
      case 'area':
        return '#28ebab';
      case 'volume':
        return '#01df68';
      case 'ratio':
        return '#ff6b6b';
      case 'production':
      case 'productionRate':
      case 'specificEnergyDemand':
        return '#537FF7';
      default:
        return '#FFFFFF';
    }
  }

  static fromQuantity(quantity: string | undefined): string {
    if (!quantity) {
      return '#FFFFFF';
    }

    if (!Object.values(PHYSICAL_QUANTITY).includes(quantity)) {
      return '#FFFFFF';
    }

    const index = Object.values(PHYSICAL_QUANTITY).indexOf(quantity);
    const physicalQuantity = Object.keys(PHYSICAL_QUANTITY)[
      index
    ] as PhysicalQuantity;
    return Colors.fromQuantityType(physicalQuantity);
  }

  static fromDatapointInfos(quantity?: string, datapointInfo?: DataPointInfo) {
    if (datapointInfo) {
      if (datapointInfo.medium === 'ProductionEfficiency') {
        return '#537FF7';
      }
    }
    return this.fromQuantity(quantity);
  }

  static glowColorForColor(color: string): string | undefined {
    if (!color) {
      return undefined;
    }
    switch (color) {
      case '#f8961e':
        return '#FF6B6B';
      default:
        return undefined;
    }
  }

  static hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? parseInt(result[1], 16) +
          ',' +
          parseInt(result[2], 16) +
          ',' +
          parseInt(result[3], 16)
      : null;
  }
}

export type PossibleColors = keyof typeof POSSIBLECOLORS;

export const POSSIBLECOLORS = {
  power: '#FFD60A',
  energy: '#F8961E',
  pressure: '#D94A93',
  ratio: '#FF6B6B',
  temperature: '#FF4858',
  massFlowRate: '#A964FF',
  force: '#BE32D1',
  currency: '#9EF8EE',
  mass: '#5ACDFF',
  time: '#4895EF',
  velocity: '#206CFF',
  distance: '#02C6D1',
  area: '#28EBAB',
  volume: '#01DF68',
  electricalCurrent: '#23FF67',
  electricalPotential: '#1A9032',
  volumeFlowRate: '#AEE647',
};

export type PhysicalQuantity = keyof typeof PHYSICAL_QUANTITY;

export const PHYSICAL_QUANTITY = {
  time: 'Time',
  length: 'Distance',
  mass: 'Mass',
  electricCurrent: 'Electric Current',
  thermodynamicTemperature: 'Temperature',
  amountOfSubstance: 'Amount of Substance',
  luminousIntensity: 'Luminous Intensity',
  planeAngle: 'Plane Angle',
  solidAngle: 'Solid Angle',
  frequency: 'Frequency',
  force: 'Force',
  pressure: 'Pressure',
  energy: 'Energy',
  power: 'Power',
  electricCharge: 'Electric Charge',
  electricalPotentialDifference: 'Electrical Potential Difference',
  capacitance: 'Capacitance',
  resistance: 'Resistance',
  electricalConductance: 'Electrical Conductance',
  magneticFlux: 'Magnetic Flux',
  magneticFluxDensity: 'Magnetic Flux Density',
  inductance: 'Inductance',
  luminousFlux: 'Luminous Flux',
  illuminance: 'Illuminance',
  area: 'Area',
  volume: 'Volume',
  footprint: 'Footprint',
  productionUnitFootprint: 'Production Unit Footprint',
  ratio: 'Ratio',
  money: 'Money',
  density: 'Density',
  flowRate: 'Flow Rate',
  specificHeatCapacity: 'Specific Heat Capacity',
  specificEnergyDemand: 'Specific Energy Demand',
  pricePerEnergy: 'Price per Energy',
  pricePerTime: 'Price per Time',
  production: 'Production Unit',
  productionRate: 'Production Rate',
  velocity: 'Velocity',
  electricPotential: 'Electric Potential',
  massFlowRate: 'Mass Flow Rate',
};
