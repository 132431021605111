import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import {
  Observable,
  combineLatest,
  combineLatestWith,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs';
import { OAuthAuthenticationService } from '../OAuth/oAuthAuthentication.service';
import { LoadingService } from '../loading.service';
import { StorageService } from '../storage.service';
import { WorkspaceService } from '../workspace.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceNavigatorGuard implements CanActivate {
  private readonly storageKey = 'lastWorkspaceId';

  constructor(
    private workspaceService: WorkspaceService,
    private authService: OAuthAuthenticationService,
    private router: Router,
    private storageService: StorageService,
    private loadingService: LoadingService
  ) {}

  canActivate(
    actRout: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.loadingService.setLoading(false);

    return new Observable<boolean>((subscriber) => {
      combineLatest([
        this.authService.ensureLoggedIn(),
        this.authService.isAuthenticated,
      ])
        .pipe(
          map((values) => values.every((b) => b)),
          filter((a) => a),
          map((_) => actRout.paramMap),
          combineLatestWith(this.getFirstUserWorkspace()),
          map(([argsParam, firstUserWorkspace]) => [
            argsParam.get('workspaceId'),
            this.getWorkspaceIdFromStorage(),
            firstUserWorkspace as string,
          ]),
          combineLatestWith(this.workspaceService.userWorkspaceList),
          switchMap(([selectedWorkspaceId, allUserWorkspaces]) => {
            const workspaceIds = allUserWorkspaces.map(
              (item) => item.workspaceId
            );
            return selectedWorkspaceId
              .filter((item) => item !== undefined && item !== null)
              .filter((item) => workspaceIds.includes(item ?? ''));
          }),
          take(1)
        )
        .subscribe({
          next: (workspaceId) => {
            this.navigateToFacility(workspaceId as string);
            subscriber.next(false);
          },
        });
    });
  }

  getFirstUserWorkspace(): Observable<string> {
    return new Observable<string>((subscriber) => {
      this.workspaceService.userWorkspaceList.pipe(take(1)).subscribe({
        next: (list) => {
          if (list.length === 0) {
            this.router.navigate(['/workspace', 'none']);
            return;
          }
          subscriber.next(list[0].workspaceId);
        },
        error: (err) => {
          console.log(`${err}: Retrying in 5 sec`);
          setTimeout(() => this.router.navigate([this.router.url]), 5000);
        },
      });
    });
  }

  navigateToFacility(workspaceId: string) {
    this.router.navigate(['/', 'workspace', workspaceId, 'facility']);
  }

  getWorkspaceIdFromStorage(): string | null {
    return this.storageService.getItem(this.storageKey);
  }
}
