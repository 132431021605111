import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { Icon, IconSize } from './../gnista-icon/gnista-icon.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[app-nista-button],a[app-nista-button]',
  templateUrl: './nista-button.directive.html',
  styleUrls: ['./nista-button.directive.scss'],
})
export class NistaButtonComponent {
  @HostBinding('class.nista-button')
  get nistaButton(): boolean {
    return true;
  }

  @HostBinding('class.button-small')
  get buttonSmall(): boolean {
    return this.size === 'small';
  }

  @HostBinding('class.button-medium')
  get buttonMedium(): boolean {
    return this.size === 'medium';
  }

  @HostBinding('class.button-large')
  get buttonLarge(): boolean {
    return this.size === 'large';
  }

  @HostBinding('class.button-link')
  get isLink(): boolean {
    return this.color === 'link';
  }

  @HostBinding('class.button-primary')
  get isPrimary(): boolean {
    return this.color === 'primary';
  }

  @HostBinding('class.button-secondary')
  get isSecondary(): boolean {
    return this.color === 'secondary';
  }

  @HostBinding('class.button-tertiary')
  get isTertiary(): boolean {
    return this.color === 'tertiary';
  }

  @HostBinding('class.button-ghost')
  get isGhost(): boolean {
    return this.color === 'ghost';
  }

  @HostBinding('class.button-danger')
  get isDanger(): boolean {
    return this.color === 'danger';
  }

  @HostBinding('class.button-disabled')
  get classDisabled(): boolean {
    return this.disabled ?? false;
  }

  @Input()
  disabled?: boolean;

  @HostBinding('attr.disabled')
  get disabledAttr(): boolean | undefined {
    if (this.disabled) {
      return true;
    }
    return undefined;
  }

  @HostBinding('class.icon-only')
  get classIconOnly(): boolean {
    return this.iconButton;
  }

  get iconSize(): IconSize {
    if (this.size === 'large') {
      return 'medium';
    }
    if (this.size === 'medium') {
      return 'medium';
    }
    if (this.size === 'small') {
      return 'small';
    }
    return 'large';
  }

  @Input()
  public iconPosition: IconPosition = 'left';

  @Input()
  public size: Size = 'medium';

  @Input()
  public icon?: Icon;

  @Input()
  public iconButton = false;

  @Input()
  public color: ButtonType = 'primary';

  constructor(public _elementRef: ElementRef) {}
}

export type Size = keyof typeof SIZE;

export const SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export type IconPosition = keyof typeof ICON_POSITION;

export const ICON_POSITION = {
  left: 'left',
  right: 'right',
};

export type ButtonType = keyof typeof BUTTON_TYPE;

export const BUTTON_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  ghost: 'ghost',
  danger: 'danger',
  link: 'link',
};
