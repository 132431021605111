import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import {
  FacilityClient,
  FacilityResponse,
} from 'src/app/facility-service-client';
import { RequestQueueService } from 'src/app/request-queue.service';
import { WorkspaceResponseBase } from 'src/app/workspace-service-client';

@Component({
  selector: 'app-workspace-status-widget',
  templateUrl: './workspace-status-widget.component.html',
  styleUrls: ['./workspace-status-widget.component.scss'],
})
export class WorkspaceStatusWidgetComponent implements OnChanges, OnDestroy {
  @Input() workspace?: WorkspaceResponseBase;

  facilityList?: FacilityResponse[];
  errorMessages: [string, string][] = Array<[string, string]>();
  facilityLoading: ([string, boolean] | undefined)[] =
    Array<[string, boolean]>();

  loading = true;

  private destroyed$ = new Subject<void>();

  constructor(
    private facilityClient: FacilityClient,
    private queueService: RequestQueueService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workspace) {
      this.workspace = changes.workspace.currentValue;
      this.destroyed$.next();

      if (!this.workspace) {
        return;
      }

      if (!this.workspace.workspaceId) {
        return;
      }

      this.queueService
        .addToQueue<FacilityResponse[]>(
          this.facilityClient.list(this.workspace.workspaceId),
          this.destroyed$
        )
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (facilityList) => {
            this.facilityList = facilityList;
            this.errorMessages = Array<[string, string]>(facilityList.length);
            this.facilityLoading = facilityList.map<[string, boolean]>((f) => [
              f.facilityId ?? '',
              true,
            ]);
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  addErrorMessage(errorMessage: [string, string]) {
    const index = this.facilityList?.findIndex(
      (f) => f.facilityId === errorMessage[0]
    );
    if (index === undefined) {
      return;
    }
    this.errorMessages[index] = errorMessage;
  }

  addFacilityLoading(facilityLoadingEvent: [string, boolean]) {
    const index = this.facilityList?.findIndex(
      (f) => f.facilityId === facilityLoadingEvent[0]
    );
    if (index === undefined) {
      return;
    }

    this.facilityLoading[index] = facilityLoadingEvent;
  }
}
