import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Icon } from '../gnista-icon/gnista-icon.component';

@Component({
  selector: 'app-nista-alert',
  templateUrl: './nista-alert.component.html',
  styleUrls: ['./nista-alert.component.scss'],
})
export class NistaAlertComponent implements OnInit, OnChanges {
  @Input()
  color: Color = 'info';

  @Input()
  closeable = true;

  @Input()
  headline = '';

  @Input()
  details?: string;

  @Input()
  action1?: string;

  @Input()
  action2?: string;

  @Input()
  show = true;

  icon: Icon = 'info';

  @Output()
  closeRequested: EventEmitter<void> = new EventEmitter();

  @Output()
  action1Requested: EventEmitter<void> = new EventEmitter();

  @Output()
  action2Requested: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      this.color = changes.color.currentValue;
      this.icon = this.determineIcon(this.color);
    }
  }

  ngOnInit(): void {}

  determineIcon(color: Color): Icon {
    if (color === 'error') {
      return 'error';
    }
    if (color === 'warning') {
      return 'warning';
    }
    if (color === 'success') {
      return 'done';
    }
    if (color === 'info') {
      return 'info';
    }
    return 'info';
  }
}

export type Color = keyof typeof COLOR;

export const COLOR = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};
