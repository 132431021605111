import { Pipe, PipeTransform } from '@angular/core';
import { FacilityService } from '../facility.service';

@Pipe({
  name: 'facilityUrl',
})
export class FacilityUrlPipe implements PipeTransform {
  constructor(private facilityService: FacilityService) {}

  transform(
    value: (string | undefined)[],
    ...args: unknown[]
  ): (string | undefined)[] {
    return this.facilityService.createAbsoluteUrl(value);
  }
}
