<div class="tip-container">
    <div class="content-area">
        <div class="content">
            <h1 class="tips-header">{{tip?.header ?? 'nista.io Trick'}}</h1>
            <p class="tips-body">{{tip?.body}}</p>
        </div>
    </div>
    <div class="animation">
        <app-loading-animation></app-loading-animation>
    </div>
</div>
