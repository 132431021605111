import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Subject, filter, mergeMap, take } from 'rxjs';
import { UserDetailsResponse } from 'src/app/identity-server-client';
import { isRequired } from 'src/app/is-required.decorator';
import { UserDetailsService } from 'src/app/user-details.service';
import { WorkspaceService } from 'src/app/workspace.service';
import { WorkspaceClient } from 'src/app/workspace-service-client';

@Component({
  selector: 'app-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
})
export class UserBadgeComponent implements OnDestroy, OnChanges {
  @Input()
  @isRequired()
  email!: string;

  @Input()
  size = 36;

  initials = '';

  fullName = '';

  userDetails?: UserDetailsResponse;
  userIsActive = false;
  inviteOnly = false;

  base64Image?: string;

  @Input()
  showTooltip?: boolean = true;

  destroyed$ = new Subject<void>();

  constructor(
    private userDetailsService: UserDetailsService,
    private workspaceService: WorkspaceService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.email) {
      this.email = changes.email.currentValue;

      if (!this.email) {
        return;
      }

      this.userDetailsService
        .getUser(this.email)
        .pipe(take(1))
        .subscribe({
          next: (details) => {
            this.userDetails = details;
            if (details === undefined) {
              this.userDetails = new UserDetailsResponse({
                familyName: this.email,
              });
            }
            this.inviteOnly = !(details?.emailConfirmed ?? false);
          },
          error: (err) => {
            this.inviteOnly = true;
          },
        });

      this.workspaceService.workspaceUserList
        .pipe(
          take(1),
          filter((a) => !!a),
          mergeMap((list) => list?.userGrants ?? []),
          filter((u) => u.userEmail === this.email.toUpperCase())
        )
        .subscribe({
          next: (userDetails) => {
            this.userIsActive = userDetails.active ?? false;
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
