import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-navigation-item',
  templateUrl: './tab-navigation-item.component.html',
  styleUrls: ['./tab-navigation-item.component.scss'],
})
export class TabNavigationItemComponent implements OnInit {
  @Input()
  label = '';

  @Input()
  isActive = false;

  @Input()
  unreadIndicator?: number;

  @Input()
  showUnreadIndicator?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
