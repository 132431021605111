import { Injectable, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading = new BehaviorSubject<boolean>(false);

  public setLoading(loading: boolean) {
    this.loading.next(loading);
  }

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is starting... show a loading spinner perhaps?
        // blog on that here: ultimatecourses.com/blog/angular-loading-spinners-with-router-events
      }
      if (event instanceof NavigationEnd) {
        this.setLoading(false);
      }
      if (event instanceof NavigationError) {
        // something went wrong, log the error
        console.log(event.error);
      }
    });
  }
}
