import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { Icon, IconSize } from '../gnista-icon/gnista-icon.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[app-nista-icon-button],a[app-nista-icon-button]',
  templateUrl: './nista-icon-button.directive.html',
  styleUrls: ['./nista-icon-button.directive.scss'],
})
export class NistaIconButtonComponent {
  @HostBinding('class.nista-icon-button')
  get nistaButton(): boolean {
    return true;
  }

  @HostBinding('class.button-small')
  get buttonSmall(): boolean {
    return this.size === 'small';
  }

  @HostBinding('class.button-medium')
  get buttonMedium(): boolean {
    return this.size === 'medium';
  }

  @HostBinding('class.button-large')
  get buttonLarge(): boolean {
    return this.size === 'large';
  }

  @HostBinding('class.button-disabled')
  get classDisabled(): boolean {
    return this.disabled ?? false;
  }

  @Input()
  disabled?: boolean;

  @HostBinding('attr.disabled')
  get disabledAttr(): boolean | undefined {
    if (this.disabled) {
      return true;
    }
    return undefined;
  }

  get iconSize(): IconSize {
    if (this.size === 'large') {
      return 'large';
    }
    if (this.size === 'medium') {
      return 'small';
    }
    if (this.size === 'small') {
      return 'small';
    }
    return 'large';
  }

  @Input()
  public size: Size = 'medium';

  @Input()
  public icon?: Icon;

  @Input()
  public iconClass: string[] | string | Set<string> | { [klass: string]: any } =
    {};

  constructor(public _elementRef: ElementRef) {}

  private get elementRef(): HTMLOrSVGElement | undefined {
    return this._elementRef?.nativeElement as HTMLOrSVGElement;
  }

  @HostListener('click', ['$event'])
  removeFocus() {
    this.elementRef?.blur();
  }
}

export type Size = keyof typeof SIZE;

export const SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};
