import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { FacilityService } from '../facility.service';

@Injectable({
  providedIn: 'root',
})
export class FacilityRootGuard implements CanActivate {
  constructor(private facilityService: FacilityService) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const facilityId = next.paramMap.get('facilityId') ?? undefined;
    this.facilityService.selectFacility(facilityId);

    return this.facilityService.facilityList.pipe(
      take(1),
      map((item) => item.some((i) => i.facilityId === facilityId))
    );
  }
}
