import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loggingin',
  templateUrl: './loggingin.component.html',
  styleUrls: ['./loggingin.component.scss']
})
export class LogginginComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    // Wait for redirect
    setTimeout(() => {
      this.router.navigateByUrl('/');
      window.location.href = '/';
    }
      , 5000);
  }

}
