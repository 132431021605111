<div class="expander-root">
  <div class="title-row" (click)="toggle()" tabindex=0 (keydown.enter)="toggle()">
    <span>
      {{ title }}
      <ng-container *ngIf="loading">
        <app-gnista-icon
          class="expander-loading-icon"
          [icon]="'loading'"
        ></app-gnista-icon>
      </ng-container>

      <ng-container *ngIf="error">
        <app-gnista-icon
          class="expander-error-icon"
          [icon]="'warning'"
          [colorOverride]="'red'"
        ></app-gnista-icon>
      </ng-container>
    </span>

    <app-gnista-icon
    [icon]="expanded ? 'chevronUp' : 'chevronDown'"
    iconSize="large"
    class="expander-icon"
  ></app-gnista-icon>

  </div>
  <div class="content" *ngIf="expanded">
    <ng-content></ng-content>
  </div>
</div>
