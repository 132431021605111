import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WorkspaceService } from '../workspace.service';

@Injectable({
  providedIn: 'root',
})
export class ReportGuard implements CanActivate {
  constructor(
    private workspaceService: WorkspaceService,
    private logger: NGXLogger
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.workspaceService.claims.createReport.observable.pipe(
      tap((x) =>
        this.logger.info(
          'You tried to go to ' + state.url + ' and this guard said ' + x
        )
      )
    );
  }
}
