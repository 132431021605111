<div class="button-direcive-root">
  <div *ngIf="icon && (iconPosition === 'left' || iconButton)">
    <app-gnista-icon [icon]="icon" [iconSize]="iconSize"></app-gnista-icon>
  </div>
  <span class="button-content" *ngIf="!iconButton">
    <ng-content></ng-content>
  </span>
  <div *ngIf="icon && iconPosition === 'right' && !iconButton">
    <app-gnista-icon [icon]="icon" [iconSize]="iconSize"></app-gnista-icon>
  </div>
</div>
