import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { SnackbarDetails } from '../snackbar.service';

@Component({
  selector: 'app-snackbar-toast',
  templateUrl: './snackbar-toast.component.html',
  styleUrls: ['./snackbar-toast.component.scss'],
})
export class SnackbarToastComponent extends Toast {
  obj: SnackbarDetails;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
    this.obj = toastPackage.config.payload as SnackbarDetails;
  }

  closeRequested() {
    this.toastrService.remove(this.toastPackage.toastId);
  }

  action1Clicked() {
    this.obj.action1Clicked?.apply(this.obj);
    this.toastrService.remove(this.toastPackage.toastId);
  }

  action2Clicked() {
    this.obj.action2Clicked?.apply(this.obj);
    this.toastrService.remove(this.toastPackage.toastId);
  }
}
