import { Component } from '@angular/core';

@Component({
  selector: 'app-global-root',
  templateUrl: './global-root.component.html',
  styleUrls: ['./global-root.component.scss'],
})
export class GlobalRootComponent {
  constructor() {}
}
