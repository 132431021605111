<div class="input-container">
  <div class="col-md-1" class="label" click-stop-propagation>
    <span>{{ placeHolder }}</span> &nbsp;
    <div class="right">
      <div
        class="info"
        *ngIf="dataPointVersion !== undefined && dataPointVersionMatch === true"
        matTooltip="Versions Match"
      >
        <app-gnista-icon
          icon="done"
          [iconSize]="'small'"
          iconClass="info"
        ></app-gnista-icon>
      </div>

      <button
        app-nista-icon-button
        icon="error"
        (click)="updateVersion()"
        *ngIf="
          dataPointVersion !== undefined && dataPointVersionMatch === false
        "
        [matTooltip]="
          'Version missmatch! Current Version: ' +
          dataPointCurrentVersion +
          ' used Version' +
          dataPointVersion
        "
      ></button>
      <a
        *ngIf="dataPointId && this.facilityService.facilityId"
        app-nista-icon-button
        icon="openBlank"
        target="_blank"
        href="#"
        [routerLink]="
          ['dashboard', 'datalibrary', 'datapoint', dataPointId] | facilityUrl
        "
      ></a>
    </div>
  </div>

  <app-inline-input
    class="datapointinput"
    [placeHolder]="placeHolder"
    [minimizeWidth]="minimizeWidth"
    [editAllowed]="editAllowed"
    (saved)="updateDataPoint($event)"
    [value]="viewValue"
    (typed)="datapointInputTyped($event)"
    [autoCompleteList]="autoCompleteDataPoints"
    [autoCompleteTemplate]="datapointList"
    [viewTemplate]="valueDisplay"
    [displaySaveState]="displaySaveState"
  >
  </app-inline-input>
</div>

<ng-template #datapointList let-context>
  <div
    class="row"
    [style.color]="color(context.value)"
    [style.fill]="color(context.value)"
  >
    <div class="col-md-2">
      <div class="item-row">
        <app-unit-icon
          *ngIf="showIcon && physicalQuantity(context.value)"
          [physicalQuantity]="physicalQuantity(context.value)"
        >
        </app-unit-icon>
        <span>{{ unit(context.value) }}</span>
      </div>
    </div>
    <div class="col-md-10">
      <span>{{ context.value.name }}</span>
    </div>
  </div>
</ng-template>

<ng-template #valueDisplay let-displayValue>
  <ng-container *ngIf="displayValue?.data">
    <div class="item-row row">
      <div class="col-md-1 edit" *ngIf="editAllowed">
        <app-gnista-icon icon="edit" [iconSize]="'small'"></app-gnista-icon>
      </div>
      <div
        class="col-md-2 item-row"
        click-stop-propagation
        [style.color]="color(displayValue.data.store)"
        [style.fill]="color(displayValue.data.store)"
      >
        &nbsp;
        <app-unit-icon
          *ngIf="showIcon && physicalQuantity(displayValue.data.store)"
          [physicalQuantity]="physicalQuantity(displayValue.data.store)"
        >
        </app-unit-icon>
        <span>{{ unit(displayValue.data.store) }}</span>
      </div>
      <div
        class="col-md-9"
        click-stop-propagation
        [matTooltip]="dataPointId + ''"
      >
        <span>{{ displayValue.text }}</span>
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="!displayValue?.data"
    style="position: relative"
    class="view valueField placeholder-inline"
  >
    {{ placeHolder }}
  </div>
</ng-template>
