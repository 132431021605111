import { Directive, Input, OnInit } from '@angular/core';
import { TabNavigationGroupComponent } from './tab-navigation-group.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-tab-navigation-group[tabGroupQuery]',
})
export class TabNavigationGroupQueryDirective implements OnInit {
  @Input()
  tabGroupQuery?: string;

  constructor(private tabGroup: TabNavigationGroupComponent) {}

  ngOnInit(): void {
    this.tabGroup.tabGroupQueryName = this.tabGroupQuery;
  }
}
