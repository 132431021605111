import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthAuthenticationService } from './oAuthAuthentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(public authenticationService: OAuthAuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return new Observable<HttpEvent<any>>((observable) => {
      next.handle(request).subscribe({
        next: (_) => observable.next(_),
        error: (err) => {
          observable.error(err);
        },
        complete: () => observable.complete(),
      });
    });
  }
}
