import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, merge, Observable, timer } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { MusicPlayer } from './music';
import { State, Wall } from './state';

@Component({
  selector: 'app-flappy-game',
  templateUrl: './flappy-game.component.html',
  styleUrls: ['./flappy-game.component.scss'],
})
export class FlappyGameComponent implements OnInit, OnDestroy {
  inLoop = false;

  state!: State;

  moveBird = false;

  gravity = 2.5;

  jumpSpeed = 2;

  speed = 4;

  showScoreBoard = false;

  musicPlayer: MusicPlayer = new MusicPlayer();

  ngOnInit(): void {
    this.bindKeypressEvent().subscribe({
      next: ($event: KeyboardEvent) => this.onKeyPress($event),
    });
  }

  constructor(private router: Router) {}
  ngOnDestroy(): void {
    this.musicPlayer.stop();
  }

  private bindKeypressEvent(): Observable<KeyboardEvent> {
    const eventsType$ = [
      fromEvent(window, 'keypress'),
      fromEvent(window, 'keydown'),
    ];
    // we merge all kind of event as one observable.
    return merge(...eventsType$).pipe(
      // We prevent multiple next by wait 10ms before to next value.
      debounce(() => timer(10)),
      // We map answer to KeyboardEvent, typescript strong typing...
      map((state) => state as KeyboardEvent)
    );
  }

  onKeyPress($event: KeyboardEvent) {
    if ($event.key === 'p') {
      if (!this.state?.play) {
        this.startGameLoop();
      }
    }

    if ($event.key === ' ') {
      this.moveBird = true;
    }

    if ($event.key === 's') {
      this.stop();
    }
  }

  startGameLoop() {
    this.showScoreBoard = false;
    this.musicPlayer.play();
    this.state = {
      play: true,
      lastUpdated: undefined,
      score: 0,
      birdPositionY: 500,
      birdPositionX: 500,
      duration: 0,
      yDuration: 0,
      xDuration: 0,
      xDirection: 0,
      lowerWalls: [],
      upperWalls: [],
    };
    setTimeout(() => {
      this.gameLoop();
    }, 20);
  }

  stop() {
    this.state.play = false;
    this.musicPlayer.stop();
  }

  gameLoop() {
    if (this.inLoop) {
      return;
    }
    this.inLoop = true;
    let cycleState = 1;
    if (!this.state.lastUpdated) {
      this.state.lastUpdated = new Date().getTime();
    } else {
      const current = new Date().getTime();

      const diff = current - this.state.lastUpdated;
      if (diff > 20) {
        cycleState = diff % 20;
        if (cycleState === 0) {
          cycleState = 1;
        }
      } else {
        cycleState = 1;
      }

      this.state.lastUpdated = new Date().getTime();
    }

    this.cylce(cycleState);

    this.inLoop = false;

    if (this.state.play) {
      setTimeout(() => {
        this.gameLoop();
      }, 20);
    }
  }

  cylce(interval: number): void {
    this.detectCollision();

    this.updateBirdPosition(interval);
    const latestWall = this.updateWallPosition(interval);

    this.state.duration += interval;

    this.state.lowerWalls = this.state.lowerWalls.filter((item) => item.x > 0);
    this.state.upperWalls = this.state.upperWalls.filter((item) => item.x > 0);

    if (latestWall < 900) {
      this.createWall();
    }

    this.state.score += interval;
  }

  jitterBirdPosition() {
    this.state.xDuration = 100;

    if (this.state.birdPositionX > 800) {
      this.state.xDirection = -1;
      return;
    }

    if (this.state.birdPositionX < 200) {
      this.state.xDirection = 1;
      return;
    }

    if (Math.random() > 0.45) {
      this.state.xDirection = -1;
      return;
    }

    if (Math.random() < 0.45) {
      this.state.xDirection = 1;
    }
  }

  detectCollision() {
    const wallList = document.getElementsByClassName('wall');
    const birdRect = document
      .getElementsByClassName('bird')[0]
      .getBoundingClientRect();
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < wallList.length; i++) {
      const wallRect = wallList[i].getBoundingClientRect();
      if (wallRect.left < birdRect.right && birdRect.left < wallRect.right) {
        if (wallRect.top < birdRect.bottom && birdRect.top < wallRect.bottom) {
          this.gameOver();
          return;
        }
      }
    }
  }

  gameOver() {
    this.showScoreBoard = true;
    this.stop();
  }

  updateWallPosition(interval: number): number {
    let latestWall = 0;
    for (const wall of this.state.lowerWalls) {
      wall.x -= interval * this.speed;
      if (wall.x > latestWall) {
        latestWall = wall.x;
      }
    }

    for (const wall of this.state.upperWalls) {
      wall.x -= interval * this.speed;
    }

    return latestWall;
  }

  createWall() {
    let gap = Math.random() * 500;

    const gapOffset = Math.random() * 100;

    if (gap < 30) {
      gap = 30;
    }

    const lowerWall: Wall = {
      x: 1000,
      y: gap + 10 + gapOffset,
    };

    const upperWall: Wall = {
      x: 1000,
      y: gap - 10 - gapOffset,
    };

    this.state.lowerWalls.push(lowerWall);
    this.state.upperWalls.push(upperWall);
  }

  updateBirdPosition(interval: number) {
    const moveBirdUp = this.moveBird;
    if (this.moveBird) {
      this.moveBird = false;
    }

    if (moveBirdUp) {
      this.state.yDuration = 50;
    }

    if (this.state.yDuration > 0) {
      const jumpModifier = interval * this.gravity * this.jumpSpeed;
      this.state.birdPositionY += jumpModifier;
      this.state.yDuration -= jumpModifier;
    } else {
      this.state.birdPositionY -= interval * this.gravity;
      if (this.state.birdPositionY <= 0) {
        this.state.birdPositionY = 0;
        this.gameOver();
        return;
      }

      if (this.state.birdPositionY >= 1000) {
        this.state.birdPositionY = 1000;
        this.gameOver();
        return;
      }
    }

    if (this.state.xDuration > 0) {
      const jumpModifier = interval;
      this.state.birdPositionX += jumpModifier * this.state.xDirection;
      this.state.xDuration -= jumpModifier;
    } else {
      this.jitterBirdPosition();
      this.state.birdPositionX -= interval * this.gravity;
    }

    if (this.state.birdPositionX <= 0) {
      this.state.birdPositionX = 0;
    }

    if (this.state.birdPositionX >= 1000) {
      this.state.birdPositionX = 1000;
    }
  }

  navigateToDashboard() {
    this.router.navigate(['/']);
  }
}
