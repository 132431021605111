<div
  class="avatar-container"
  [matTooltip]="matTooltip"
  [style.background-color]="bgColor"
>
  <i
    class="avatar"
    *ngIf="avatar"
    [style.-webkit-mask-image]="cleanImageSrc"
    [style.mask-image]="cleanImageSrc"
    [style.height.px]="size"
    [style.width.px]="size"
    [style.background-color]="colorOverride"
  ></i>
</div>
