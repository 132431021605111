import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-navigation-button',
  templateUrl: './tab-navigation-button.component.html',
  styleUrls: ['./tab-navigation-button.component.scss'],
})
export class TabNavigationButtonComponent implements OnInit {
  @Input()
  label = '';

  @Input()
  isActive = false;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter();

  @Input()
  unreadIndicator?: number;

  @Input()
  showUnreadIndicator?: boolean;

  constructor() {}

  ngOnInit(): void {}

  click(): void {
    if (this.isActive) {
      return;
    }
    this.clicked.emit();
  }
}
