export class Guid {
  static fromString(value: string): Guid {
    return new Guid(value);
  }

  static empty(): Guid {
    return new Guid('00000000-0000-0000-0000-000000000000');
  }

  static newGuid(): Guid {
    // Timestamp
    let d = new Date().getTime();
    // Time in microseconds since page-load or 0 if unsupported
    let d2 = (performance && performance.now && performance.now() * 1000) || 0;
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        // random number between 0 and 16
        let r = Math.random() * 16;
        if (d > 0) {
          // Use timestamp until depleted
          // eslint-disable-next-line no-bitwise
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          // Use microseconds since page-load if supported
          // eslint-disable-next-line no-bitwise
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return new Guid(guid);
  }

  private constructor(private guid: string) {}

  toString(): string {
    return this.guid;
  }

  isValid(): boolean {
    if (this.guid.length > 0) {
      if (
        /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/.test(
          this.guid
        )
      ) {
        return true;
      }
    }

    return false;
  }
}
