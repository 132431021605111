import { KeyValue } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { fromEvent, Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { filter, skip, skipWhile, take } from 'rxjs/operators';
import { PendingChangesService } from 'src/app/pending-changes.service';
import {
  State,
  ViewValue,
} from './inline-input-display/inline-input-display.component';

@Component({
  selector: 'app-inline-input',
  templateUrl: './inline-input.component.html',
  styleUrls: ['./inline-input.component.scss'],
})
export class InlineInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  editMode = false;

  @Input()
  placeHolder = '';

  @Input()
  value?: ViewValue | string;

  viewValue?: ViewValue;

  @Input()
  editAllowed = true;

  @Input()
  autoCompleteList?: KeyValue<string, unknown>[];

  @Input()
  autoCompleteTemplate: TemplateRef<any> | null = null;

  @Input()
  viewTemplate!: TemplateRef<any> | null;

  @Input()
  editTemplate!: TemplateRef<any> | null;

  @Input()
  minimizeWidth = true;

  @Input()
  displaySaveState = true;

  @Output()
  typed: EventEmitter<string> = new EventEmitter();

  @Output()
  saved: EventEmitter<ViewValueSavedEvent> = new EventEmitter();

  currentEditValue?: string;

  openBindingEvent = 'click';
  closeBindingEvent = 'click';

  viewHandler?: Subscription;
  editHandler?: Subscription;

  displayState: State = State.unchanged;

  destroyed$ = new Subject<void>();

  constructor(
    private readonly el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private pendingChangesService: PendingChangesService
  ) {}

  ngOnInit(): void {
    this.updateEditMode();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.value = changes.value.currentValue;
      this.updateViewValue();
    }
    if (changes.editMode) {
      this.editMode = changes.editMode.currentValue;
      this.updateEditMode();
    }
  }

  updateViewValue() {
    const viewValue = this.value as ViewValue;

    if (viewValue?.data !== undefined || viewValue?.text !== undefined) {
      this.viewValue = viewValue;
    } else if (this.value || this.value === '') {
      this.viewValue = {
        text: this.value as string,
      };
    }
  }

  private handleViewMode(): void {
    this.pendingChangesService.clearPendingChanges();

    this.viewHandler?.unsubscribe();
    this.editHandler?.unsubscribe();
    this.currentEditValue = this.viewValue?.text;

    this.viewHandler = fromEvent(this.element, this.openBindingEvent)
      .pipe(
        skipWhile(() => this.editAllowed === false),
        take(1)
      )
      .subscribe(() => this.displayEditMode());
  }

  private handleEditMode(): void {
    this.pendingChangesService.setPendingChanges();
    this.viewHandler?.unsubscribe();
    this.editHandler?.unsubscribe();

    this.editHandler = fromEvent(document, this.closeBindingEvent)
      .pipe(
        /*
      skip the first propagated event if there is a nested node in the viewMode templateRef
      so it doesn't trigger this eventListener when switching to editMode
       */
        skip(this.openBindingEvent === this.closeBindingEvent ? 1 : 0),
        filter(({ target }) => this.element.contains(target) === false),
        take(1)
      )
      .subscribe(() => this.saveEdit({ text: this.currentEditValue ?? '' }));
  }

  updateEditMode() {
    if (this.editMode) {
      this.handleEditMode();
    } else {
      this.handleViewMode();
    }
  }

  displayEditMode() {
    if (this.editAllowed) {
      this.editMode = true;
      this.updateEditMode();
      this.changeDetectorRef.detectChanges();
    }
  }

  saveEdit(event?: ViewValue) {
    this.startSaving();
    this.editMode = false;
    if (event && !event.data && event.text !== this.viewValue?.text) {
      new Observable<void>((savedObservable) => {
        this.saved.emit({
          text: event.text,
          data: event.data,
          saveFinished: savedObservable,
        });
      }).subscribe({ next: () => this.finishedSaving() });
    }

    this.updateEditMode();
    this.changeDetectorRef.detectChanges();
  }

  cancelEdit() {
    this.editMode = false;
    this.updateViewValue();
    this.updateEditMode();
    this.displayState = State.unchanged;
    this.changeDetectorRef.detectChanges();
  }

  valueChanged(event: string) {
    this.currentEditValue = event;
    this.typed.emit(event);
    this.changeDetectorRef.detectChanges();
  }

  private get element(): any {
    return this.el.nativeElement;
  }

  startSaving() {
    this.displayState = State.saving;
    this.changeDetectorRef.detectChanges();
  }

  finishedSaving() {
    this.displayState = State.saved;
    setTimeout(() => (this.displayState = State.unchanged), 2000);
    this.changeDetectorRef.detectChanges();
  }
}

export interface ViewValueSavedEvent {
  data?: any;
  text: string;
  saveFinished?: Subscriber<void>;
}
